@import "../../../../../../../../styles/mixins/variables.scss";

.history__single {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $colorWhite;
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 20px;
  box-shadow: 0 3px 20px rgba(29, 38, 38, 0.05);
}

.history__single__inner {
  border-radius: 4px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  margin-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;

  > img {
    width: 100%;
    height: 229px;
    position: absolute;
    top: 0;
    object-fit: cover;
  }

  > span {
    display: block;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16.8px;
    color: rgba(31, 31, 31, 0.70);
  }

  h3 {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    color: rgba(31, 31, 31, 0.70);
    margin-bottom: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
  }

  :global(form.ant-form) {
    display: flex;
    gap: 10px;

    :global(.ant-form-item) {
      margin: 0;

      button {
        border: 1px solid #d9d9d9;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.history__single__download {
  border: 0!important;
  box-shadow: none!important;
  padding: 0;
  display: flex;
  align-items: center;

    svg {
      width: 24px;
      height: 24px;
    }
}

:global(.app-dark-mode) {
  .history__single__download {
    svg {
      path {
        stroke: $colorWhite;
      }
    }
  }

  .history__single {
    background: $darkModeBg;
    border: 2px solid $colorWhite;
  }
}
