@import "../../styles/mixins/breakpoints.scss";
@import "../../styles/mixins/variables.scss";


#app-layout {
    .trigger {
        padding: 0 24px;
        font-size: 18px;
        line-height: 64px;
        cursor: pointer;
        transition: color 0.3s;

        &:hover {
            color: $colorPrimary;
        }
    }

    .app-layout-wrap {
        position: relative;
        padding: 0 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:before, &:after {
            display: none;
        }

        & > li {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0;
            top: 0;
            padding: 0 15px 0 0;
            @media screen and (max-width: 720px) {
                padding: 0;
            }

            &.app-layout-wrap__mob-category-name,
            &.app-layout-wrap__mob-page-name,
            &.app-layout-wrap__mob-logo {
                padding: 0;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);

                span {
                    color: rgb(2, 136, 209);
                    font-size: 14px;
                }

                img {
                    width: 140px;
                    height: 24px;
                    object-fit: contain;
                    display: block;
                }
            }

            &.ant-menu-submenu {
                @include breakpoint(sm) {
                    margin-right: 0 !important;
                    padding-right: 0;
                }

                .flex-center {
                    @include breakpoint(sm) {
                        padding-right: 0 !important;
                    }
                }
            }

            &:first-of-type {
                padding-right: 5px;
            }

            &:nth-of-type(4) {
                padding-right: 0;
                margin-right: 0 !important;
            }

            button {
                display: block;

                span {
                    display: block;
                }
            }
        }
    }


    .logo {
        height: 23px;
        margin: 15px 0;
        text-align: center;

        img {
            height: 100%;
            object-fit: contain;
            width: 150px;
        }
    }

    .app-layout-header {
        padding: 0;
        height: 56px;

        @media screen and (min-width: 1024px) {
            display: none;
        }

        & > ul {
            height: 100%;
        }

        &--sticky {
            position: fixed;
            top: 0;
            z-index: 5;
            width: 100%;

            & > ul {
                border: 0;
            }
        }
    }

    .app-layout-content {
        overflow: hidden;
        padding: 0;

        @media (max-width: 720px) {
            padding-top: 56px;
        }
    }

    .ant-menu-item {
        &::after {
            border: none;
        }
    }
}

.menu-drawer-wrap {

    &.quick-access.ant-drawer-content {
        .ant-drawer-header {
            padding: 12px 16px;
        }

        .ant-drawer-header-title {

            .ant-drawer-close {
                margin-inline-end: 16px;
                display: flex;

                svg {
                    color: $colorDark;
                    width: 22px;
                    height: 22px;
                }
            }

            .ant-drawer-title {
                font-size: 14px;
                font-weight: 500;
                line-height: 22px;
            }
        }

        .ant-drawer-body {
            overflow: hidden !important;
            @media screen and (max-width: 768px) {
                background: $colorGrayBg;
            }
        }
    }

    .ant-drawer-body {
        @media screen and (max-width: 768px) {
            padding: 0;
        }
    }

    .ant-drawer-header {
        padding: 20px 28px;
        border-bottom: 0;

        .ant-drawer-header-title {
            position: relative;

            .ant-drawer-close {
                margin-inline-end: 30px;
                display: flex;

                svg {
                    width: 24px;
                    height: 24px;
                    color: #0288d1;
                }
            }

            .ant-drawer-title {
                line-height: 100%;

                img {
                    width: 150px;
                    height: auto;
                    object-fit: contain;
                    display: block;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }
}

.ant-menu-inline {
    border-inline-end: 0 !important;
}

//Icon position fix 2-6-2023
.anticon {
    display: flex;
    vertical-align: 0px !important;
}

.icon-align {
    vertical-align: 2px
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex-end {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

li.ant-menu-item.mob-icon-chat {
    width: 24px;
    height: 24px;
    background: $purpleGradient !important;
    border-radius: 2px !important;
    position: relative;

    svg {
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: $colorWhite;
    }

    .ant-menu-title-content {
        display: none;
    }
}

.mob-menu__border {
    border-radius: 0 !important;
    margin-bottom: 16px !important;
    position: relative;
    overflow: initial !important;

    &:after {
        content: '';
        position: absolute;
        bottom: -8px;
        left: 0;
        width: 100%;
        height: 1px;
        background: rgba(0, 0, 0, .2);
    }
}

.menu-side-wrap {
    padding: 40px 16px 10px 28px;
    @include maxw($breakpoint-lg) {
        padding-top: 20px;
        padding-left: 16px;
    }

    &.desktop {
        & > .ant-flex {
            margin-bottom: 15px;

            span.ant-typography {
                color: rgba(0, 0, 0, 0.4);
                font-size: 15px;
                font-weight: 600;
                line-height: 20px;
                margin-bottom: 4px;
                display: block;
            }

            .ant-menu-item {
                height: 40px;
                line-height: 28px;
                border-radius: 8px;
                margin-bottom: 4px;

                &:hover, &.active-item-custom {
                  background: $blueGradient;

                  .ant-menu-title-content {
                    span {
                      color: $colorWhite;
                    }
                  }
                }

                .ant-menu-title-content {
                    padding-left: 20px;
                    margin-inline-start: 0;

                    svg {
                        font-size: 18px;
                        width: 18px;
                        height: 18px;
                    }

                    span {
                        transition: none;
                        font-size: 16px;
                        line-height: 20px;
                        margin: 0;
                        color: #1C1C1C;
                        font-weight: 400;
                    }
                }
            }
        }
    }

    li.ant-menu-item {
        display: flex;
        align-items: center;
        width: 100%;

        @media screen and (max-width: 768px) {
            height: 30px;
        }

        .ant-menu-title-content {
            margin-inline-start: 16px;

            @media screen and (max-width: 768px) {
                line-height: 100%;
            }
        }

        span.ant-menu-title-content, span.anticon {
            transition: none;
            font-weight: 600;

            svg {
                transition: none;
                width: 20px;
                height: 20px;
            }
        }

        &:not(:last-of-type) {
            margin-bottom: 16px;

            @media screen and (max-width: 768px) {
                margin-bottom: 8px;
            }
        }

        &.ant-menu-item-selected {
            background: $blueGradient;
        }

        &:hover:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected):not(.ant-menu-item-only-child) {
            background: $blueGradient;
            color: $colorWhite;
            fill: $colorWhite;

            .ant-menu-submenu-title {
                color: $colorWhite;
                fill: $colorWhite;
            }
        }

        &:first-of-type {
            margin-top: 0;
        }
    }

    li.ant-menu-submenu,
    li.ant-menu-item-group {
        background: transparent;
        height: auto;
        line-height: 1;

        &.ant-menu-submenu-selected {
            background: transparent;
            color: $colorWhite;
            fill: $colorWhite;
        }

        ul.ant-menu-sub.ant-menu-inline,
        ul.ant-menu-item-group-list {
            position: relative;
            background: $colorWhite;

            .ant-menu-item {
                margin-bottom: 4px;
                padding: 5px 27px !important;
                gap: 7px;
                border-radius: 8px;
                height: auto;

                &:first-of-type {
                    margin-top: 10px;
                }

                &:last-child {
                    margin-bottom: 0;
                    padding-bottom: 12px;
                }

                &.ant-menu-item-selected {
                    background: $blueGradient;
                    color: $colorWhite;
                    fill: $colorWhite;

                    span.ant-menu-title-content {
                        color: $colorWhite;
                    }

                    svg {
                        fill: $colorWhite;
                    }
                }

                &:hover:not(.ant-menu-item-selected) {
                    background: $blueGradient;
                    color: $colorWhite;

                    .ant-menu-title-content {
                        color: $colorWhite;
                    }
                }

                span.ant-menu-title-content {
                    margin-inline-start: 0;
                    color: #1c1c1c;
                    font-weight: 500;
                }

                svg {
                    width: 18px;
                    height: 18px;
                }
            }
        }

        span.ant-menu-title-content,
        .ant-menu-item-group-title {
            font-weight: 400 !important;
            font-size: 14px;
            line-height: 143%;
            color: $black-40;
        }

        span.ant-menu-title-content, span.anticon {
            transition: none;
            font-weight: 600;
            overflow: initial !important;
            @media screen and (max-width: 720px) {
                color: #4d4d4d;
                font-size: 20px;
            }

            svg {
                transition: none;
                width: 20px;
                height: 20px;
            }
        }

        .ant-menu-item-group-title,
        .ant-menu-submenu-title {
            padding: 4px 12px !important;
            height: auto;
            line-height: 1;
            cursor: initial;
            color: initial;

            .ant-menu-submenu-arrow {
                display: none;
                @include maxw($breakpoint-lg) {
                    margin-right: 10px;
                    display: block;
                }
            }

            &:hover {
                background: transparent;
            }
        }

        .ant-menu-item-group-title {
            color: $black-40;
        }

        &:not(:last-of-type) {
            margin-bottom: 10px;
        }
    }
}

.org-select {
    margin-bottom: 20px;
    @media screen and (max-width: 720px) {
        margin: 10px 0 0 !important;

    }

    &.ant-select-open {
        .ant-select-arrow {
            transform: rotate(180deg);
        }
    }
}

.app-layout-wrap__burger {
    svg {
        color: $colorPrimary;
        width: 24px;
        height: 24px;
    }
}

.app-layout-wrap__dark-mode {

    .ant-col {
        @media screen and (max-width: 768px) {
            display: flex;
            align-items: center;
        }

        svg {
            @media screen and (max-width: 1024px) {
                width: 16px;
                height: 16px;
                padding: 0;
                margin: 0;
            }
        }
    }

    button.ant-switch {
        background-color: rgb(0, 0, 0);

        &.ant-switch-checked {
            background-color: $colorPrimary;
        }
    }

    span {
        display: flex;
        align-items: center;
        height: 100%;

        svg {
            height: 100%;
            width: auto;
        }
    }
}
