@import '../../../../../../../../styles/mixins/variables.scss';
@import '../../../../../../../../styles/mixins/breakpoints';

.billing__payment {
  padding: 30px;
  overflow: hidden;

  @include maxw($breakpoint-md){
    padding: 16px 0 32px 16px;
    max-width: initial;
  }
}

.billing__payment__container {
    margin: 0 auto;
    max-width: 1200px;
}
