@import "../../../../../../styles/mixins/variables.scss";

.knowledge_wrapper {
  .ant-drawer-body {
    padding: 10px;
  }
}

.knowledge__inner {
  .ant-collapse-item {
    .ant-collapse-header {
      margin: 0;
      padding: 0;
    }

    .ant-collapse-expand-icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 10px;
    }
  }
}

.knowledge__inner__btn {
  background: $greenGradient;
  color: #fff;
  margin-bottom: 10px;

  &:hover {
    background: $greenGradient!important;
    color: #fff!important;
  }
}

.knowledge_wrapper__item__title {
  height: 40px;
  border-radius: 8px 8px 0 0;

  &.checked {
    border: 1px solid #0288D1;
  }
}

.knowledge_wrapper__item {

}

.knowledge_wrapper__item__bottom {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    margin: 0;
    font-size: 12px;
    font-weight: 500;
  }

  span {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 12px;
    font-weight: 500;

    &:before {
      content: '';
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: #43A047;
    }
  }
}

.knowledge_wrapper__item__checkbox {
  margin-bottom: 10px;

  span {
    color: rgba(30,30,30,.5);
    line-height: 100%;
  }

  .ant-checkbox-wrapper {
    display: flex;
    align-items: center;
  }
}
