$breakpoint-xs: 320px;
$breakpoint-min: 440px;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 1024px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1440px;

@mixin maxw($media) {
  @media only screen and (max-width: $media) {
    @content;
  }
}

@mixin minw($media) {
  @media only screen and (min-width: $media) {
    @content;
  }
}

@mixin breakpoint($val) {
  @if $val == xs {
    @media (max-width: $breakpoint-xs) {
      @content;
    }
  } @else if $val == min {
    @media (max-width: $breakpoint-min) {
      @content;
    }
  } @else if $val == sm {
    @media (min-width: $breakpoint-xs) and (max-width: $breakpoint-sm) {
      @content;
    }
  } @else if $val == md {
    @media (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) {
      @content;
    }
  } @else if $val == lg {
    @media (min-width: $breakpoint-md) and (max-width: $breakpoint-lg) {
      @content;
    }
  } @else if $val == xl {
    @media (min-width: $breakpoint-lg) and (max-width: $breakpoint-xl) {
      @content;
    }
  } @else if $val == xxl {
    @media (min-width: $breakpoint-xl) {
      @content;
    }
  } @else if $val == xxxl {
    @media (min-width: $breakpoint-xxl) {
      @content;
    }
  }
}
