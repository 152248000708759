@import "../../../../../styles/mixins/variables.scss";
@import "../../../../../styles/mixins/breakpoints.scss";

.menu-side__bottom__main {
    span.ant-avatar {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.menu-side__bottom__update.ant-btn.ant-btn-default {
    background: radial-gradient(192.53% 172.33% at 0% 0%, #6A74D1 38.54%, #9B60A5 72.4%, #9B60A5 99.48%);
    color: $colorWhite;
    font-size: 16px;
    line-height: inherit;
    border: 0;
    outline: none;
    border-radius: 5px;
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
    height: 40px;
    cursor: pointer;
    padding: 20px;
    max-width: 220px;
    width: 100%;
    margin: 0 auto 17px auto;
    position: relative;

    @media screen and (max-width: 768px) {
        padding: 20px 30px;
        max-width: 276px;
    }

    &:hover {
        background: radial-gradient(192.53% 172.33% at 0% 0%, #6A74D1 38.54%, #9B60A5 72.4%, #9B60A5 99.48%);
    }

    span.anticon {
        position: absolute;
        top: 50%;
        left: 27px;
        transform: translateY(-50%);
    }

    span {
        color: $colorWhite;
        font-weight: 500;
    }
}

.modal-form-popover {
    @include maxw($breakpoint-lg) {
        max-width: 93%;
    }

    .ant-popover-inner {
        padding: 0 !important;
        max-width: 586px;
        width: 100%;
        border-radius: 15px !important;
        @include maxw($breakpoint-xl) {
            max-width: none;
            width: auto;
        }
        @include maxw($breakpoint-md) {
            box-shadow: 0 3px 20px rgba(0, 0, 0, 0.3)
        }

        .ant-popover-title {
            display: flex;
            padding: 32px;
            background-color: #EAF9FE;
            justify-content: space-between;
            border-radius: 15px 15px 0 0;
            @include maxw($breakpoint-lg) {
                padding: 16px;
            }

            span.ant-typography {
                font-weight: 700;
                font-size: 24px;
                line-height: 110%;
                @include maxw($breakpoint-md) {
                    font-size: 18px;
                }
            }

            a {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .anticon svg {
                width: 18px;
                height: 18px;

                path {
                    fill: rgba(31, 31, 31, 0.5)
                }
            }

        }

        ul {
            padding-left: 32px;
            @include maxw($breakpoint-md) {
                padding-left: 22px;
            }

            li {
                margin-bottom: 5px;
                font-weight: 400;
                font-size: 18px;
                line-height: 112%;
                color: #000;
                @include maxw($breakpoint-md) {
                    font-size: 16px;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .ant-popover-inner-content {
            padding: 25px 25px 40px;
            @include maxw($breakpoint-lg) {
                padding: 16px 16px 24px;
            }
        }
    }
}

