@import "../../../../../../../../styles/mixins/breakpoints.scss";
@import "../../../../../../../../styles/mixins/variables.scss";

.tools-page {
  padding: 38px 10px 10px 10px;

  @media screen and (max-width: 768px){
    padding: 10px;
  }

  .ant-card-head {
    padding: 0;
    margin: 0 24px;
    @include breakpoint(sm) {
      margin: 0;
    }
  }
  .ant-card-head-wrapper {
    .ant-card-head-title {
      padding-top: 0;
    }
  }
  .ant-card-head, .ant-card-body {
    @include breakpoint(sm) {
      padding-left: 6px;
      padding-right: 6px;
    }

    .ant-card-tool {
      .ant-card-head {
        .ant-card-head-title {
          display: flex;
          align-items: center;
          gap: 5px;
        }
      }
    }
  }
}

.no-shadow {
  box-shadow: none !important;
}

.tools-page__tabs {

  &.no-tabs {
    .ant-card-head {
      display: none;
    }

    .ant-card-body {
      margin-top: 0!important;
      padding-top: 0!important;
    }
  }

  .ant-card-body {
    padding: 41px 20px 20px 20px;

    @media screen and (max-width: 768px){
      padding: 21px 6px 11px 6px;
    }
  }

  .ant-tabs-nav {
    margin-top: 50px;
    position: relative;

    &:before, &:after{
      content: '';
      background: rgba(0,0,0,.1);
      width: calc(100% + 80px);
      left: -40px;
      height: 1px;
      position: absolute;
      border: 0;
    }

    &:before {
      top: 0;
    }

    &:after {
      bottom: 0;
    }

    @media screen and (max-width: 768px){
      margin-top: 21px;
    }

    .ant-tabs-ink-bar {
      display: none;
    }

    .ant-tabs-nav-wrap {

      .ant-tabs-tab+.ant-tabs-tab {
        margin: 0 0 0 24px;

        @media screen and (max-width: 768px){
          margin: 0 0 0 10px;
        }
      }

      .ant-tabs-tab {
        padding: 15px 0;

        @media screen and (max-width: 768px){
          padding: 4px 0;
        }

        .ant-tabs-tab-btn {
          padding: 8px 16px;
          color: $colorDark;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;

          @media screen and (max-width: 768px){
            font-size: 14px;
            padding: 4px 8px;
          }
        }

        &.ant-tabs-tab-active {

          .ant-tabs-tab-btn {
            color: $colorWhite;
            position: relative;

            &:before {
              content: '';
              position: absolute;
              top: -50%;
              left: -50%;
              transform: translate(50%,50%);
              z-index: -1;
              width: 100%;
              height: 100%;
              border-radius: 30px;
              background: radial-gradient(192.53% 172.33% at 0% 0%, #0288D1 38.54%, #51AFE0 72.4%, #EAF9FE 99.48%);
            }
          }
        }
      }

      &:before, &:after {
        display: none;
      }
    }
  }
}

.tools-page__head {
  display: flex;
  justify-content: space-between;

  & > .ant-space-item {
    width: 100%;
    display: flex;

    &:first-of-type {
      @media screen and (max-width: 1200px) {
        display: none;
      }
    }
  }
}

.tools-page__head__right {
  display: flex;
  margin-left: auto;
}

.tools-page__search {
  max-width: 436px;
  width: 100%;
  margin-left: auto;
  position: relative;
  border-radius: 10px;
  overflow: hidden;

  @media screen and (max-width: 1200px) {
    max-width: initial;
    border-radius: 5px;
  }

  span.ant-input-affix-wrapper {
    padding: 0;
    margin: 0;
  }

  .ant-input-group-addon {
      position: initial;
    button {
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 3;
    }

    button, span, div {
      width: initial;
      height: initial;
      background: transparent;
      outline: none!important;
      border: 0;
      box-shadow: none!important;

      svg {
        fill: $colorDark;
        width: 18px;
        height: 18px;
        margin-top: 1px;

        @media screen and (max-width: 768px) {
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  input.ant-input {
    background: $colorWhite;
    padding: 6px 17px 6px 36px;

    &::placeholder {
      font-size: 14px;
      color: $colorDark;

      @media screen and (max-width: 720px){
        font-size: 12px;
      }
    }
  }

  .ant-btn {
    span {
      display: block;
    }
  }
}

.tools-page__single-card {
  height: 150px;
  cursor: pointer;
  display: grid;
  width: 100%;
  grid-template-columns: minmax(100px, 159px) minmax(130px, 196px);
  position: relative;
  border-radius: 10px;
  background: $colorWhite;
  overflow: hidden;
  transition: .3s ease-in-out;
  will-change: transform;

  @media screen and (max-width: 600px) {
    height: 140px;
    grid-template-columns: minmax(150px, 1fr) minmax(193px, 1fr);
    border-radius: 5px;
  }

  &:hover {
    transform: scale(1.03);
  }

  .ant-col {
    padding: 16px;
  }

  .ant-card-head {
    margin: 0;

    .ant-card-head-wrapper {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
      align-items: flex-start;

      .ant-card-extra {
        margin-inline-start: initial;
      }
    }
  }

  svg {
    width: 21px;
    height: 21px;
  }

  span.anticon-star {
    position: absolute;
    bottom: 19px;
    left: 18px;
    z-index: 6;

    @media screen and (max-width: 720px) {
      bottom: 15px;
      left: 15px;
    }
  }

  .ant-card-head-title {
    align-items: flex-start;
    white-space: initial;
    @media screen and (max-width: 1366px) {
      font-size: 14px;
    }

    > span.anticon {
      margin-top: 4px;
    }
  }
}

.tools-page__single-card:hover .tool-card-favorite-icon {
  display: inline-block;
}

.tool-card-favorite-icon {

  @include breakpoint(sm) {
    display: block;
  }

  &:hover {
    svg {
      fill: $colorWhite;
    }
  }
}
