@import "../../../../../../../../styles/mixins/variables.scss";

.actions__popover {
  background: transparent!important;
  color: transparent!important;
  box-shadow: none;
  border: 0!important;
  outline: 0!important;
  z-index: 3;

  a {
    color: rgba(31, 31, 31, 0.70);
    font-size: 14px;
  }
}

:global(.app-dark-mode) {
  .actions__popover {
    svg {
      path {
        stroke: $colorWhite;
      }
    }
  }
}