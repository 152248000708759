.billing-page__type {
  .ant-radio-group {
    display: flex;
    flex-direction: column;
  }
  .ant-radio-wrapper {
    & > span:not(.ant-radio){
      width: 100%;
    }
  }
  .billing-page__type__value {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
}

.billing-page__cards-single {
  box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
  margin-top: 20px;
  padding: 20px;
  border-radius: 8px;
}

.stripe-submit-btn {
  box-shadow: 0 2px 0 rgba(5, 205, 255, 0.1);
  color: #fff;
  background-color: #0288d1;
  height: 56px;
  padding: 4px 15px;
  border-radius: 6px;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  outline: none;
  position: relative;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  cursor: pointer;
  user-select: none;
  touch-action: manipulation;
  line-height: 1.5714285714285714;
  margin: 10px 0 auto 0;
  display: flex;
  align-items: center;
  justify-content: center;

  span#button-text {
    font-size: 20px;
    font-weight: 600;
    line-height: 120%;
  }

  &:hover {
    color: #fff;
    background-color: #26a4de;
  }
}

.stripe-agreements {
  margin-top: 10px;
  text-align: left;
  font-size: 12px;
  font-weight: 500;
  line-height: 120%;
  color: #464646;

  a {
    color: #464646;
    text-decoration: underline;
  }
}
