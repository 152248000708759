@import "../../../../../../../../../../styles/mixins/variables";
@import "../../../../../../../../../../styles/mixins/breakpoints";

.credit-cards {
    .credit-cards__title {
        margin-bottom: 28px;

        .ant-col {
            background: transparent;
            display: flex;
            align-items: center;
            gap: 8px;
            padding-left: 0;
            padding-right: 0;

            & > div {
                display: none !important;
            }


            span {
                color: $colorDark;
            }

            svg {
                width: 20px;
                height: 20px;
            }

            h4 {
                margin: 0;
                color: $colorDark;
                font-weight: 600;

                @media screen and (max-width: 1400px) {
                    font-size: 14px;
                    font-weight: 500;
                }
            }
        }
    }
}

.credit-cards__list {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;

    .ant-spin-nested-loading  {
        @include maxw($breakpoint-md) {
            overflow-x: clip;
        }
    }


    .ant-radio-wrapper .ant-radio {
        display: none;
    }

    .ant-radio-wrapper span.ant-radio + * {
        padding: 0;
    }

    .ant-radio-group {
        @include maxw($breakpoint-md) {
            padding-bottom: 8px;
            padding-right: 8px;
            display: flex;
            white-space: nowrap;
            overflow-x: auto;
        }
    }

    .ant-radio-wrapper {
        border: 3px solid transparent;
        transition: all ease-in-out .35s;
    }

    .ant-radio-wrapper-checked {
        border: 3px solid $colorTextBlue;
        border-radius: 13px;
    }

}

.credit-card {
    margin-bottom: 0;
    position: relative;
    width: 285px;
    border-radius: 10px;
    padding: 30px 23px 12px 16px;
    height: 182px;
    color: #1E354E;
    font-size: 18px;
    background: $colorGrayBg;
    overflow: hidden;

    @media screen and (max-width: 1400px) {
        height: 100%;
        min-height: 200px;
    }

    @media screen and (max-width: 768px) {
        height: 182px;
        min-height: initial;
        max-width: 285px;
        padding: 30px 16px 16px;
    }

    &:not(.added) {
        display: flex;
        justify-content: center;
        align-items: center;
        @media screen and (max-width: 768px) {
            border: 3px solid $colorWhite;
        }
    }

    &.added {
        display: flex;
        flex-direction: column;
        background: radial-gradient(192.53% 258.33% at 0% 0%, rgb(20, 30, 48) 19%, rgb(36, 59, 85) 55%, rgb(36, 59, 85) 50%);
        box-shadow: 2px 4px 4px 0 rgba(0, 0, 0, 0.1);
    }
}

.credit-card-footer {
    font-size: 16px;
    color: $colorWhite;
    font-weight: 600;
    line-height: 100%;

    @media screen and (max-width: 1400px) {
        font-size: 16px;
    }
}

.credit-card-exp {
    display: flex;
    align-items: center;

    @media screen and (max-width: 768px) {
        font-size: 16px;
    }
}

.credit-card-content {
    margin-top: auto;
    margin-bottom: 24px;
    font-size: 16px;
    color: $colorWhite;
    font-weight: 600;

    @media screen and (max-width: 768px) {
        margin-bottom: 16px;
    }

    label {
        font-size: 16px;
        color: $colorWhite;
        font-weight: 600;
    }
}

.credit-card-header__chip {
    width: 55px;
    border-radius: 10px;
    border: 1px solid $colorPrimary;
    background: #FFD700;
    height: 100%;
    max-height: 43px;
}

.credit-card-header__chip-bg {
    position: absolute;
    top: 0;
    left: 0;

    @media screen and (max-width: 768px) {
        top: -31%;
        left: -3%;
        height: 213px;
        width: 213px;
    }
}

.credit-card-header__delete {
    bottom: 20px;
    position: absolute;
    right: 20px;
    width: fit-content;
    color: $colorWhite;

    @media screen and (max-width: 768px) {
        right: 16px;
        bottom: 16px;
    }

    svg {
        width: 20px;
        height: 20px;
    }
}

.credit-card-button {
    padding: 13px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $greenGradient;
    border-radius: 5px;
    border: 0;
    color: $colorWhite;
    font-size: 16px;
    font-weight: 600;
    height: auto;
    outline: none;
    box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
    transition: box-shadow .35s ease-in-out;

    &:hover {
        background: $greenGradient !important;
        color: $colorWhite !important;
        box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.1);
    }
}
