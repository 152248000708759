@import "../../../../styles/mixins/breakpoints.scss";
@import "../../../../styles/mixins/variables.scss";

.art-page {
    min-height: 100vh;
    background: $colorGrayBg;
    padding: 11px 16px;
    margin-top: 0px;
    @media (min-width: 1024px) {
      padding: 32px 70px 60px 70px;
    }
    .art-btn-engine__wrap {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 6px;
      position: relative;
      width: fit-content;
      margin: 0px auto 12px 0px;

      .art-btn-engine__wrap__popover {
        padding-top: 20px;
        z-index: 15;
        position: absolute;
        top: 40px;
        left: 0;
        opacity: 0;
        pointer-events: none;
        transition: .2s ease-in-out;
        width: 240px;
        cursor: auto;

        @media screen and (max-width: 768px){
          padding-top: 10px;
        }
        @media screen and (min-width: 720px){
          top: 40px;
        }
        @media screen and (min-width: 1024px){
          top: 35px;
        }

        & > div {
          border-radius: 10px;
          padding: 10px;
          background: #fff;

          h3 {
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 22.4px;
            background: var(--diamond, linear-gradient(to bottom right, #6A74D1 19%, #9B60A5 36%, #9B60A5 50%) bottom right / 50% 50% no-repeat, linear-gradient(to bottom left, #6A74D1 19%, #9B60A5 36%, #9B60A5 50%) bottom left / 50% 50% no-repeat, linear-gradient(to top left, #6A74D1 19%, #9B60A5 36%, #9B60A5 50%) top left / 50% 50% no-repeat, linear-gradient(to top right, #6A74D1 19%, #9B60A5 36%, #9B60A5 50%) top right / 50% 50% no-repeat);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            margin-bottom: 2px;
          }

          p {
            color: #000;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16.8px;
            margin-bottom: 12px;
          }

          span {
            color: #949BAB;
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: 16.8px;

            a {
              text-decoration: underline;
              color: #949BAB;
            }
          }
        }
      }

      @media screen and (max-width: 720px){
        margin: 0px auto 16px 0px;
      }

      .ant-radio-button-wrapper {
        display: flex;
        padding: 8px 16px;
        justify-content: center;
        align-items: center;
        border: none;
        border-radius: 5px;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.43;
        box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.05);
        height: 40px;
        position: initial;
        &:nth-child(1) {
          padding-left: 10px;
        }

        &:hover {
          .art-btn-engine__wrap__popover {
            opacity: 1;
            pointer-events: auto;
          }
        }

        & > span {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 4px;
        }
        &:not(:first-child)::before {
          width: 0px;
        }
      }
      & .isActive {
        background: radial-gradient(192.53% 172.33% at 0% 0%, #0288D1 38.54%, #51AFE0 72.4%, #EAF9FE 99.48%);
        color: #fff;
        & svg {
          fill: #fff;
        }
      }
    }
    .ant-radio-button-wrapper-disabled {
      background-color: rgba(0, 0, 0, 0.04);
      cursor: not-allowed;
      color: #1F1F1F;

      span {
        color: #1F1F1F;

          &:hover {
              color: #1F1F1F;
          }
      }

      &:hover {
        span svg path{
          fill: #1F1F1F!important;
        }
      }
    }
}
.art-page__explanation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (min-width: 1024px) {
        justify-content: start;
        gap: 14px;
    }
    @media (max-width: 768px) {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 10px;
    }
    div {
      display: flex;
      flex-direction: column;
      @media (min-width: 1024px) {
        display: inline;
      }
      @media (max-width: 768px) {
        margin-bottom: 8px;
        flex: 1 0 100%;
      }
    }
    .ant-typography {
      font-size: 10px;
      @media (min-width: 1024px) {
        font-size: 12px;
        &:nth-child(2) {
            margin-left: 2px;
        }
      }
    }
    .ant-btn {
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1px 7px;
      border: 1px solid #D9D9D9;
      border-radius: 5px;
      font-size: 12px;
      @media (min-width: 1024px) {
        height: 24px;
        font-size: 14px;
      }

      &.prompt-helper {
        background: $greenGradient;
        color: rgba(255, 255, 255, 0.85);
      }
    }
}
.ant-btn.ant-btn-sm {
    border-radius: 2px;
}

.art-page__search__btn {
  flex: 1 0 216px;

  @media (max-width: 1024px) {
    flex: initial;
  }
}

.art-page__empty {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 90%;
  margin: 0 auto;

  h1 {
    font-size: 24px;
    line-height: 120%;
  }
}

.art-page__search {
    width: 100%;
    margin-top: 24px;
    margin-bottom: 32px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: relative;
    @media (min-width: 1024px) {
        margin-top: 14px;
        flex-direction: row;
        gap: 12px;
    }

  .art-page__search__btn__sizes {
    margin-top: 17px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    button {
      border: 2px solid #A8A8A8;
      background: transparent;
      color: #1F1F1F;
      opacity: .5;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: 500;

      &.active {
        border: 2px solid rgb(0, 138, 101);
        color: rgb(0, 138, 101);
      }

      &:hover {
        border: 2px solid rgb(0, 138, 101)!important;
        color: rgb(0, 138, 101)!important;
      }

      &:first-of-type {
        width: 57px;
        height: 60px;
      }

      &:nth-of-type(2) {
        width: 60px;
        height: 43px;
      }

      &:last-of-type {
        width: 43px;
        height: 60px;
      }
    }
  }

  .ant-input-suffix {
    position: absolute;
    right: 18px;
    bottom: 12px;
    font-size: 12px;
    opacity: .5;
    color: #1F1F1F;

    .ant-input-data-count {
      bottom: 0;
    }
  }

  textarea {
    min-height: 150px;
    font-weight: 600;
    border-radius: 5px;
    border: 0;
    resize: none;
    color: rgba(31,31,31,.7);
    padding: 24px 15px;
    font-size: 16px!important;

    @media (max-width: 1024px) {
      min-height: 78px;
      font-size: 14px!important;
      padding: 12px 14px;
    }
  }
  .ant-input:hover,
  .ant-input:focus {
    @media (min-width: 1024px) {
      border-right: none;
    }
  }
  button {
    height: 40px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.5;
    width: 100%;

    @media (min-width: 1024px) {
        height: 72px;
        padding: 18px 50px;
        font-size: 24px;
        font-weight: 600;
    }
  }
}
h1.ant-typography.art-page__templates__title {
  font-size: 24px;
  line-height: 120%;
  font-weight: 600;
  color: rgba(31,31,31,.7);
  margin: 0 0 10px;
}

.art-page__templates__descr {
  font-size: 14px;
  line-height: 120%;
  font-weight: 300;
  color: #7B7B7B;
  margin-bottom: 22px;
  display: block;
}

.art-page__images {
    width: 100%;
    display: grid;
    gap: 3px;
    grid-template-areas: 'two  nineteen four'
                        'five twenty four'
                        'eleven eleven seventeen'
                        'eleven eleven six'
                        'one eight ten'
                        'eighteen eighteen sixteen'
                        'eighteen eighteen three'
                        'seven nine twelve'
                        'thirteen fourteen fifteen';
        grid-template-columns: repeat(3, 1fr);
    @media (min-width: 1024px) {
        gap: 4px;
        grid-template-areas: 'one  two three four five six six'
                        'seven eight nine four ten six six'
                        'eleven eleven twelve thirteen fourteen fifteen sixteen'
                        'eleven eleven seventeen eighteen fourteen nineteen twenty';
        grid-template-columns: repeat(7, 1fr);
    }
}
.img-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  &:not(:nth-child(4)):not(:nth-child(14)) {
    aspect-ratio: 1/ 1;
  }
    &:nth-child(1) {
        grid-area: one;
    }
    &:nth-child(2) {
        grid-area: two;
    }
    &:nth-child(3) {
        grid-area: three;
    }
    &:nth-child(4) {
        grid-area: four;
    }
    &:nth-child(5) {
        grid-area: five;
     }
    &:nth-child(6) {
        grid-area: six;
    }
    &:nth-child(7) {
        grid-area: seven;
     }
    &:nth-child(8) {
        grid-area: eight;
     }
    &:nth-child(9) {
        grid-area: nine;
    }
    &:nth-child(10) {
        grid-area: ten;
     }
    &:nth-child(11) {
        grid-area: eleven;
     }
    &:nth-child(12) {
        grid-area: twelve;
    }
    &:nth-child(13) {
        grid-area: thirteen;
    }
    &:nth-child(14) {
        grid-area: fourteen;
     }
    &:nth-child(15) {
        grid-area: fifteen;
    }
    &:nth-child(16) {
        grid-area: sixteen;
      }
    &:nth-child(17) {
        grid-area: seventeen;
     }
    &:nth-child(18) {
        grid-area: eighteen;
      }
    &:nth-child(19) {
        grid-area: nineteen;
     }
    &:nth-child(20) {
        grid-area: twenty;
    }
    @media (max-width: 1024px) {
      &:not(:nth-child(4)) {
        aspect-ratio: 1/ 1;
      }
    }
}
.img-wrap img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    transition: .3s ease-in-out;
    will-change: transform;
    &:hover {
      cursor: pointer;
      transform: scale(1.03);
    }
}
.art-card {
    overflow: hidden;
    transition: .3s ease-in-out;
    .ant-image-mask-info {
      display: flex;
      align-items: center;
    }
    &:hover {
      &:before {
        content: '';
        background: rgba(0, 0, 0, 0.5);
        z-index: 1;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        pointer-events: none;
        @include breakpoint(sm) {
          display: none;
        }
      }
    }
    .ant-image-mask {
      z-index: 2;
      background: transparent;
    }
    &:hover {
      .art-generated-btns {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
.art-generated-btns {
    display: none;
    @media (min-width: 1024px) {
      width: 100%;
      position: absolute;
      bottom: 10px;
      right: auto;
      display: flex;
      flex-direction: row;
      justify-content: center;
      z-index: 2;
      opacity: 1;
      pointer-events: none;
      transition: .3s ease-in-out;
      button {
        border: 1px solid rgb(203, 213, 224);
        border-radius: 5px;
        background: #fff;
        display: flex;
        align-items: center;
          span {
            font-size: 11px;
            color: rgba(0,0,0,.8);
          }
      }
    }
}
.img-dropdown {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 7;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color:rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  gap: 7px;
  @media (min-width: 1024px) {
    display: none;
  }
}
.img-dropdown-btn {
  border: none;
  opacity: 0.8;
}
.img-dropdown-icon {
  transition: .3s ease-in-out;
  & svg {
    fill: #fff;
  }
  &:hover {
    cursor: pointer;
    transform: scale(1.03);
  }
}

.art-page__search__popover {
  z-index: 3;
  max-width: 288px;
  background: #fff;
  padding: 16px;
  border-radius: 10px;
  border: 1px solid #D3D3D3;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);

  .ant-popover-inner {
    box-shadow: none;
    padding: 0;
  }

  .ant-form-item {
    margin-bottom: 10px;
  }

  .ant-input {
    border-radius: 5px;
    background: #EAF9FE;
    height: 33px;
  }

  .ant-popover-title {
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.1;
    background: $purpleGradient;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .ant-form-item-label {
    padding: 0;
    label {
      color: rgba(31, 31, 31, 0.70);
      font-size: 12px;
      font-weight: 400;
      line-height: 1.4;
    }
  }
}
