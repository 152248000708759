$colorPrimary: #0288d1;
$colorTextBlue: #2563eb;
$colorWhite: #FFFFFF;
$colorDark: #1F1F1F;
$colorGrayBg: #F1F1F1;
$colorLightGreenBg: #1DC198;
$colorBlack: #000000;
$colorLightGray: #949BAB;
$colorLightBlue: #EAF9FE;
$colorBlue: #384D6C;
$lineColorGray: #D0D0D0;
$colorDarkBlue: #141E30;
$colorLightGray: #D3D3D3;
$colorLightBlack: rgba(31, 31, 31, 0.5);
$black-40: rgba(28, 28, 28, 0.4);

// Darkmode colors
$darkModeBg: #141414;
$lightBlue: #EAF9FE;
$darkModeText: rgba(255, 255, 255, 0.85);

/// gradients
$blueGradient: radial-gradient(192.53% 172.33% at 0% 0%, #0288D1 38.54%, #51AFE0 72.4%, #EAF9FE 99.48%);
$purpleGradient: radial-gradient(192.53% 172.33% at 0% 0%, rgb(106, 116, 209) 38.54%, rgb(155, 96, 165) 72.4%, rgb(155, 96, 165) 99.48%);
$orangeGradient: radial-gradient(192.53% 172.33% at 0% 0%, rgb(255, 145, 109) 38.54%, rgb(237, 94, 147) 72.4%, rgb(237, 94, 147) 99.48%);
$greenGradient: radial-gradient(192.53% 172.33% at 0% 0%, rgb(0, 138, 101) 38.54%, rgb(29, 193, 152) 72.4%, rgb(29, 193, 152) 99.48%);
$darkGradient: radial-gradient(192.53% 172.33% at 0% 0%, rgb(20, 30, 48) 19%, rgb(36, 59, 85) 36%, rgb(36, 59, 85) 50%);
$grayGradient: radial-gradient(192.53% 172.33% at 0% 0%, #868686 38.54%, #D6D6D6 72.4%, #868686 99.48%);
