@import '../../../../../../../../../../styles/mixins/variables.scss';
@import '../../../../../../../../../../styles/mixins/breakpoints.scss';

.app-dark-mode {

    .billing__selector__settings {
        * {
            color: $colorWhite!important;
        }

        h2 {
            background: $colorBlue;
        }
    }
}

.billing__selector__settings {
    margin-top: 80px;
    border-radius: 10px;
    box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.05);
    overflow: hidden;

    @media screen and (max-width: 768px) {
        margin-top: 32px;
        margin-left: -16px;
        width: calc(100% + 32px);
        box-shadow: none;
        border-radius: 0;
    }

    h2 {
        background: $colorDarkBlue;
        color: $colorWhite;
        font-weight: 600;
        padding: 12px 37px;
        line-height: 1.4;
        display: flex;
        align-items: center;
        margin: 0;
        border-radius: 10px 10px 0 0;

        @include maxw($breakpoint-md){
            padding: 12px 16px;
            border-radius: 0;
            font-size: 20px;
            border: 0;
            font-weight: 600;
        }

        @include maxw($breakpoint-sm) {
            font-size: 16px;
        }

    }

    .ant-table {
        border-radius: 0;
        border: 0;

        table {
            border-radius: 0;
            padding: 20px 40px 25px;
            background: $colorWhite;

            @include maxw($breakpoint-xl) {
                padding: 20px 20px 25px;
            }
            @include maxw($breakpoint-md) {
                padding: 0 16px;
            }

            thead {
                margin-bottom: 16px;

                tr {
                    th {
                        padding: 8px;
                        white-space: nowrap;
                        color: $colorLightBlack;
                        @media screen and (max-width: 768px) {
                            padding-top: 24px;
                            padding-bottom: 8px;
                            width: auto;
                        }

                        &:first-child {
                            @include maxw($breakpoint-md) {
                                padding-left: 16px;
                            }
                        }
                    }
                }
            }

            tr {

                td {
                    border: 0;
                    font-size: 12px;
                    font-weight: 500;
                    padding: 8px;
                    white-space: nowrap;
                    text-align: center;
                    color: $colorLightBlack;

                    span {
                        margin: 0 auto;
                        width: fit-content;
                        display: block;
                    }

                    &.ant-table-cell-row-hover {
                        background: transparent !important;
                    }
                }

                th {
                    text-align: center;
                    background: $colorWhite;
                    border: 0;
                    padding: 8px;
                    font-size: 16px;
                    font-weight: 600;

                    @include maxw($breakpoint-xl) {
                        font-size: 14px;
                    }

                    @include maxw($breakpoint-lg) {
                        font-size: 12px;
                        font-weight: 500;
                        padding-top: 24px;
                    }

                    &:before {
                        display: none;
                    }
                }
            }
        }
    }

    .billing__update__button {
        width: 114px !important;
        height: 34px;
        font-size: 12px;
        font-weight: 600;
    }
}

.to-upper-case {
    &:first-letter {
        text-transform: uppercase;
    }
}
