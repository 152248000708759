@import "../../../../../../styles/mixins/variables";

.tabs__docs {
  composes: tabs__head from '../../index.module.scss';
  flex-direction: column;
  align-items: flex-start;
}

.docs__table {
  width: 100%;
  padding-top: 30px;

  :global(.ant-table-thead) {
    display: none;
  }

  :global(td.ant-table-cell) {
    padding: 0!important;
    border: 0!important;
  }
}

.tabs__title {
  composes: tabs__head__title from '../../index.module.scss';
}

.tabs__explore {
  composes: tabs__head__link from '../../index.module.scss';
}

.tabs__docs__inner {
  width: 100%;
  background: #FAFAFA;
  padding: 40px 90px;
  margin: 32px 0 0!important;
  border-radius: 10px;

  @media screen and (max-width: 1024px) {
    padding: 20px;
    margin: 29px 0 0!important;
  }

  :global(.ant-col) {

    ul {
      @media screen and (max-width: 768px) {
        margin: 0 auto;
      }
    }

    &:last-child {
      ul {
        margin-left: auto;

        @media screen and (max-width: 768px) {
          margin: 0 auto;
        }
      }
    }
  }
}
