@import "../../../../../../../../styles/mixins/variables.scss";

:global(.app-dark-mode) {
    :global(.single-art__prompts) {
        & > :global(.ant-col:not(:first-child)) {
            .single-art__prompt {
                &:global(.free-plan) {
                    .single-art__prompt__descr {
                        &:before {
                            background: rgba(0, 0, 0, 0.1);
                        }
                    }
                }
            }
        }
    }
}

:global(.single-art__prompts) {
    & > :global(.ant-col:not(:first-child)) {
        .single-art__prompt {
            &:global(.free-plan) {
                .single-art__prompt__descr {
                    position: relative;

                    &:before {
                        content: '';
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background: rgba(255, 255, 255, 0.1);
                        backdrop-filter: blur(7px);
                    }
                }
            }
        }
    }
}

.single-art__prompt__tooltip {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    svg {
        width: 40px;
        height: 40px;
    }
}

:global(.ant-tooltip) {
    max-width: 172px;
    border-radius: 10px;

    :global(.ant-tooltip-inner) {
        background: #252525;
        text-align: center;
        font-size: 12px;
        line-height: 142%;
    }
}

.single-art__prompt {
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    overflow: hidden;
    background: $colorWhite;
    display: flex;
    flex-direction: column;
    width: 100%;

    @media screen and (max-width: 720px) {
        border-radius: 10px;
    }

    &:hover {
        .single-art__prompt__remove {
            opacity: 1;
        }
    }

    h4 {
        font-weight: 600;
        padding: 14px;
        color: $colorWhite;
        background: $colorPrimary;
        margin: 0;

        @media screen and (max-width: 720px) {
            padding: 8px 16px;
        }
    }

    :global(div.ant-typography) {
        padding: 16px 16px 20px 16px;
        margin: 0;

        @media screen and (max-width: 720px) {
            padding: 20px 16px 16px 16px;
        }
    }

    :global(button.ant-btn) {
        background: $colorPrimary;
        border: 1px solid $colorWhite;
        margin: auto 16px 22px 16px;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        max-width: calc(100% - 30px);
        color: $colorWhite;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 45px;

        @media screen and (max-width: 720px) {
            padding: 4px 15px;
            margin: auto 16px 16px 16px;
        }

        &:hover, &:active {
            border-color: $colorWhite;
            color: $colorWhite;
        }


        span {
            line-height: 0;
            display: flex;
            align-items: center;
        }
    }
}

.single-art__prompt__descr {
    span {
        color: $colorPrimary;
        font-weight: 700;
    }
}

button.single-art__prompt__remove {
    opacity: 0;

    &:global(.ant-btn) {
        background: 0;
        position: absolute;
        right: 30px;
        top: 10px;
        max-width: initial;
        height: initial;
        margin: 0;
        z-index: 1;
        width: auto;
        border: 1px solid rgba(31, 31, 31, 0.5);
        border-radius: 3px;
        padding: 3px;
        background: #fff;

        svg {
            color: rgba(31, 31, 31, 0.5);
            font-size: 20px;
        }
    }
}

:global(.app-dark-mode) {
    .single-art__prompt {
        background: $darkModeBg;
        border: 1px solid $darkModeText;
    }

    button.single-art__prompt__remove {
        &:global(.ant-btn-default) {
            &:hover {
                background: $darkModeText;
            }
        }
    }
}
