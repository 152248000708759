@import "../../../../../../../../styles/mixins/variables.scss";

div.template-single__add {
    cursor: pointer;

    &:global(.ant-col) {
        height: auto;

        :global(.ant-flex) {
            width: 100%;
            background: $colorWhite;
            border-radius: 20px;

            svg {
                font-size: 75px;
                color: rgba(31,31,31,.5);
            }
        }
    }
}

button.template-single__add__border {
    width: 100%;
    margin: 16px;
    border: 1px dashed rgba(31,31,31,.5);
    height: calc(100% - 32px);
    display: flex;
    align-items: center;
    justify-content: center;
    background: 0;
    outline: none;
    cursor: pointer;
}

:global(.app-dark-mode) {
    div.template-single__add{
        &:global(.ant-col) {
            height: auto;

            :global(.ant-flex) {
                background: $darkModeBg;
                border: 1px solid $colorWhite;

                svg {
                    color: $colorWhite;
                }
            }
        }
    }

    button.template-single__add__border {
        border: 1px dashed rgba(255,255,255,.5);
    }
}
