@import "../../../../styles/mixins/variables.scss";

.library {
  background: $colorGrayBg;
  height: 100%;
  padding: 38px 30px;
  min-height: 100dvh;

  @media screen and (max-width: 768px) {
    padding: 18px 18px 27px 18px;
  }

  & > h2 {
    margin: 0 0 5px;

    @media screen and (max-width: 768px) {
      margin: 0 0 8px;
    }
  }
}

.library__descr {
  font-size: 12px;
  margin-bottom: 15px;
  display: block;

  @media screen and (max-width: 768px) {
    margin: 0 0 19px;
  }
}

.library__tabs {
  margin-top: 45px;

  @media screen and (max-width: 768px) {
    margin-top: 50px;
  }

  :global(.ant-tabs-nav-operations) {
    @media screen and (max-width: 768px) {
      display: none!important;
    }
  }

  :global(.ant-tabs-nav-wrap) {
    &:after, &:before {
      display: none;
    }
  }

  :global(.ant-tabs-nav) {
    margin-bottom: 25px;
    overflow: hidden;

    @media screen and (max-width: 768px) {
      margin-bottom: 18px;
    }
  }

  :global(.ant-tabs-tab.ant-tabs-tab-active) {
    :global(.ant-tabs-tab-btn) {
      font-weight: 700;
    }
  }

  :global(.ant-tabs-tab) {
    padding: 0 16px;

    &:first-child {
      @media screen and (max-width: 768px) {
        padding: 0 16px 0 0;
      }
    }

    :global(.ant-tabs-tab-btn) {
      transition: none!important;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 36px;
    }
  }

  :global(.ant-tabs-ink-bar) {
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
}

.tabs__head {

  @media screen and (max-width: 1024px) {
    margin-bottom: 0;
  }
}

.library__title {
  svg {
    width: 24px;
    height: 27px;
  }
}

:global(.app-dark-mode) {
  .library {
    background: $darkModeBg;
    box-shadow: -10px 0px 10px 1px #fff;
  }

  h2.tabs__head__link {
    background: transparent;
    outline: 2px solid $darkModeText;
    color: $darkModeText;

    &:hover {
      background: transparent;
    }
  }
}

h2.tabs__head__link {
  cursor: pointer;
  padding: 0 24px;
  color: $colorWhite;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $colorPrimary;
  border-radius: 4px;
  font-size: 22px;
  transition: .2s ease-in-out;
  outline: 2px solid transparent;
  outline-offset: -2px;

  &:hover {
    color: $colorPrimary;
    outline: 2px solid $colorPrimary;
    background: $colorWhite;
  }
}

h2.tabs__head__title {
  background-image: $purpleGradient;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  margin: 0;
  padding: 8px 0;

  @media screen and (max-width: 768px) {
    padding: 0;
  }
}
