
:root {
    --Diamond:
            linear-gradient(to bottom right, #6A74D1 19%, #9B60A5 36%, #9B60A5 50%) bottom right / 50% 50% no-repeat,
            linear-gradient(to bottom left, #6A74D1 19%, #9B60A5 36%, #9B60A5 50%) bottom left / 50% 50% no-repeat,
            linear-gradient(to top left, #6A74D1 19%, #9B60A5 36%, #9B60A5 50%) top left / 50% 50% no-repeat,
            linear-gradient(to top right, #6A74D1 19%, #9B60A5 36%, #9B60A5 50%) top right / 50% 50% no-repeat;
}

.welcome-modal {
    width: 586px;

    .ant-modal-content {
        padding: 0;
    }

    .ant-modal-body {
        padding: 0px 30px 16px;
    }

    &-footer {
        display: flex;
        justify-content: center;
    }

    &__cover {
        img {
            object-fit: contain;
            max-width: 100%;
            max-height: 173px;
        }
    }

    &__title {
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px;
        background: var(--Diamond);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    &__text {
        margin-top: 16px;
        color: rgba(31, 31, 31, 0.50);
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
    }
}
