.formNewTool {
  :global(span.ant-typography) {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16.8px;
    opacity: .5;
    color: rgba(31, 31, 31, 0.70);
    margin-bottom: 17px;
    display: block;
  }

  :global(.ant-form-item) {
    margin-bottom: 14px;
  }

  :global(h5.ant-typography) {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22.4px;
    letter-spacing: 0.096px;
    color: rgba(31, 31, 31, 0.70);
    margin-bottom: 14px;
  }

  :global(.ant-input-affix-wrapper) {
    border: 0;
    padding: 0;
  }

  :global(.ant-input-data-count) {
    position: relative;
    bottom: 0;
  }

  :global(.ant-input-suffix) {
    bottom: -30px;
    position: absolute;
    right: 0;
  }

  textarea, :global(input.ant-input) {
    border-radius: 5px;
    background: #EAF9FE;
    border: 0;
    outline: none;
    min-height: 50px!important;
    padding: 16px 32px 16px 16px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19.6px;
    letter-spacing: 0.084px;
    color: rgba(31, 31, 31, 0.70);
  }
}