.tabs_wrap {
    margin-bottom: 32px;
    :global(.ant-tabs-content-holder) {
        padding-top: 30px;
        padding-bottom: 30px;
    }
    :global(.ant-form-item) {
        margin-bottom: 0px;
    }
    :global(.ant-tabs-nav-wrap) {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    :global(.ant-tabs-nav ) {
        margin: 0px auto;
        min-width: 375px;
        border-color: transparent;
        background: #F1F1F1;
        color: #1F1F1F;
        padding-top: 16px;
        padding-bottom: 16px;
        @media (min-width: 1024px) {
            width: 550px;
          }
        @media (min-width: 1440px) {
            width: 711px;
          }
    }
    :global(.ant-tabs-nav-list) {
        gap: 16px;
        :global(.ant-tabs-tab) {
            margin: 0;
        }
    }
    :global(.ant-tabs-nav-list .ant-tabs-ink-bar.ant-tabs-ink-bar-animated) {
        width: 0px !important;
    }
    :global(.ant-tabs-tab) {
        display: flex;
        align-items: center;
        height: 30px;
        padding: 4px 8px;
        gap: 8px;
        background-color: transparent;
        border-radius: 100px 100px 100px 100px;
        @media (min-width: 1024px) {
            padding: 8px 16px;
            height: 36px;
          }
    }
    :global(.ant-tabs-tab .ant-tabs-tab-btn) {
        font-size: 14px;
        font-weight: 500;
        line-height: 1.6;
        @media (min-width: 1024px) {
            font-size: 16px;
            font-weight: 600;
            line-height: 1.4;
        }
    }
    :global(.ant-tabs-tab.ant-tabs-tab-active) {
        border-radius: 30px;
        background: radial-gradient(192.53% 172.33% at 0% 0%, #0288D1 38.54%, #51AFE0 72.4%, #EAF9FE 99.48%);
        color: #fff;
    }
    :global(.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn) {
        color: #fff;
    }
}
.radio_group {
    margin-right: auto;
    margin-left: auto;
    width: 332px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 16px;
    @media (min-width: 1440px) {
        width: 392px;
      }
    :global(.ant-radio-button-wrapper:not(:first-child)::before) {
        width: 0px;
    }
    :global(.ant-radio-button-wrapper.ant-radio-button-wrapper-checked div)::before {
        content: "";
        position: absolute;
        inset: 0;
        border-radius: 10px; 
        padding: 3px; 
        background: radial-gradient(192.53% 172.33% at 0% 0%, rgb(255, 145, 109) 38.54%, rgb(237, 94, 147) 72.4%, rgb(237, 94, 147) 99.48%); 
        -webkit-mask: 
        radial-gradient(192.53% 172.33% at 0% 0%, rgb(255, 145, 109) 38.54%, rgb(237, 94, 147) 72.4%, rgb(237, 94, 147) 99.48%) content-box, 
        radial-gradient(192.53% 172.33% at 0% 0%, rgb(255, 145, 109) 38.54%, rgb(237, 94, 147) 72.4%, rgb(237, 94, 147) 99.48%);
        -webkit-mask-composite: xor;
        mask-composite: exclude; 
    }
}
.radio_btn {
    padding-inline: 0px;
    flex-basis: calc((100% - 2 * 16px) / 3);
    width: 100%;
    height: 100%;
    border: none;
    &:first-child {
        border: none;
    }
}