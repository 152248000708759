.profile-referral-link {
  width: 100%;
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .ant-form-item-control-input-content {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
  }

  p {
    flex: 1 0 100%;
  }

  span {
    display: block;
  }

  span.anticon-copy {
    padding: 4px 10px;
    height: 100%;
    border: 1px solid rgba(100, 100, 100, 0.2);
    background: rgba(150, 150, 150, 0.1);
    border-radius: 3px;
    cursor: pointer;

    svg {
      width: 21px;
      height: 21px;
    }
  }

  article.ant-typography {
    width: calc(100% - 53px);
    cursor: pointer;
  }
}

.profile-form {
  .ant-form-item-label {
    label {
      font-weight: 700;
    }
  }
}

.profile-meta {
  &-avatar {
    width: 100%;
    height: 100%;
    border-radius: 0;

    &-inner {
      display: flex;
      justify-content: space-between;
    }
  }
  .ant-card-meta-avatar {
    width: 100%;
    padding: 0;
  }
  .ant-upload-wrapper {
    text-align: left;
  }
}


.avatar-uploader {
  .ant-upload-list-item-actions {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}