@import "../../../../../../styles/mixins/variables.scss";

.tabs__audio {
  width: 100%;

  table {
    border-collapse: separate;
    border-spacing: 0 4px;

    :global(.ant-table-row.active) {

      h2.tabs__audio__voice {
        background-image: $orangeGradient;
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
      }

      td {
        position: relative;
        border-top: 1px solid rgb(255, 145, 109)!important;
        border-bottom: 1px solid rgb(255, 145, 109)!important;
        border-radius: 0!important;
      }

      td:first-child {
        border-left: 1px solid rgb(255, 145, 109)!important;
        border-radius: 10px 0 0 10px !important;
      }

      td:last-child {
        & > div {
          border-top: 1px solid rgb(255, 145, 109)!important;
          border-bottom: 1px solid rgb(255, 145, 109)!important;
          border-right: 1px solid rgb(255, 145, 109)!important;
          border-radius: 0 10px 10px 0 !important;
        }
      }
    }

    :global(.ant-table-row) {
      height: 86px;
      position: relative;

      :global(.ant-table-cell) {
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        padding: 8px 0;
        border-top: 1px solid transparent;
        border-bottom: 1px solid transparent;
        background: #FAFAFA;
        position: initial;
        transition: initial!important;

        &:first-child {
          border-left: 1px solid transparent;
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
          padding-left: 30px;

          @media screen and (max-width: 768px) {
            padding-left: 10px;
          }
        }

        &:last-child {
          border-right: 1px solid transparent;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
          right: -1px!important;

          & > div {
            z-index: 2;
            position: absolute;
            width: 100%;
            height: calc(100% + 2px);
            top: -1px;
            left: 0;

            & > a {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }

          &:before {
            content: '';
            position: absolute;
            background: #FAFAFA;
            z-index: 1;
            width: 100%;
            height: calc(100% + 2px);
            top: -1px;
            left: 0;
            right: 0;
            bottom: 0;
          }
        }
      }
    }

    tbody {
      &:before {
        content:"@";
        display:block;
        line-height:32px;
        text-indent:-99999px;
      }
    }

    :global(.ant-table-thead) {

      :global(.ant-table-cell) {
        background: $colorWhite;
        padding: 8px 0;
        border: 0;

        h2 {
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
          background-image: $purpleGradient;
          background-clip: text;
          color: transparent;
          margin: 0;

          @media screen and (max-width: 768px) {
            font-size: 18px;
          }
        }

        &:first-child {
          padding: 8px 20px 8px 10px;
          @media screen and (min-width: 768px) {
            padding: 8px 20px 8px 30px;
          }
        }

        &:last-child {
          text-align: center;
        }

        &:before {
          display: none;
        }
      }
    }
  }
}

.tabs__audio__presenter {
  display: block;
  width: 70px;
  height: 70px;
  object-fit: cover;
  border-radius: 5px;
  margin: 0 8px;
}

h2.tabs__audio__voice {
  margin: 0;
  color: rgba(31, 31, 31, 0.70);

  @media screen and (max-width: 768px) {
    font-size: 18px;
  }
}

h2.tabs__audio__date {
  margin: 0;
  color: $colorLightGray;

  @media screen and (max-width: 768px) {
    font-size: 18px;
  }
}

span.tabs__audio__voice {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  color: $colorLightGray;
}

span.tabs__audio__date {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  color: rgba(31, 31, 31, 0.70);
}

span.tabs__audio__text {
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  width: 100%;
  display: block;
  color: $colorDark;
}

span.tabs__audio__text__ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.tabs__audio__icon {
  svg {
    width: 24px;
    height: 24px;
    @media screen and (min-width: 768px) {
      width: 35px;
      height: 35px;
    }
  }
}

.tabs__audio__icon__black {
  color: $colorBlack;
}

:global(.app-dark-mode) {
  span.tabs__audio__text {
    color: $darkModeText;
  }

  h2.tabs__audio__voice {
    color: $darkModeText;
  }

  .tabs__audio__icon__black {
    color: $darkModeText;
  }

  table {
    :global(.ant-table-thead) {
      :global(.ant-table-cell) {
        background: $darkModeBg;
      }
    }

    :global(.ant-table-row) {
      :global(.ant-table-cell) {
        background: $darkModeBg;

        &:last-child {
          &:before {
            background: transparent;
          }

          & > div {
            background: transparent;
          }
        }
      }
    }
  }
}
