@import '../../styles/mixins/variables.scss';

.share_chat {
    background: #D9D9D9;
    height: 100dvh;
    overflow: hidden;
    padding: 0 24px;

    @media screen and (max-width: 768px) {
        padding: 0 10px;
    }
}

.share_chat__not_found {
    background: #D9D9D9;
    height: 100dvh;
    overflow: hidden;

    :global(.ant-flex) {

        img {
            margin-bottom: auto;
            width: 200px;
            display: block;
            margin-top: 40px;
        }

        &:last-of-type {
            margin-top: auto;
        }

        &:first-of-type{
            flex: 0 0 50%;
        }
    }

    h1 {
        text-align: center;
        color: rgba(31,31,31,.5);
    }
}

.share_chat__heading {
    width: 100%;
    max-width: 920px;
    margin: 0 auto;
    padding-bottom: 18px;

    h1 {
        background-image: $darkGradient;
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
        font-size: 48px;
        padding-top: 40px;
        line-height: 70px;
        margin: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;

        @media screen and (max-width: 768px) {
            padding-top: 5px;
            font-size: 30px;
            line-height: 50px;
        }
    }

    :global(span.ant-typography) {
        font-size: 16px;
        color: rgba(31,31,31,.5);
        font-weight: 600;
        max-height: none;
    }
}

.share_chat__info {
    max-width: 920px;
    margin: 0 auto 44px auto;
    background: $colorGrayBg;
    padding: 20px;
    border-radius: 10px;
    position: relative;

    @media screen and (max-width: 768px) {
        margin: 0 auto 24px auto;
    }

    &:after {
        content: '';
        position: absolute;
        bottom: -24px;
        border-bottom: 1px solid #585858;
        left: 0;
        height: 1px;
        width: 100%;;
        z-index: 1;

        @media screen and (max-width: 768px) {
            bottom: -14px;
            border-bottom: 1px solid #fff;
        }
    }

    span {
        font-size: 16px;
        line-height: 24px;
        color: $colorBlack;

        @media screen and (max-width: 768px) {
            font-size: 14px;
            padding: 0;
            line-height: 140%;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: normal;
        }
    }
}

.share_chat__list {
    width: 100%;
    max-width: 920px; // Changed from 920px to 100%
    margin: auto;
    background: $colorWhite;
    height: calc(100vh - 180px); // Adjust this value based on your header and footer heights
    display: flex;
    flex-direction: column;

    :global(.ant-list) {
        flex: 1;
        overflow-y: auto;
        max-height: none; // Remove the fixed max-height

        @media screen and (max-width: 640px) {
            max-height: none; // Remove the fixed max-height for mobile as well
        }
    }
}

.share_chat__item {
    &:global(.ant-list-item) {
        padding: 32px 21px;
        display: flex;
        gap: 32px;
        justify-content: flex-start;
        flex-wrap: nowrap;

        @media screen and (max-width: 768px) {
            gap: 20px;
            padding: 25px 15px;
        }

        :global(.chat-message-response) {
            margin: 0;
        }

        img {
            border-radius: 50%;
            overflow: hidden;
            width: 56px;
            height: 56px;
            display: block;

            @media screen and (max-width: 768px) {
                width: 34px;
                height: 34px;
            }
        }

        &:global(.user) {
            background: $colorWhite;
            font-size: 16px;
            color: $colorDark;
            line-height: 22px;
            font-weight: 600;

            @media screen and (max-width: 768px) {
                font-size: 12px;
            }

            &:global(.align) {
                align-items: center;
            }
        }

        &:global(.respond) {
            background: $colorGrayBg;
            font-size: 14px;
            color: $colorDark;
            line-height: 22px;
            font-weight: 500;

            &:global(.align) {
                align-items: center;
            }
        }
    }
}

.share_chat__signup {
    height: 100px;
    background: #D9D9D9;
    max-width: 966px;
    margin: 0 auto;
    width: 100%;
    z-index: 1;

    a {
        color: $colorWhite;
        font-size: 16px;
        font-weight: 700;
        line-height: 1;
        border-radius: 10px;
        background: $greenGradient;
        width: 270px;
        height: 53px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0;
        outline: none;

        @media screen and (max-width: 640px){
            font-size: 14px;
            height: 45px;
            width: 230px;
        }

        &:hover {
            border: 0;
            outline: none;
            background: $greenGradient;
            color: $colorWhite;
        }
    }
}
