.modal__enhance {
  :global(span.ant-typography) {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22.4px;
    letter-spacing: 0.096px;
    color: rgba(31, 31, 31, 0.70);
    margin-bottom: 14px;
    display: block;
  }

  textarea {
    background: #EAF9FE;
    border-radius: 5px;
    padding: 16px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 19.6px;
    letter-spacing: 0.096px;
    opacity: 0.7;
    color: #000;
  }

  :global(.ant-input-data-count) {
    bottom: -33px;
  }
}