@import "../../../../../../styles/mixins/variables.scss";

.billing__form__radio {
  :global(.ant-radio-group) {
    width: 100%;

    :global(.ant-space) {
      width: 100%;
      gap: 20px!important;

      :global(.ant-radio-wrapper.ant-radio-wrapper-checked) {
        outline: 3px solid $colorPrimary;
      }

      :global(.ant-radio-wrapper) {
        border-radius: 18px;
        padding: 20px 15px;
        width: 100%;
        outline: 1px solid #1F1F1F;

        @media screen and (max-width: 768px) {
          padding: 15px 5px;
        }

        > span {
          width: 100%;
        }

        > span {
          font-weight: 600;
          font-size: 16px;
          line-height: 120%;
        }

        :global(span.ant-radio) {
          width: auto;
          opacity: 0;
          position: absolute;
        }
      }
    }
  }
}

.billing__radio__price {
    font-size: 16px;

  :first-letter {
    text-transform: uppercase;
  }

  span {
    font-size: 11px;
    padding: 2px 8px;
    background: $greenGradient;
    color: $colorWhite;
    border-radius: 16px;
  }
}

.billing__form__btn {
  width: 100%;
  height: 56px;
  color: $colorWhite;
  line-height: 120%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $blueGradient;
  border-radius: 8px;

  &:global(.ant-btn.ant-btn-default) {
    background: $blueGradient;

    &:hover {
      background: $blueGradient;
    }
  }

  span {
    color: $colorWhite;
    font-size: 20px;
    font-weight: 600;
  }

  &:hover {
    color: $colorWhite;
  }
}

.billing__form__join {
  display: flex;
  align-items: center;
  gap: 10px;

  & > div {
    display: flex;

    :global(.ant-image) {
      width: 33px;
      height: 33px;

      &:nth-of-type(2) {
        margin-left: -6px;
      }

      &:nth-of-type(3) {
        margin-left: -6px;
      }
    }
  }
}

:global(.app-dark-mode) {
  .billing__form__radio {
    :global(.ant-radio-group) {
      :global(.ant-space) {
        :global(.ant-radio-wrapper) {
          outline: 1px solid $colorWhite;
        }

        :global(.ant-radio-wrapper.ant-radio-wrapper-checked) {
          outline: 3px solid $colorPrimary;
        }
      }
    }
  }
}
