.billing {
    height: 100%;
    overflow: hidden;
    background: #F1F1F1;

    & > .ant-col {
        height: 100%;

        &:last-of-type {
            box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.05);

            @media screen and (max-width: 992px) {
                box-shadow: none;
            }
        }

        @media screen and (max-width: 992px) {
            height: initial;
        }
    }
}
