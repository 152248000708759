@import "../../../../styles/mixins/variables.scss";

.book_page {
  padding-top: 0;
  min-height: 100vh;
  @media (min-width: 1024px) {
    background: $colorGrayBg;
    padding-top: 70px;
    padding-right: 60px;
    padding-left: 70px;
    }
  :global(.ant-card .ant-card-body) {
    padding: 0;
  }
  :global(.ant-steps.ant-steps-vertical) {
    display: none;
  }
  :global(.ant-steps.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item) {
    padding-inline-start: 0px;
  }
  :global(.ant-progress-line) {
    font-size: 0px;
    margin-bottom: 0px;
    margin-inline-end: 0px;
  }
  :global(.ant-progress) {
    @media (min-width: 1024px) {
      display: none;
    }
  }
  :global(.ant-progress.ant-progress-bg) {
    background: $purpleGradient;
  }
  :global(.ant-steps) {
    :global(.ant-steps-item-container[role='button']):hover {
      :global(.ant-steps-icon svg) {
          color: #46444c;;
      }
    }
    :global(.ant-steps-item-icon) {
      margin-inline-end: 0px;
      width: 36px;
      height: 36px;
      border: none;
      background-color: #fff;
      :global(.ant-steps-icon) {
        color: rgba(0, 0, 0, 0.65);
      }
    }
    :global(.ant-steps-item-title) {
      padding-inline-end: 0px;
    }
    :global(.ant-steps-icon) {
      display: flex;
      justify-content: center;
      margin-top: 11px;
      margin-bottom: 11px;
    }
    :global(.ant-steps-item-process .ant-steps-item-title)::after {
      background-color: #fff !important;
    }
    :global(.ant-steps-item-wait) {
      :global(.ant-steps-item-title)::after {
        background-color: #fff !important;
      }
    }
    :global(.ant-steps-item-finish) {
      :global(.ant-steps-item-title)::after {
        background-color: transparent;
        background: $purpleGradient;
      }
      :global(.ant-steps-item-icon) {
        span > svg {
          width: 16px;
          height: 16px;
        }
        background: $purpleGradient;
        :global(.ant-steps-icon) {
          color: #fff;
          font-size: 12px;
          font-weight: 400;
          line-height: 1.4;
        }
      }
    }
  }
  :global(.slick-slider) {
    width: 100%;
  }
  :global(.slick-track) {
    display: flex;
    flex-direction: row;
  }
  :global(.slick-slide) {
    margin: 0 8px;
  }
  :global(.slick-slide):first-child {
    margin-left: 0px;
  }
}
.book_page__wrapper {
  padding-top: 32px;
  padding-bottom: 78px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: 1024px) {
    padding-left: 0;
    padding-top: 71.5px;
    padding-bottom: 102.5px;
    border-radius: 10px;
  }
}
.book_page__form {
  margin-top: 32px;
  width: 100%;
}
.book_page__steps {
  display: none;
  @media (min-width: 1024px) {
    display: flex;
    width: 503px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 46px;
    margin-bottom: auto;
    padding: 12px 0px 12.5px 32px;
  }
}
