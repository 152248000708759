.image_wrap {
    position: relative;
    display: flex;
    justify-content: center;
}
.image {
    width: 100px;
    height: 130px;
    border-radius: 10px;
    border: 3px solid rgba(31, 31, 31, 0.20);
    @media (min-width: 1440px) {
        width: 120px;
        height: 156px;
    }
}
