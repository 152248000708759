@import "../../../../../../styles/mixins/variables.scss";

.chat-share__list {
  max-height: 510px;
  height: 100%;
  overflow-y: auto;
  width: 100%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 10px 10px 0 0 ;
  margin-top: 10px;

    @media screen and (max-width: 768px) {
        max-height: 230px;
    }

  :global(ul.ant-list-items) {

    :global(.chat-message) {
      width: auto;
      gap: 20px;
    }

    :global(li.ant-list-item) {
      padding: 0;

      :global(.chat-message-response) {
        margin: 0;
      }

      &:nth-of-type(odd) {
        background: #F1F1F1;
      }

      :global(.chat-message) {
        padding: 32px 24px;

          @media screen and (max-width: 768px) {
              padding: 22px 15px;
          }

          :global(.chat-message-response) {
              max-width: 100%;
          }
      }
    }
  }
}

.chat-share__descr {
    color: rgba(31,31,31,.5);
    font-size: 16px;
    font-weight: 600;

    @media screen and (max-width: 768px) {
        font-size: 14px;
    }
}

.chat-share__info {
    padding: 20px;
    background: #D9D9D9;
    border-radius: 0 0 10px 10px;

    @media screen and (max-width: 768px) {
        font-size: 14px;
        padding: 10px 20px;
    }

  h2 {
    font-size: 24px;
    font-weight: 700;
    line-height: 120%;
    margin: 0;
    color: rgba(31,31,31,.5);

    @media screen and (max-width: 768px) {
        font-size: 18px;
    }
  }

  :global(span.ant-typography) {
    font-weight: 600;
    font-size: 16px;
    color: rgba(31,31,31,.5);

      @media screen and (max-width: 768px) {
          font-size: 14px;
      }
  }
}

.chat-share__footer {
  margin-top: 20px;

  :global(button.ant-btn.ant-btn-default) {
    background: $greenGradient;
    width: 160px;
    height: 53px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $colorWhite;
    font-size: 14px;
    font-weight: 500;
    border: 0;
    outline: none;

    svg {
      font-size: 16px;
    }

    &:hover {
      background: $greenGradient;
      border: 0;
      outline: none;
      color: $colorWhite;
    }
  }
}

.chat-share__info__title {
    line-height: 1;

    h3 {
        font-size: 24px;
        line-height: 1;
        font-weight: 700;
        color: rgba(31,31,31,.5);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 80%;
        width: auto;
        margin: 0;

        @media screen and (max-width: 768px) {
            font-size: 18px;
        }
    }

    input {
        width: auto;
    }

    svg {
        padding: 3px;
        border-radius: 3px;
        border: 1px solid rgba(31,31,31,.5);
        font-size: 16px;
        cursor: pointer;
    }
}

:global(.app-dark-mode) {
    .chat-share__list {
        :global(ul.ant-list-items) {
            :global(li.ant-list-item) {
                &:nth-of-type(odd) {
                    background: $darkModeBg;
                }
            }
        }
    }

    .chat-share__info {
        background: $darkModeBg;
        border: 2px solid $darkModeText;
    }

    .chat-share__info__title {
        svg {
            border: 1px solid $darkModeText;
        }
    }
}
