@import "../../../../../styles/mixins/variables.scss";

.menu-side {
    box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.05);
    z-index: 11;

    .ant-menu-submenu {
        transform: none !important;

        ul.ant-menu.ant-menu-sub {
            padding: 0;
            border-radius: 10px;
            background: #FAFAFA;

            li.ant-menu-item {
                padding: 13px 15px;
                height: initial;
                line-height: initial;
                background: transparent;
                color: #1c1c1c;
                border-radius: 8px;

                .ant-row {
                    align-items: center;

                    .ant-col {
                        line-height: 100%;
                        display: flex;
                    }
                }

                svg {
                    width: 18px;
                    height: 18px;
                }
            }
        }
    }

    .ant-layout-sider-children {
        height: 100vh;
        display: flex;
        flex-direction: column;
        position: sticky;
        top: 0;
        overflow-y: auto;
    }
}

.menu-side__bottom__ref {
    padding: 0 16px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    width: fit-content;

    @media screen and (max-width: 768px) {
        padding: 0 21px;
        margin-bottom: 25px;
    }

    &:hover {
        span {
            color: $colorPrimary;
        }

        svg {
            fill: $colorPrimary;
        }
    }

    span {
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        color: $colorDark;
        transition: .2s ease-in-out;
    }

    svg {
        fill: $colorDark;
        transition: .2s ease-in-out;
        width: 16px;
        height: 16px;
    }
}

.menu-side__bottom {
    margin-top: auto;
    margin-bottom: 30px;
    padding-top: 20px;
    position: relative;

    @media screen and (max-width: 768px) {
        margin-bottom: 20px;
        padding-top: 25px;
    }

    ul.ant-menu.ant-menu-sub.ant-menu-vertical {
        padding: 8px 0;
    }

    .ant-menu-submenu.ant-menu-submenu-popup {
        transition: none !important;
        top: initial !important;
        left: 50% !important;
        transform: translateX(-50%) !important;
        bottom: 50px !important;
        min-width: 225px !important;

        @media screen and (max-width: 1024px) {
            min-width: 330px!important;
        }

        @media screen and (max-width: 768px) {
            min-width: 92%!important;
        }

        ul.ant-menu {
            @media screen and (max-width: 768px) {
                padding: 8px 0;
            }

            li {
                @media screen and (max-width: 1024px) {
                    display: flex;
                    height: initial;
                    line-height: 100%;
                    padding: 8px 32px;
                }

                &.ant-menu-item-selected {
                    @media screen and (max-width: 768px) {
                        color: $colorDark;
                    }
                }
            }
        }
    }
}

ul.menu-side__settings {
    padding: 0 16px;

    &.ant-menu-horizontal {
        border: 0;
    }

    li.ant-menu-submenu {
        padding: 0;

        &:before, &:after {
            display: none;
        }

        .ant-menu-submenu-title {
            padding: 11px 5px !important;
            height: initial;
            line-height: initial;
            border: 1.5px solid #d2d2d2;
            border-radius: 10px;

            @media screen and (max-width: 768px) {
                padding: 10px 10px!important;
            }

            h4, span:not(.ant-avatar-string):not(.ant-avatar) {
                color: $colorDark;
                text-transform: capitalize;
            }

            &:hover {
                background: radial-gradient(192.53% 172.33% at 0% 0%, #0288D1 38.54%, #51AFE0 72.4%, #EAF9FE 99.48%);

                h4, span:not(.ant-avatar-string):not(.ant-avatar) {
                    color: $colorWhite;
                }

                svg {
                    path {
                        stroke: $colorWhite;
                    }
                }
            }

            .ant-menu-title-content {
                line-height: initial;

                .ant-space {
                    .ant-avatar {
                        width: 33px;
                        height: 33px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }
        }
    }
}
