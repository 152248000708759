.verify__links {
  composes: auth__links from '../../index.module.scss';
  padding: 32px 0;
}

.verify {

  :global(.ant-form-item-explain-error) {
    transform: translateY(-50%)!important;
  }

  :global(.ant-form-item) {

    :global(.ant-input) {
      margin: 0 0 36px;

      @media screen and (max-width: 1024px){
        margin: 0 0 10px;
      }
    }
  }
}
