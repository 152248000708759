@import "../../../../../../../../styles/mixins/variables.scss";

.img-to-video__history__single {

  &.ant-list-item {

    h2 {
      margin-bottom: 0;

      @media screen and (max-width: 768px) {
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 22.4px;
      }
    }

    .ant-card-meta-description {
      font-size: 12px;
      font-weight: 400;
      line-height: 16.8px;
      color: $colorDark;
    }

    .ant-card-body {
      padding: 16px 16px 0 16px;

      .ant-card-meta-title {
        white-space: initial;
      }
    }

    .ant-card-actions {
      border: 0;
      padding: 16px;

      li {
        margin: 0;
        padding: 0;
        border-inline-end: 0;

        .ant-typography {
          padding: 0;
          margin: 0;
          display: flex;
          align-items: center;
          gap: 4px;
          font-weight: 500;

          svg {
            width: 20px;
            height: 20px;
          }
        }

        &:first-of-type {
          text-align: left;
        }

        &:last-of-type {
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }

  .ant-card-cover {
    height: 0;
    padding-bottom: 60%;
    position: relative;
    margin: 0;
    border: 0;

    @media screen and (max-width: 1200px) {
      padding-bottom: initial;
      height: initial;
    }
  }
}

.img-to-video__react-player {
  position: absolute;
  top: 0;
  left: 0;

  @media screen and (max-width: 1200px) {
    position: relative;
    height: 230px!important;
  }

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    object-fit: cover;
    left: 0;
  }
}

.img-to-video__wrapper {
  .img-to-video-time {
    z-index: 2;
    position: absolute;
    bottom: 8px;
    right: 8px;
    padding: 4px 12px;
    background: $colorDark;
    color: $colorWhite;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 400;
    line-height: 16.8px;
  }
  .img-to-video-delete {
    position: absolute;
    background: $colorDark;
    right: 10px;
    bottom: -40px;
    z-index: 1;
    cursor: pointer;
    &:hover {
      background: $colorDark;
    }
  }
}
