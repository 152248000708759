@import "../../../../../../styles/mixins/variables.scss";

.prompts-library {

  .ant-drawer-header {
    padding: 16px;
    background: $lightBlue;
    border: 0;
  }

  .ant-drawer-body {
    padding: 0;
    background: $colorGrayBg;
  }

  .ant-drawer-header-title {
    & > button {
      margin-inline-end: 16px;
    }
  }

  span.anticon {
    display: flex;
    justify-content: center;

    &.anticon-close {
      svg {
        color: $colorDark;
        height: 20px;
        width: 20px;
      }
    }

    svg {
      display: block;
    }
  }
}

.prompts-description {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  font-size: 16px;

  span {
    margin-top: 4px;
  }
}

.prompt-link {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 10px;

  a {
    font-size: 16px;
    color: $colorPrimary;

    &:hover {
      text-decoration: underline;
    }
  }

  svg {
    width: 20px;
    height: 20px;
    object-fit: contain;
  }
}

.prompts-search-input {
  padding: 24px 16px 24px 16px;
  background: #cdcdcd;
  width: 100%;
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.05);

  @media screen and (max-width: 1200px) {
    max-width: initial;
    background: #fff!important;
  }

  span.ant-input-affix-wrapper {
    padding: 0;
    margin: 0;
    height: 36px;
    border-radius: 5px!important;
    overflow: hidden;
  }

  .ant-input-suffix {
    display: none;
  }

  .ant-input-group-addon {
    position: absolute;
    left: 17px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 3;
    background: transparent;

    button, span, div {
      width: initial;
      height: initial;
      background: transparent;
      outline: none!important;
      border: 0;
      box-shadow: none!important;

      svg {
        fill: $colorDark;
        width: 20px;
        height: 20px;
        margin-top: 1px;
        margin-left: 16px;
      }
    }
  }

  input.ant-input {
    background: $colorWhite;
    padding: 6px 20px 6px 44px;
    border-radius: 5px;
    font-size: 12px;

    &.ant-input-disabled {
      background: $colorWhite;
    }

    &::placeholder {
      font-size: 12px;
      color: $colorDark;
      opacity: .8;
    }
  }

  .ant-btn {
    span {
      display: block;
    }
  }
}

.prompts-accordion {
  background: transparent;
  border: 0;
  border-radius: 0;

  .ant-collapse-item {
    border: 0;
    border-radius: 2px!important;
    margin-bottom: 2px;
    background: $colorWhite;

    &.ant-collapse-item-active {
      .ant-collapse-expand-icon {

        svg {
          transform: rotate(0);
        }
      }
    }

    .ant-collapse-expand-icon {

      svg {
        transition: .3s ease-in-out;
        transform: rotate(-90deg);
      }
    }

    .ant-collapse-header {
      padding: 17px 16px;

      .ant-collapse-expand-icon {
        padding-inline-end: 16px;
      }

      .ant-collapse-header-text {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
}

.prompts-library {

  .ant-tabs-tabpane {
    .tools-page {
      padding: 0;

      .ant-tabs-content-holder {
        display: none;
      }

      .tools-page__head {
        display: none;
      }

      .ant-card-body {
        padding: 8px 16px;
        margin-top: 33px;

        .ant-row {
          row-gap: 16px!important;

          .ant-col {
            flex: 0 0 100%;
            max-width: 100%;
          }
        }
      }

      .ant-card-head {
        min-height: initial;
        margin: 0 16px;

        .ant-tabs-nav {
          margin: 0;

          .ant-tabs-nav-wrap .ant-tabs-tab {
            padding: 8px 0;

            & + .ant-tabs-tab {
              margin-left: 10px;
            }

            &.ant-tabs-tab-active {
              .ant-tabs-tab-btn {
                opacity: 1;
              }
            }

            .ant-tabs-tab-btn {
              opacity: .5;
              font-weight: 500;
            }
          }

          &:before, &:after {
            display: none;
          }
        }
      }
    }
  }

  .ant-tabs.prompts-library__tabs {

    .tools-page__tabs {
      .tools-page__single-card {
        @media screen and (max-width: 600px) {
          grid-template-columns: minmax(150px, 1fr) minmax(160px, 1fr);
        }
      }
    }

    & > .ant-tabs-nav {
      margin: 0;

      .ant-tabs-nav-wrap {
        background: $colorWhite;

        .ant-tabs-nav-list {
          width: 99.99%;
          display: flex;
          justify-content: space-between;

          @media screen and (max-width: 768px){
            overflow-x: auto;
          }

          .ant-tabs-ink-bar {
            display: none;
          }

          .ant-tabs-tab {
            min-width: 139px;
            font-size: 16px;
            padding: 16px;
            font-weight: 600;
            display: flex;
            justify-content: center;
            margin: 0;
            width: 100%;

            &.ant-tabs-tab-active {
              background: $blueGradient;
              & > div {
                color: $colorWhite;
              }
            }
          }
        }
      }
    }

    .ant-tabs-content-holder {
      padding: 0px 0px;
    }
  }
}
