@import "../../../../../../../../styles/mixins/variables";

.docs__single {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #FAFAFA;
  height: 97px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.10);
  border-radius: 10px;
  margin-bottom: 8px;
}

.docs__single__inner {
  border-radius: 4px;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 12px;
  gap: 40px;

  @media screen and (max-width: 768px){
    gap: 20px;
  }

  > img {
    width: 100%;
    height: 229px;
    position: absolute;
    top: 0;
    object-fit: cover;
  }

  > span {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16.8px;
    color: rgba(31, 31, 31, 0.70);
    display: flex;
    gap: 10px;
    align-items: center;

    b {
      color: #D3D3D3;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 26.4px;

      @media screen and (max-width: 768px){
        font-size: 18px;
        line-height: 120%;
      }
    }
  }

  h3 {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin: 0;
    background: $orangeGradient;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    width: 280px;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;

    @media screen and (max-width: 768px){
      width: 120px;
    }
  }
}

.docs__single__btns {
  display: flex;
}

.docs__single__download {
  display: flex;
  align-items: center;
  margin: 16px 35px 16px auto;
  gap: 4px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  color: rgba(31, 31, 31, 0.70);

  svg {
    width: 29px;
    height: 29px;
  }
}

:global(.app-dark-mode) {
  .docs__single {
    background: $darkModeBg;
    border: 1px solid rgba(255,255,255,.75);
  }

  .docs__single__download {
    svg {
      path {
        stroke: rgba(255,255,255,.75);
      }
    }
  }
}