@import "../../../../../../styles/mixins/variables.scss";
@import "../../../../../../styles/mixins/breakpoints.scss";

:global(.app-dark-mode) {
  .profile {
    background: $darkModeBg;

    .profile__form__label {
      :global(.ant-form-item-label) label,
      :global(.ant-form-item-required) {
        color: $darkModeText;
      }
    }
    .profile__title {
      &:global(.ant-typography) {
        color: $darkModeText
      }
    }

    :global(.ant-upload-list-item) {
      background: $lineColorGray;
    }

    :global(.anticon-question-circle) path {
      fill: $darkModeText;
    }

    :global(.ant-input-affix-wrapper-disabled) {
      background: #424242;
    }

    :global(.ant-form-item-control-input-content) {
      :global(.ant-input) {
        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus {
          -webkit-text-fill-color: $darkModeText !important;
          -webkit-box-shadow: 0 0 0 1000px $darkModeBg inset !important;
          transition: background-color 5000s ease-in-out 0s !important;
        }
      }

      :global(.ant-select-selector) {
        background: $darkModeBg !important;
      }
    }
  }
}


.profile {
  height: 100%;
  background: $colorGrayBg;
  padding: 36px 16px !important;

  @include maxw($breakpoint-sm) {
    padding: 0 !important;
  }

  :global(.ant-card-body) {
    @include maxw($breakpoint-sm) {
      padding: 30px 16px !important;
    }
  }

  .profile__title {
    margin-bottom: 35px;
    padding-bottom: 17px;
    font-size: 24px;
    color: $colorBlue;
    position: relative;
    width: max-content;
    @include maxw($breakpoint-lg) {
      display: none;
    }

    &:after {
      content: '';
      display: block;
      margin: auto;
      height: 1px;
      width: 100%;
      background: $lineColorGray;
      position: absolute;
      bottom: 0;
    }
  }

  .profile__card {
    width: 100%;
    background: transparent;
    border-radius: 0;
    box-shadow: none;
  }

  &__meta__wrapper,
  &__form__row {
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      height: 1px;
      left: 0;
      right: 0;
      background: $lineColorGray;
    }
  }

  &__form__row {
    &:after {
      left: 23px;
      right: 23px;

      @include maxw($breakpoint-xl) {
        left: 16px;
        right: 16px;
      }

      @include maxw($breakpoint-md) {
        left: 8px;
        right: 8px;
      }

      @include maxw($breakpoint-sm) {
        left: 0;
        right: 0;
      }
    }
  }

  &__meta {
    &:global(.ant-card-meta) {
      margin-bottom: 0;
    }

    &__wrapper {
      margin-bottom: 35px;
      padding-bottom: 35px;
      position: relative;

      @include maxw($breakpoint-sm) {
        margin-bottom: 16px;
        padding-bottom: 16px;
      }
    }

    &__avatar {
      width: 100%;
      height: 100%;
      border-radius: 0;

      &__wrapper {
        display: flex;
        justify-content: space-between;
          width: 100%;

        @include maxw($breakpoint-sm) {
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
        }
      }

      &__inner {
        display: flex;
        align-items: center;
      }
    }

    :global(.ant-upload-wrapper) {
      text-align: left;
    }

    :global(.ant-card-meta-avatar) {
      width: 100%;
      padding: 0;
    }
  }

  &__form {
    &__input,
    &__select :global(.ant-select-selector) {
      padding: 10px 19px;
      font-size: 16px;
      min-height: 52px;
      font-style: italic;
      border-radius: 8px;
      border: 1px solid #D1D5DB;

      &:global(.ant-input-affix-wrapper-disabled) {
        background: #CBCBCB;
      }

      :global(.ant-select-selector) {
        background: transparent !important;
      }
    }

    &__select :global(.ant-select-selector) {
      border: 1px solid #D1D5DB !important;
      background: $colorWhite !important;
    }

    &__select :global(.ant-select-selector),
    &__input :global(.ant-input) {
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus {
        -webkit-text-fill-color: $colorDark !important;
        -webkit-box-shadow: 0 0 0 1000px $colorWhite inset !important;
        transition: background-color 5000s ease-in-out 0s !important;
      }
    }

    &__label {
      :global(.ant-form-item-label) {
        label {
          font-weight: 700;
          font-size: 20px;
          color: rgba(31, 31, 31, 0.70);
        }
      }

      &.profile__form__phone {
        :global(label) {
          gap: 5px;
        }
      }
    }

    &__submit {
      padding: 10px 35px;
      border-radius: 8px;
      font-weight: 500;
      background: $blueGradient;
      height: auto;
      min-height: 56px;
      border: 0;
      box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
      transition: box-shadow .3s ease-in-out;

        &:hover {
            background: $blueGradient!important;
            box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.1) ;
        }
    }

    &__row {
      margin-bottom: 24px;
      padding-bottom: 24px;
      position: relative;

      @include breakpoint(sm) {
        margin-bottom: 16px;
        padding-bottom: 16px;
      }

      &:global(.ant-row) {
        row-gap: 20px;
      }

      :global(.ant-form-item) {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &__tooltip {

      &__text {
        &:global(.ant-tooltip) {
          max-width: 260px;
          @include maxw($breakpoint-sm) {
            max-width: 200px;
          }
        }

        :global(.ant-tooltip-arrow) {
          &:before,
          &:after {
            background: #A8A8A8;
          }
        }

        :global(.ant-tooltip-inner) {
          border-radius: 4px;
          background: #A8A8A8;
          color: $colorWhite;
          font-size: 10px;
          line-height: 168%;
        }
      }
    }
  }

  &__referral__link {
    width: 100%;
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include maxw($breakpoint-sm) {
      margin-bottom: 100px;
    }

    &__inner {
      margin-top: 15px;
      display: flex;
      gap: 42px;

      @include maxw($breakpoint-md) {
        gap: 12px;
      }
    }

    :global(.ant-typography) pre {
      margin: 0;
      padding: 14px 40px;
      border-radius: 8px;
      border: 1px solid #D1D5DB;
      background: #C1C1C1;
      font-size: 16px;
      font-style: italic;

      @include maxw($breakpoint-md) {
        padding: 13px;
      }
    }

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      width: 58px;
      border-radius: 8px;
      border: 1px solid #D1D5DB;
      background: #C1C1C1;

      @include maxw($breakpoint-sm) {
        width: 45px;
      }

      &:global(.anticon) {
        border: 0;
      }
    }

    span:global(.anticon-copy),
    span:global(.anticon-check) {
      padding: 4px 10px;
      height: 100%;
      background: rgba(150, 150, 150, 0.1);
      border-radius: 8px;
      cursor: pointer;

      svg {
        width: 34px;
        height: 34px;

        path {
          fill: $colorDark;
          fill-opacity: 0.7;
        }
      }
    }

    article:global(.ant-typography) {
      flex: 1 0 0;
      cursor: pointer;

      @include maxw($breakpoint-md) {
        width: 100%;
      }
    }
  }
}

