.text_area_form {
    margin-left: auto;
    margin-right: auto;
    width: 343px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 18px 16px;
    border-radius: 5px;
    border-color: transparent;
    background-color: #EAF9FE;
    color: #1F1F1F;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.4;
    @media (min-width: 1024px) {
        padding: 90px 64px;
        border-radius: 10px;
        width: 733px;
        height: 397px;
      }
    &:focus {
        border: 1px solid transparent;
    }
}