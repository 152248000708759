.details_form {
  width: 343px;
  margin-top: 32px;
  @media (min-width: 1024px) {
    width: 430px;
    margin-top: 48px;
  }
  &__label {
    width: 343px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 16px;
    @media (min-width: 1024px) {
      margin-top: 16px;
      &:nth-child(4) {
        margin-bottom: 48px;
      }
    }
    :global(.ant-form-item-control-input) {
      padding-top: 6px;
      padding-bottom: 6px;
      @media (min-width: 1024px) {
        padding-top: 8px;
        padding-bottom: 8px;
      }
    }
    :global(.ant-form-item-label >label) {
      height: 22px;
      @media (min-width: 1024px) {
        height: 23px;
      }
    }
    @media (min-width: 1024px) {
      margin-bottom: 32px;
      width: 430px;
    }
    :global(.ant-form-item-label) {
      padding: 0px;
    }
    :global(.ant-form-item-label >label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before) {
      display: none;
    }
    :global(.ant-form-item-label >label) {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.6;
      @media (min-width: 1024px) {
        font-size: 16px;
        font-weight: 600;
        line-height: 1.4;
      }
    }
  }
}
.book_page__form_item {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.4;
}
.book_details__radio_btn {
  margin-top: 9px;
  margin-bottom: 73px;
  @media (min-width: 1024px) {
    margin-top: 0px;
    margin-bottom: 0px;
  }
}