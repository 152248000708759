@import "../../../../../../../../styles/mixins/variables";

.ant-drawer {
    .ant-drawer-body {
        background: $colorGrayBg;

        @media screen and (max-width: 1024px) {
            background: $colorWhite;
        }
    }

    .ant-drawer-header {
        padding: 10px 24px;
        background: #000000;

        .ant-drawer-title {
            color: $colorWhite;
        }
    }

    .ant-drawer-close {
        path {
            fill: $colorWhite;
        }
    }
}

.audio-page__list {
    padding: 25px;
    width: 34%;
    height: calc(100vh - 90px);
    overflow-y: auto;

    @media screen and (max-width: 1480px){
        width: 50%;
    }

    @media screen and (max-width: 950px) {
        height: initial;
        width: 100%;
    }

    @media screen and (max-width: 768px) {
        padding: 0;
    }

    .ant-list-items {
        gap: 18px;
        display: flex;
        flex-direction: column;
    }
}

.audio-page__list__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img.ant-image-img {
        width: 80px;
        height: 80px;
    }

    span.ant-typography {
        color: rgba(31,31,31,.5);
    }
}

.audio-page__list__item.ant-list-item {
    outline: 2px solid #D9D9D9;
    outline-offset: -2px;
    border-radius: 10px;
    width: 100%;
    max-height: 200px;
    background: $colorWhite;
    flex-direction: column;
    display: flex;
    align-items: flex-start;
    padding: 0;

    .ant-skeleton {
        padding: 24px;
    }
}

.audio-page__item__main {
    overflow: hidden;
    width: 100%;
}

.audio-page__item__main__text {
    padding: 8px 0 13px 13px;
    overflow-y: auto;
    margin-right: 8px;
    max-height: 108px;
    height: 100%;

    span.ant-typography {
        font-size: 14px;
        line-height: 26px;
        color: #7B7B7B;
        display: block;
        font-weight: 300;
    }
}

.audio-page__item__main__inner {
    padding: 8px 13px;
    font-size: 10px;
    width: 100%;
    line-height: 120%;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 768px) {
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
    }

    span {
        background: #D9D9D9;
        padding: 4px 26px;
        border-radius: 5px;
    }
}

button.ant-btn.audio-page__list__loadmore.ant-btn-default {
    margin: 13px auto 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 38px;
    width: 108px;
    border-radius: 5px;
    background: $darkGradient;
    font-size: 12px;
    line-height: 1;
    outline: none;
    border: 0;

    span {
        color: $colorWhite;
    }

    &:hover {
        outline: none;
        border: 0;
        background: $darkGradient;

        span {
            color: $colorWhite;
        }
    }
}

.audio-page__item__footer {
    border-top: 2px solid #D9D9D9;
    height: 56px;
    padding: 8px 13px;
    display: flex;
    gap: 16px;
    width: 100%;

    @media screen and (max-width: 768px) {
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
    }

    button {
        border: 1px solid rgba(31,31,31,.5);
        border-radius: 5px;
        color: rgba(31,31,31,.5);
        font-size: 14px;
        line-height: 1;
        font-weight: 500;
        display: flex;
        align-items: center;

        @media screen and (max-width: 768px) {
            padding: 0 6px;
        }

        .ant-btn-icon {
            margin-inline-end: 4px!important;
            font-size: 17px;
        }

        &:first-of-type {
            margin-right: auto;
        }
    }
}
