@import "../../../../../../styles//mixins//variables.scss";

.checklist_page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;
    :global(.ant-typography) {
        margin-bottom: 0px;
    }
}
.checklist_description {
    width: 329px;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.6;
    @media (min-width: 1024px) {
        width: 550px;
        font-size: 24px;
        font-weight: 600;
        line-height: 1.3;
    }
    @media (min-width: 1440px) {
        width: 741px;
        font-size: 24px;
        font-weight: 600;
        line-height: 1.3;
    }
}
.checklist_wrap {
    width: 265px;
    padding: 0px 16px;
    @media (min-width: 1024px) {
        width: 569px;
    }
    :global(.ant-typography):first-child {
        margin-bottom: 16px;
    }
}
.checklist_title {
    margin-bottom: 16px;
    background: radial-gradient(192.53% 172.33% at 0% 0%, #6A74D1 38.54%, #9B60A5 72.4%, #9B60A5 99.48%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    max-width: max-content;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.4;
    @media (min-width: 1024px) {
        font-size: 24px;
        line-height: 1.0;
    }
}
.checklist {
    :global(.ant-list-item .ant-list-item-meta .ant-list-item-meta-title) {
        margin: 0;
        font-size: 12px;
        font-weight: 400;
        line-height: 1.4;
    @media (min-width: 1024px) {
        font-size: 16px;
        font-weight: 500;
        line-height: 1.4;
    }
    }
    :global(.ant-btn.ant-btn-icon-only) {
        width: 40px;
    }
    :global(.ant-list-item) {
        padding: 8px 0px;
        border: none;
    }
}
.checklist_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    border: none;
    background: transparent;
    @media (min-width: 1024px) {
        padding: 7px;
        width: 40px;
        height: 40px;
        border: 0.5px solid rgba(31, 31, 31, 0.20);
        border-radius: 10px;
        background: $greenGradient;
    }
    :global(.ant-btn-icon svg) {
        width: 16px;
        height: 16px;
        @media (min-width: 1024px) {
            width: 24px;
            height: 24px;
            color: #fff;
        }
    }
}
