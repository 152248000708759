@import "../../../../../../../../styles/mixins/variables";
@import "../../../../../../../../styles/mixins/breakpoints";

.profile__info {
  &:global(.ant-col) {
    padding: 0;
    text-align: left;
    overflow: hidden;
  }

  &__name,
  &__option {
    &:global(.ant-typography) {
      margin-bottom: 8px;
      font-size: 20px;
      @include maxw($breakpoint-sm) {
        font-size: 18px;
      }
    }
    color: $colorBlue;
  }

  &__name:global(.ant-typography) {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__email {
    color: #6B7280;
    font-size: 16px;
    &:global(.ant-typography) {
      margin: 0;
    }
  }
}
