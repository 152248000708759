@import "../../../../../../../../../styles/mixins/variables.scss";

.row--applied {
  color: white;
  background: $colorLightGreenBg;
  pointer-events: none;
}

.row--expired {
  background: $colorGrayBg;
  pointer-events: none;
}

.row--added {
  background: $colorLightBlue;
  pointer-events: none;
}
