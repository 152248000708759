@import '../../../../../../../../styles/mixins/variables.scss';
@import '../../../../../../../../styles/mixins/breakpoints';

:global(.app-dark-mode ){
    .billing__selector__head {
        &:after {
            background-color: rgba(255, 255, 255, .2);
        }
    }
}

.billing__selector__head {
    padding: 23px 40px;
    margin-bottom: 26px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    position: relative;

    @include maxw($breakpoint-md) {
        margin-bottom: 10px;
        padding: 15px 40px 0;
        justify-content: center;
    }

    &:after {
        content: '';
        background: rgba(31, 31, 31, .2);
        height: 1px;
        position: absolute;
        bottom: 0;
        width: calc(100% + 80px);
        left: -40px;

        @include maxw($breakpoint-md) {
            display: none;
        }
    }

    h2 {
        margin: 0;
        padding: 0;
        font-family: var(--font-family);
        font-weight: 400;
        font-size: 24px;
        line-height: 100%;
        color: #000;

        @include maxw($breakpoint-md) {
            display: none;
        }
    }

    :global(.ant-btn) {
        background: radial-gradient(192.53% 172.33% at 0% 0%, #6A74D1 38.54%, #9B60A5 72.4%, #9B60A5 99.48%);
        color: $colorWhite;
        font-size: 16px;
        line-height: 0;
        border: 0;
        outline: none;
        border-radius: 5px;
        display: flex;
        gap: 5px;
        align-items: center;
        height: 40px;
        cursor: auto;
        padding: 0 20px;

        @include maxw($breakpoint-md) {
            font-size: 14px;
            font-weight: 700;
            line-height: 12px;
            height: 40px;
            padding: 0 35px;
            border-radius: 8px;
        }

        strong {
            &:first-letter {
                text-transform: uppercase;
            }
        }

        span {
            font-weight: 500;
        }

        &:hover, &:active {
            color: $colorWhite;
        }
    }
}
