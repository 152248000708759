@import "../../../../styles/mixins/variables.scss";


.image-upscale {
  padding: 40px;
}

span.ant-upload-wrapper.image-upscale__upload.ant-upload-picture-card-wrapper {
  max-width: 800px;
  display: flex;
  margin: 0 auto;

  &:before, &:after {
    display: none;
  }

  .ant-upload-list {
    width: 100%;
    height: 0;
    padding-bottom: calc(70%);
    position: relative;

    @media screen and (max-width: 768px){
      padding-bottom: calc(100%);
    }

    div.ant-upload-list-item-container {
      width: calc(100% - 40px);
      height: 0;
      margin-block: 0;
      margin-inline: 0;
      padding-bottom: calc(70% - 100px);
      position: absolute;
      left: 20px;
      top: 20px;
      right: 20px;
      bottom: 80px;
      z-index: 3;

      .ant-upload-list-item {
        position: absolute;
        width: 100%;
        padding: 0;

        &:before {
          width: 100%;
          height: 100%;
        }

        img {
          object-fit: cover;
        }
      }
    }
  }

  div.ant-upload.ant-upload-select {
    position: absolute;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    top: 0;
    left: 0;
    z-index: 1;
  }
}

.upscale-change-image {
  position: absolute;
  bottom: 25px;
}

.upscale-icon {
  justify-content: center;
  svg {
    fill: $colorPrimary;
    width: 100px;
    height: auto;
  }
}
