@import "../../../../../../styles/mixins/variables";

.tabs__images {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr minmax(202px, 1fr) 1fr 1fr;
  grid-column-gap: 4px;
  grid-row-gap: 4px;
  margin-bottom: 36px;

  @media screen and (max-width: 1400px) {
    grid-template-columns: repeat(7, 1fr);
  }

  img {
    width: 100%;
    border-radius: 10px;
    object-fit: cover;
  }
}

.tabs__title {
  composes: tabs__head__title from '../../index.module.scss';
}

.tabs__explore {
  composes: tabs__head__link from '../../index.module.scss';
}

.tabs__images__large {
  img {
    &:nth-child(5) {
      grid-row: 1 / span 2;
      grid-column: 5;
      height: 100%;
    }
  }
}

.tabs__images__slider {
  margin-top: 29px;

  img {
    width: 175px;
    height: 173px;
    object-fit: cover;

    @media screen and (max-width: 768px) {
      border-radius: 10px;
    }
  }

  :global(.swiper-slide) {
    width: auto;
    height: auto;

    @media screen and (max-width: 768px){
      margin-top: 0!important;
    }
  }

  :global(.swiper) {

    @media screen and (max-width: 768px){
      margin-left: -15px;
      margin-right: -15px;
    }
  }
}

.tabs__images__head {
  composes: tabs__head from '../../index.module.scss';
}

.tabs__images__wrap {
  margin-top: 54px;

  @media screen and (max-width: 768px){
    margin-top: 0;
    margin-left: -17px;
    margin-right: -17px;
    width: calc(100% + 34px);
  }
}
