.radio_group {
    display: flex;
    gap: 16px;
    min-width: 320px;
    width: 100%;
    &__radio_btn {
      padding: 8px 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 30px;
      border-radius: 5px;
      @media (min-width: 1024px) {
        height: 40px;
      }
      :global(.ant-radio-button.ant-radio-button-checked) {
        border-radius: 5px;
        border-color: transparent;
        background: radial-gradient(192.53% 172.33% at 0% 0%, #6A74D1 38.54%, #9B60A5 72.4%, #9B60A5 99.48%);
      }
      &:not(:first-child) {
        border: 1px solid #d9d9d9;
      }
      &:not(:first-child)::before {
        width: 0px;
      }
      & > span {
        font-size: 12px;
        font-weight: 400;
        line-height: 1.4;
        @media (min-width: 1024px) {
          font-size: 16px;
          font-weight: 500;
          line-height: 1.5;
        }
      }
    }
    :global(.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)) {
      background: radial-gradient(192.53% 172.33% at 0% 0%, #6A74D1 38.54%, #9B60A5 72.4%, #9B60A5 99.48%);
      border-color: transparent;
    }
  }








