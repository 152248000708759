@import "../../../../../../styles/mixins/variables.scss";

.history {
  background: #F1F1F1;
  padding: 55px 47px;
  height: 100%;

  @media screen and (max-width: 1024px){
    height: calc(100vh - 60px);
  }

  @media screen and (max-width: 768px){
    padding: 20px;
  }
}

.history__buttons {
  margin-bottom: 20px;
  display: flex;
  gap: 20px;

  :global(button.ant-btn.ant-btn-primary) {
    background: $greenGradient;
    border: 0;
    box-shadow: none;
    width: 110px;
    height: 38px;
    display: flex;
    gap: 6px;
    align-items: center;
    justify-content: center;

      &:hover {
          background: $greenGradient;
      }

    &:disabled {
      background: $grayGradient;
    }

    span {
      color: $colorWhite;
      font-weight: 500;
    }

    svg {
      path {
        fill: $colorWhite;
      }
    }
  }

  :global(.ant-input-affix-wrapper) {
    width: calc(50% - 15px);
    padding: 0 11px;

    @media screen and (max-width: 768px){
      width: 100%;
    }

    svg {
      width: 20px;
      height: 20px;
      fill: #949BAB;
      margin-right: 8px;
    }
  }

  input {
    height: 36px;
    border-radius: 5px;
    border: 1px solid rgba(31, 31, 31, 0.70);
  }
}

.history__heading {
  margin-bottom: 20px;

  > span {
    color: rgba(31, 31, 31, 0.70);
    display: block;
    margin-bottom: 9px;
  }

  button {
    background: $greenGradient;
    border: 0;
    box-shadow: none;
    width: 110px;
    height: 38px;
    display: flex;
    gap: 6px;
    align-items: center;
    justify-content: center;

    span {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
    }
  }
}

.history__table {
  :global(.ant-table-thead) {
    display: none;
  }

  :global(.ant-table-placeholder) {
    :global(.ant-table-cell) {
      display: flex;
      justify-content: center;
      width: 100%;
    }
  }

  :global(.ant-table-tbody) {
    display: flex;
    flex-direction: column;
    gap: 20px;

    tr {
      background: #fff;
    }
  }

  :global(.ant-table) {
    background: 0;
  }

  :global(td.ant-table-cell) {
    background: 0!important;

    &:nth-child(2) {
      width: 100%;
    }
  }
}

:global(.app-dark-mode) {
  .history__table {

    :global(.ant-table-tbody) {
      tr {
        background: $darkModeBg;
        border: 1px solid $colorWhite;
      }
    }

    :global(.ant-checkbox-inner) {
      border: 1px solid $colorWhite;
    }

    :global(.anticon-download) {
      svg {
        fill: $colorWhite;
      }
    }
  }

  .history {
    background: $darkModeBg;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.75);
  }
}
