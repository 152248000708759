@import "../../../../../../../../styles/mixins/variables.scss";

:root {

    @keyframes overlay {
        0% {
            width: var(--starting-width);
            background: $darkGradient;
        }
        100% {
            width: 0;
            background: $darkGradient;
        }
    }
}

.profile {
    &__resend__button {
        height: 51px;
        border-radius: 10px;
        background: $darkGradient;
        border: 2px solid #D9D9D9;
        color: $colorWhite;
        font-weight: 500;
        box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.02);
        overflow: hidden;
        transition: all ease .3s;

        :global(span) {
            color: #fff;
            z-index: 2;
            position: relative;
        }

        &:global(.ant-btn-dashed:disabled) {
            background: #ccc;
        }

        &:hover {
            :global(span) {
                color: $colorPrimary;
            }
        }
    }

    &.disabled {
        background: $darkGradient;
        cursor: not-allowed;
        position: relative;
        color: $lineColorGray;
    }
}


.animated {
    position: absolute;
    width: 100%;
    height: 100%;
    content: '';
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    animation: overlay var(--delay-duration) linear;
}


