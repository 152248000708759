.signin {
  margin-top: 28px;

  @media screen and (max-width: 768px) {
    margin-top: 0;
  }

  :global(.ant-form-item) {

    :global(.ant-input) {
      margin: 0 0 14px;
    }
  }
}

.signin__links {
  margin-top: 25px;
  composes: auth__links from '../../index.module.scss';
  padding: 36px 0;

  @media screen and (max-width: 1024px){
    padding: 15px 0;
  }

  @media screen and (max-width: 768px){
    padding: 44px 0 8px 0;
  }
}

.signin__password {

  :global(.ant-row) {

    :global(#password_help) {
      line-height: 0;
    }

    :global(.ant-form-item-explain-error) {
      transform: translateY(25%) !important;
    }

    :global(.ant-input) {
      margin-bottom: 19px;

      @media screen and (max-width: 768px) {
        margin-bottom: 15px;
      }
    }
  }
}

.signin__forgot {
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  padding-bottom: 43px;

  a {
    color: #0288D1;
  }

  @media screen and (max-width: 1024px){
    padding-bottom: 20px;
  }

  @media screen and (max-width: 768px){
    padding-bottom: 15px;
  }

  :global(.ant-form-item-control-input) {
    min-height: initial;
  }
}
