.billing__upgrade {
  border-radius: 15px;
  overflow: hidden;

  &:global(.ant-modal) {
    :global(.ant-modal-content) {
      padding: 0;

      :global(.ant-modal-body) {
        padding: 0;
      }

      :global(.ant-modal-footer) {
        display: none;
      }
    }
  }
}

.billing__upgrade__right__descr {
    font-size: 15px;
    font-weight: 500;
}

.billing__upgrade__right {
  padding: 25px 30px 15px 30px;

  @media screen and (max-width: 768px) {
    padding: 40px 15px;
  }

  :global(div.ant-spin) {
    top: 50%;
    transform: translateY(-50%);
  }

  span {
    :global(.anticon) {
      font-size: 20px;
    }
  }

  h2 {
    margin-bottom: 0;
    font-size: 36px;
    line-height: 120%;

    @media screen and (max-width: 768px) {
      font-size: 28px;
    }
  }

  ul {
    margin-top: 35px;
    margin-bottom: 35px;
    list-style-type: none;

    li {
      margin-inline: 0;
      margin-bottom: 20px;
      font-weight: 500;
      font-size: 15px;
      line-height: 120%;
      display: flex;
      align-items: center;
      gap: 10px;

      :global(span.anticon) {
        font-size: 23px;
        color: rgb(29, 193, 152);
      }
    }
  }
}

.billing__upgrade__right__list {
  border-top: 1px solid #E1E1E1;
  margin-top: 15px;
  padding-top: 15px;
}

.billing__payment__value {
  border-top: 1px solid #E1E1E1;
  padding-top: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 15px;
  font-weight: 500;
  line-height: 120%;
}
