@import "../../../../../../styles/mixins/breakpoints.scss";
@import "../../../../../../styles/mixins/variables.scss";

.chat-history-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 52px;
    margin-bottom: 11px;
    border: 2px solid #cccccc;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;

    @media screen and (max-width: 768px) {
        height: 50px;
        border-radius: 5px;
        margin-bottom: 10px;
        background: #fff;
    }

    &:hover, &.chat-history-item--selected {
        background-color: rgba(0, 0, 0, 0.03);
        border: 1px solid rgba(31, 31, 31, 0.2);
    }
}

.chat-history-item__inner {
    padding: 10px;
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;

    button.ant-btn {
        background: transparent;
        border: 0;
        box-shadow: none;
        width: initial;
        display: flex;
        align-items: center;
        padding: 0;
        margin-right: 5px;

        .ant-btn-icon {
            svg {
                font-size: 20px;
                @media screen and (max-width: 768px) {
                    font-size: 18px;
                }
            }
        }
    }

    &.ant-list-item {
        padding: 10px 15px;
        @include breakpoint(sm) {
            padding: 10px 15px;
        }
    }

    input {
        margin: 0 10px;
        height: 32px;
    }

    .anticon-message svg {
        width: 20px;
        height: 20px;
        @media screen and (max-width: 768px) {
            width: 18px;
            height: 18px;
        }
    }

    span {
        display: block;
    }
}

.chat-add-btns {
    gap: 16px;

    @media screen and (max-width: 768px) {
        gap: 10px;
    }

    button.ant-btn.ant-btn-default {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: initial;
        padding: 7px;
        border: 0.5px solid rgba(31, 31, 31, 0.20);
        border-end-start-radius: initial;
        border-radius: 10px;

        @media screen and (max-width: 768px) {
            padding: 15px 15px;
            border-radius: 5px;
            border: 0;
        }

        &:first-of-type {
            span.anticon {
                &:first-of-type {
                    background: $greenGradient;
                }
            }
        }

        &:nth-of-type(2) {
            span.anticon {
                &:first-of-type {
                    background: $darkGradient;
                }
            }
        }

        &:last-of-type {
            span.anticon {
                &:first-of-type {
                    background: $purpleGradient;
                }
            }
        }

        span {
            font-weight: 500;
        }


        span.anticon {
            width: 56px;
            height: 56px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
            margin-inline-end: 8px;

            @media screen and (max-width: 768px) {
                border-radius: 2px;
                width: 35px;
                height: 35px;
                margin-inline-end: 8px;
            }

            &.anticon-arrow-right {
                margin-left: auto;
                height: 24px;
                width: 24px;
                margin-inline-end: 14px;

                @media screen and (max-width: 768px) {
                    width: 20px;
                    height: 20px;
                    margin-inline-end: 0;
                }
            }

            &:first-of-type {
                svg {
                    color: $colorWhite;
                }
            }

            svg {
                width: 24px;
                height: 24px;

                @media screen and (max-width: 768px) {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
}

h2.chat-history-title {
    padding: 10px 10px;
    background: #cccccc;
    margin: 0;

    @media screen and (max-width: 768px) {
        font-size: 16px;
        font-weight: 600;
        line-height: 22.4px;
        padding: 8px 10px;
        background: #ddd;
    }
}

.chat-history-sidebar {
    padding: 10px;
    height: calc(100vh - 230px);

    @media screen and (max-width: 1024px) {
        height: calc(100vh - 295px);
    }

    @media screen and (max-width: 768px) {
        height: calc(100vh - 245px);
        padding: 16px;
        background: $colorGrayBg;
    }

    .infinite-scroll-component__outerdiv {
        height: 100%;

        .infinite-scroll-component {
            padding-right: 5px;
        }
    }
}

.chat-history-list {
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 0;

    .ant-list-items {
        .ant-list-item {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            border: 0;
            border-block-end: 0;
            padding: 0;

            &:hover {
                background: none;
                border: 0;
            }
        }
    }
}

.chat-history-item__options {
    button {
        display: flex;
        align-items: center;
        padding: 0 14px;

        svg {
            display: block;
        }
    }
}

.chat-history-row {
    margin-bottom: 5px;
}

.chat-history-date {
    margin-bottom: 5px;
    font-weight: bold;
    text-align: left;
}
