@import "../../../../../../styles/mixins/variables.scss";
@import "../../../../../../styles/mixins/breakpoints.scss";

.app-dark-mode {
    .single-tool__title-wrap {
        background: $darkModeBg;
    }

    .single-tool__tips,
    .single-tool__prompt {

        .single-tool__tip__body,
        .ant-btn {
            background: $colorDark
        }

        &.single-tool__prompt--new button.ant-btn,
        .ant-btn {
            border-color: $colorWhite;

            svg {
                g, path {
                    fill: $colorWhite
                }
            }
        }
    }
}

.single-tool {
    padding: 33px 10px 90px 30px;
    @media screen and (max-width: 992px) {
        padding: 30px;
    }
    @media screen and (max-width: 720px) {
        padding: 16px;
    }
}

.single-tool__about {
    a {
        font-weight: 600;
        color: $colorPrimary;
        cursor: pointer;
    }
}

.single-tool__remove.ant-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
}

.single-prompt__remove.ant-btn {
    margin: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 24px !important;
    height: 24px;
}

.single-tool__back.ant-btn.ant-btn-default {
    color: $colorDark;
    margin-bottom: 32px;
    display: flex;
    align-items: center;
    gap: 9px;
    border: 0;
    box-shadow: none;
    background: 0;
    padding-left: 0;

    @media screen and (max-width: 720px) {
        margin-bottom: 10px;
    }

    span.ant-btn-icon {
        margin-inline-end: 0 !important;

        svg {
            width: 16px;
            height: 16px;
        }
    }

    &:hover {
        color: $colorPrimary;
        background: 0;
    }
}

.single-tool__categories {
    display: flex;
    gap: 10px;
    margin-bottom: 30px;


    @media screen and (max-width: 720px) {
        margin-bottom: 10px;
    }

    & > div {
        padding: 4px 11px 4px 8px;
        border-radius: 20px;
        color: $colorWhite;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        position: relative;
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .category {
        padding: 4px 18px 4px 18px;
        background: $colorPrimary;
        border-radius: 20px;
        color: $colorWhite;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        position: relative;
        display: flex;
        align-items: center;
        gap: 8px;

        @media screen and (max-width: 720px) {
            padding: 4px 8px 4px 8px;
        }
    }
}

.single-tool__left {
    padding-right: 30px;
    @media screen and (max-width: 992px) {
        padding-right: 0;
    }

    .single-tool__prompts {

        &.show {
            .ant-col {
                display: flex !important;
            }
        }

        .ant-col {
            align-self: stretch;
            display: flex;

            &:not(.prompt-add) {
                @media screen and (max-width: 720px) {
                    display: none;
                }
            }

            &:first-of-type, &:nth-of-type(2) {
                @media screen and (max-width: 720px) {
                    display: flex;
                }
            }

            .ant-form-item {
                margin: 0;
                width: 100%;
            }
        }
    }
}

.single-tool__title-wrap {
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    padding: 18px 20px 18px 20px;
    gap: 18px;
    background: $colorWhite;
}

.single-tool__title__inner {
    width: 100%;

    input {
        width: 100%;
        height: 100%;
    }
}

h2.single-tool__title {
    margin: 0;
    display: flex;
    align-items: center;

    @media screen and (max-width: 720px) {
        margin-left: -16px;
        width: calc(100% + 32px);
        margin-bottom: 24px;
        padding: 8px 12px 8px 17px;
        font-size: 18px;
        line-height: 20px;
        gap: 5px;
        align-items: flex-start;
    }

    svg {
        width: 31px;
        height: 31px;

        @media screen and (max-width: 720px) {
            width: 17.5px;
            height: 17.5px;
        }
    }
}

.single-tool__right {
    border-left: 2px solid rgba(31, 31, 31, .1);
    margin-bottom: 29px;
    padding: 61px 94px 0 30px;

    @media screen and (max-width: 1400px) {
        padding: 20px;
    }
    @media screen and (max-width: 992px) {
        padding: 20px 0;
        border-left: 0;
    }
}

.single-tool__prompt__descr {
    span {
        color: $colorPrimary;
        font-weight: 700;
    }
}

.single-tool__prompt {
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    overflow: hidden;
    background: $colorWhite;
    display: flex;
    flex-direction: column;
    width: 100%;

    @media screen and (max-width: 720px) {
        border-radius: 10px;
    }

    h4 {
        font-weight: 600;
        padding: 14px;
        color: $colorWhite;
        background: $colorPrimary;
        margin: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media screen and (max-width: 720px) {
            padding: 8px 16px;
        }
    }

    div.ant-typography {
        padding: 16px 16px 20px 16px;
        margin: 0;

        @media screen and (max-width: 720px) {
            padding: 20px 16px 16px 16px;
        }
    }

    button.ant-btn {
        background: $colorPrimary;
        border: 1px solid $colorWhite;
        margin: auto 16px 22px 16px;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color: $colorWhite;
        width: fit-content;

        @media screen and (max-width: 720px) {
            padding: 4px 15px;
            margin: auto 16px 16px 16px;
        }

        &:hover, &:active {
            border-color: $colorWhite;
            color: $colorWhite;
        }


        span {
            line-height: 0;
            display: flex;
            align-items: center;
        }
    }

    &__body {
        padding: 16px;
    }

    &.single-tool__prompt--new {
        button.ant-btn {
            margin: 0 auto;
            padding: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            background: transparent;
            border-radius: 0 0 10px 10px;
            border: 1px dashed rgba(31, 31, 31, 0.70);

            .ant-btn-icon {
                width: 75px;
                height: 75px;
                color: rgba(31, 31, 31, 0.5);

                * {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}

.single-tool__tips {

    &.single-tool__tips--new {
        margin: 16px 0 0 !important;

        #tipCreateForm {
            margin-top: 0 !important;
        }

        .ant-col {
            margin-top: 16px !important;

            &:first-child {
                margin-top: 0 !important;
            }
        }

        .ant-form-item {
            margin-top: 0;
            padding: 0 8px;
        }

        .single-tool__tips__title {
            margin: 0;
            padding: 0 8px;
            width: 100%;
        }

        .single-tool__tip {
            padding-right: 30px;
        }

        .single-tip__remove {
            padding: 0;
            border: 0;
            display: flex;
            position: absolute;
            background: transparent;
            width: auto;
            right: 8px;
            top: 50%;
            transform: translateY(-50%);

            svg {
                width: 20px;
                height: 20px;
            }
        }
    }

    .ant-form-item,
    .ant-btn {
        width: 100%;
    }


    .single-tool__tip__body {
        border-radius: 10px;
        padding: 16px;
        background: $colorWhite;
    }

    .ant-btn {
        padding: 14px;
        height: auto;
        border-radius: 0 0 10px 10px;
        border: 1px dashed rgba(31, 31, 31, 0.70);

        svg {
            width: 27px;
            height: 27px;
        }
    }

    .ant-form,
    .ant-col {
        width: 100%;
        max-width: 426px;

        &:first-of-type {
            margin-top: 13px;
        }

        @media screen and (max-width: 992px) {
            max-width: 100%;
        }

        @media screen and (max-width: 720px) {
            display: none;
        }

        &:first-of-type, &:nth-of-type(2) {
            @media screen and (max-width: 720px) {
                display: block;
            }
        }
    }

    &.show {
        .ant-col {
            display: block;
        }
    }
}

.single__tool__modal {
    .ant-modal-footer {
        flex-wrap: wrap;
        @include maxw($breakpoint-sm) {
            padding-top: 0 !important;
            justify-content: flex-start !important;
            button {
                margin: 0 !important;
                width: 100%;
            }
        }
    }
}

.single-tool__tip {
    background: $colorWhite;
    padding: 16px 24px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    position: relative;

    div.ant-typography {
        display: flex;
        align-items: center;
        gap: 24px;
        margin: 0;

        svg {
            width: 20px;
            height: 20px;
        }
    }
}

.single-tool__view {
    justify-content: space-between;
    display: flex;
    margin-bottom: 10px;

    h4 {
        margin-bottom: 0;
    }

    a {
        color: $colorPrimary;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
    }
}
