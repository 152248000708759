.modal_add_voice {
    top: 40px;
    @media screen and (max-width: 620px) {
        width: 375px !important;
    }
    @media screen and (min-width: 1024px) {
        top: 10px;
    }
    @media screen and (min-width: 1650px) {
        top: 70px;
    }
    width: 585px !important;
    :global(.ant-modal-content) {
        padding: 0px;
    }
    :global(.ant-modal-close) {
        top: 22px;
        right: 24px;
        width: 24px;
        height: 24px;
    }
    :global(.ant-modal-close-x) {
        font-size: 24px;
    }
    :global(.ant-form-item-label) {
        padding: 0 0 10px;
    }
    :global(.ant-form-item-explain) {
        margin-right: auto;
        margin-left: auto;
        text-align: left;
        width: 514px;
        @media screen and (max-width: 620px) {
            width: 320px;
        }
    }
    :global(.ant-modal-header) {
        margin: 0;
        margin-bottom: 6px;
        padding: 22px 40px 22px 30px;
        border-radius: 10px 10px 0px 0px;
        background: #EAF9FE;
        color: rgba(31, 31, 31, 0.70);
    }
    :global(.ant-modal-title) {
        font-size: 24px;
        font-weight: 700;
        line-height: 1.1;
        color: rgba(31, 31, 31, 0.70);
    }
    :global(.ant-input) {
        padding: 14px 22px;
        border-radius: 10px;
        border-color: transparent;
        background: var(--eaf-9-fe, #EAF9FE);
        width: 514px;

        color: rgba(31, 31, 31, 0.70);
        font-size: 14px;
        font-weight: 600;
        line-height: 1.7;
        @media screen and (max-width: 620px) {
          width: 320px;
        }
        @media screen and (min-width: 1024px) and (max-width: 1440px){
            padding-top: 8px;
            padding-bottom: 8px;
        }
    }
    :global(.ant-modal-body) {
        text-align: center;
    }
    :global(.ant-modal-body label) {
        padding: 8px 40px;
        font-size: 15px;
        font-weight: 700;
        line-height: 1.12;
        color: #4A4A4A;
        @media screen and (max-width: 620px) {
            padding: 8px 20px;
        }
        @media screen and (min-width: 1024px) and (max-width: 1440px){
            padding-top: 4px;
            padding-bottom: 4px;
        }
    }
    :global(.ant-modal-body label)::before {
        display: none !important;
    }

    // dragger
    .upload_files {
        margin-bottom: 10px;
    }
    :global(.ant-upload-wrapper) {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    :global(.ant-upload-wrapper .ant-upload-drag) {
        width: 508px;
        border-color: #000;

        @media screen and (max-width: 620px) {
            width: 320px;
        }
    }
    :global(.ant-upload-wrapper .ant-upload-drag .ant-upload-btn) {
        padding-top: 8px;
        padding-bottom: 8px;
    }
    :global(.ant-upload-list.ant-upload-list-item-error) {
        color: #000;
    }
    :global(p.ant-upload-text) {
        color: rgba(31, 31, 31, 0.70) !important;
        font-size: 14px !important;
        font-weight: 600;
        line-height: 1.7;
        opacity: 0.5;
        @media screen and (max-width: 620px) {
            font-size: 12px !important;
        }
    }
    :global(p.ant-upload-hint) {
        color: #D3D3D3 !important;
        text-align: center;
        font-size: 12px !important;
        font-weight: 600;
        line-height: 2.0;
        opacity: 0.5;
        @media screen and (max-width: 620px) {
            font-size: 10px !important;
        }
    }
    .list_files__title {
      margin-top: 17px;
      margin-bottom: 10px;
      padding-top: 8px;
      padding-bottom: 8px;
      padding-left: 40px;
      text-align: left;
      color: #4A4A4A;
      font-size: 15px;
      font-weight: 700;
      line-height: 1.12;
      @media screen and (max-width: 620px) {
        padding-left: 20px;
    }
    }
    :global(.ant-list-items) {
        padding-top: 12px;
        padding-bottom: 20px;
        padding-left: 37px;
        padding-right: 37px;
        @media screen and (max-width: 620px) {
            padding-left: 20px;
            padding-right: 20px;
        }
    }
    :global(.ant-list-empty-text) {
        display: none;
    }
    :global(.ant-list-item) {
        margin-bottom: 14px;
        padding-top: 0;
        padding-bottom: 8px;
        padding-left: 8px;
        padding-right: 13px;
        display: flex;
        justify-content: space-between;
        border-block-end: 2px solid #FAFAFA;
        p {
            text-align: left;
            color: #D3D3D3;
            line-height: 1.0;
        }
    }
    :global(.ant-list-item):last-child {
        margin-bottom: 0;
    }
    .list_files__btns {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 25px;
        :global(.ant-btn) {
          margin: 0;
          padding: 0;
          width: 17px;
          height: 17px;
          border: none;
        }
        :global(.ant-btn):hover {
            svg > path {
                fill: #0288d1;
            }
        }
    }
    // notification quality
    .notification_quality {
        margin-right: auto;
        margin-left: auto;
        box-sizing: border-box;
        padding: 14px 22px 14px 30px;
        border-radius: 10px;
        background: #F1F1F1;
        width: 514px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 8px;

        @media screen and (max-width: 620px) {
            padding: 7px 11px 7px 15px;
            width: 320px;
        }

        & > p {
          text-align: left;
          color: rgba(31, 31, 31, 0.70);
          font-size: 12px;
          font-weight: 600;
          line-height: 2;
          opacity: 0.5;
        }
        & > p::before {
            content: '';
            display: block;
            margin: -0.5em 0;
        }
    }

    // description
    .description_item {
        margin-bottom: 10px;
    }
    .add_voice__description {
        height: 80px;
    }

    // agreement
    .item_agreement {
      margin-bottom: 24px;
      @media screen and (min-width: 1024px) and (max-width: 1440px){
        margin-bottom: 14px;
      }
        :global(.ant-checkbox-wrapper) {
          box-sizing: border-box;
          padding: 0;
          width: 505px;
          margin-right: 40px;
          margin-left: 40px;

          @media screen and (max-width: 620px) {
            width: 320px;
            margin-right: auto;
            margin-left: auto;
          }
        }
        :global(.ant-checkbox-inner) {
            border-radius: 2px;
        }
        :global(span):nth-child(2) {
            text-align: left;
            color: rgba(0, 0, 0, 0.85);
            font-size: 12px;
            font-weight: 400;
            line-height: 1.4;
        }
        :global(label > span):last-child {
            width: 435px;

            @media screen and (max-width: 620px) {
                width: 320px;
            }
        }
    }

    //  modal footer
    :global(.ant-modal-footer) {
        margin-top: 0;
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 30px;
        padding-right: 30px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-top: 1px solid rgba(31, 31, 31, 0.20);
        @media screen and (min-width: 1024px) and (max-width: 1440px){
            padding-top: 8px;
            padding-bottom: 8px;
          }
        :global(.ant-btn) {
            width: 160px;
            height: 53px;
            border-radius: 10px;
            font-size: 14px;
            font-weight: 500;
            line-height: 1.57;
        }
        :global(.ant-btn-default) {
            border-radius: 10px;
            border: 1px solid rgba(31, 31, 31, 0.70);
            box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);

            color: rgba(31, 31, 31, 0.70);
        }
        :global(.ant-btn-default):hover {
            background-color: #F1F1F1;
        }
    }
}