.single-player-wrapper {
  position: relative;
  padding-top: 56.25%;
  margin: 10px;
}

.single-react-player {
  max-height: 98vh;
  position: absolute;
  top: 0;
  left: 0;
}

.single-player-back {
  margin: 10px auto ;
  display: flex;
  justify-content: center;
  width: fit-content;
}