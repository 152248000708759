.forgot__links {
  margin-top: 25px;
  composes: auth__links from '../../index.module.scss';
  padding: 32px 0;
}

.forgot {
  margin-top: 28px;

  @media screen and (max-width: 768px) {
    margin-top: 0;
  }

  :global(.ant-form-item-explain-error) {
    transform: translateY(-110%)!important;
  }

  :global(.ant-form-item) {

    :global(.ant-input) {
      margin: 0 0 36px;
    }
  }
}
