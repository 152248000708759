.input_form {
    width: 343px;
    height: 40px;
    padding: 0 16px;
    border-radius: 2px;
    border: 1px solid #E0E0E0;
    background: #EAF9FE;
    color: #1F1F1F;

    font-size: 12px;
    font-weight: 400;
    line-height: 1.4;
    opacity: 0.5;
    &:focus,
    &:hover {
        border: 1px solid transparent;
        outline: none;
        border-inline-end-width: 0px;
    }
    @media (min-width: 1024px) {
        width: 430px;
        height: 56px;
        font-size: 16px;
        font-weight: 600;
    }
}
