@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import "./mixins/breakpoints.scss";
@import "./darkmode.scss";

html {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
}

* {
    margin: 0;
    padding: 0;
}

body {
    background-color: #f5f5f5;
    color: #46444c;
    position: relative;
    height: 100dvh;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

#root, #app-layout {
    min-height: 100dvh;
}

// styles for scrollbar

::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 9999px;
}

::-webkit-scrollbar-thumb {
    --tw-border-opacity: 1;
    background-color: rgba(217, 217, 227, .8);
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
    border-radius: 9999px;
    border-width: 1px;
}

::-webkit-scrollbar {
    height: 0.5rem;
    width: 0.5rem;

    @media screen and (max-width: 720px) {
        width: 0;
    }
}

.ant-notification-notice-message {
    margin-bottom: 0 !important;
}

.ant-pagination-item-link {
    > span {
        display: block;
    }
}
button {
    font-family: Poppins, sans-serif;
}

.ant-select {
    &.ant-select-open {
        .ant-select-arrow {
            transform: rotate(180deg);
        }
    }
}

* {
    scrollbar-color: rgba(217, 217, 227, .8) transparent;
    scrollbar-width: thin;
}

span.anticon-close {
    display: block;
}

.tool-output-wrapper {
    @media screen and (max-width: 990px) {
        min-height: initial !important;
    }
}

.ant-message-notice {
    .ant-message-notice-content {
        .ant-message-custom-content {
            display: flex;
            align-items: center;
        }
    }
}

.tools-form-item {
    label.ant-form-item-required {
        align-items: flex-start;
    }
}

// styles for modal

.modal-warning {
    width: auto !important;

    &.ant-modal {
        .ant-modal-content {
            .ant-modal-body {
                padding: 0;
                width: 710px;

                @media screen and (max-width: 768px) {
                    width: auto;
                }
            }
        }

        &.ant-modal-content {
            padding: 0;
            height: 108px;
            width: 286px;
            border-radius: 5px;
            box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.05);

            @media screen and (min-width: 375px) {
                width: 330px;
            }

            @media screen and (min-width: 768px) {
                width: 530px;
                border-radius: 20px;
            }

            @media screen and (min-width: 1440px) {
                width: 710px;
            }

            .ant-row {
                flex-flow: column;
                padding: 16px;
                height: 72px;
                width: 286px;
            }
        }

        &.ant-modal-confirm {
            .ant-modal-confirm-paragraph {
                width: 100%;
                max-width: 100%;
            }

            &-title {
                display: flex !important;
                align-items: center;
                padding: 8px 16px;
                color: $colorDark !important;
                background: $colorLightBlue;
                border-radius: 5px 5px 0 0;

                @media screen and (min-width: 768px) {
                    padding: 20px 30px;
                    height: 66px;
                    font-size: 18px !important;
                    border-radius: 20px 20px 0 0;
                }

                @media screen and (min-width: 1440px) {
                    padding: 32px;
                    height: 91px;
                    font-size: 24px !important;
                    font-weight: 700 !important;
                }
            }

            &-body {
                display: block;

                .anticon {
                    display: none;
                }
            }

            .ant-modal-confirm-content h4 {
                margin-top: 0 !important;
                margin-inline-start: 0 !important;
            }

            .ant-modal-confirm-btns {
                margin-top: 0;
                position: absolute;
                bottom: 40px;
                right: 40px;
                padding: 0;

                @media screen and (max-width: 768px) {
                    padding: 16px;
                    flex-wrap: wrap;
                    row-gap: 16px;
                    position: initial;
                }

                .ant-btn {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 53px;
                    width: 160px;
                    font-size: 14px;
                    font-weight: 500;
                    background-color: $colorPrimary;
                    border: none;
                    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.04);
                    border-radius: 10px;

                    &:hover, &:active {
                        background: rgb(36, 150, 202);
                    }
                }
            }
        }

        &-close {
            display: flex;
            align-items: center;
            justify-content: center;
            top: 6px;
            inset-inline-end: 7px;
            color: $colorBlack;

            @media screen and (min-width: 768px) {
                top: 22px;
                inset-inline-end: 27px;
            }

            @media screen and (min-width: 1440px) {
                top: 34px;
                inset-inline-end: 32px;
            }

            &-x {
                @media screen and (min-width: 768px) {
                    font-size: 18px;
                }

                @media screen and (min-width: 1440px) {
                    font-size: 24px;
                }
            }

            &:hover {
                background: none;
            }
        }
    }

    .ant-flex {
        font-size: 16px;
        font-weight: 700;
        color: $colorDark;

        .ant-typography {
            display: flex;
            align-items: center;
            font-size: 16px;
            font-weight: 700;
            color: $colorDark;
        }
    }


    span.ant-typography.opacity {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 400;
        color: $colorDark;
        opacity: 0.5;

        @media screen and (min-width: 1440px) {
            padding: 8px 0;
            font-size: 24px;
            font-weight: 600;
        }
    }

    .ant-modal-confirm-title, .ant-typography, .ant-col.opacity, .ant-btn {
        font-family: Poppins, sans-serif;
    }
}

.upper-first-letter {
    &:first-letter {
        text-transform: capitalize;
    }
}

.app-dark-mode .chat-upload-clip svg path {
    fill: rgb(0 0 0 / 85%);
}

.ant-modal.modal-component, .ant-modal.modal-confirm, .ant-modal-root {

    .ant-modal-content, .ant-modal-body {
        padding: 0;
        border-radius: 20px;
        overflow: hidden;


        .ant-modal-body {
            padding: 23px 32px;
            @include maxw($breakpoint-sm) {
                padding: 16px;
            }
        }

        .anticon-exclamation-circle {
            display: none;
        }

        .ant-modal-title {
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 26.4px;

            @include maxw($breakpoint-sm) {
                font-size: 22px;
            }
        }

        .ant-modal-footer, .ant-modal-confirm-btns {
            display: flex;
            justify-content: flex-end;
            padding: 23px 32px;
            gap: 10px;
            margin-top: 0;

            @media screen and (max-width: 768px) {
                padding: 16px;
                flex-wrap: wrap;
                row-gap: 16px;
                position: initial;
            }

            button.ant-btn {
                height: 53px;
                padding: 0 30px;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 22px;

                @media (max-width: 768px) {
                    height: 40px;
                    padding: 0 20px;
                }
            }
        }

        .ant-modal-confirm-content {
            margin-inline-start: 0;
            margin-block-start: 0;
            padding: 30px;
            max-width: 70%;

            @media screen and (max-width: 768px) {
                max-width: 100%;
            }

            h3 {
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                background: $purpleGradient;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                margin-bottom: 15px;
            }

            h4 {
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                background: $purpleGradient;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                margin-top: 24px;
                margin-bottom: 15px;
            }
        }

        .ant-modal-confirm-content {
            margin-inline-start: 0 !important;
        }

        .ant-modal-header, .ant-modal-confirm-title {
            background: $colorLightBlue;
            flex: 0 0 100%;
            height: 91px;
            display: flex;
            align-items: center;
            padding: 32px;
            font-size: 24px;
            font-weight: 700;
            line-height: 26.4px;
            color: rgba(31, 31, 31, 0.70);

            @include maxw($breakpoint-sm) {
                padding: 16px;
            }
        }

        span.ant-typography {
            margin-bottom: 10px;
            font-size: 16px;
            line-height: 140%;
            display: block;
            color: #000;
            opacity: .5;
        }

        .ant-form-item {
            margin-bottom: 14px;
            @include maxw($breakpoint-sm) {
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        h5.ant-typography {
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 22.4px;
            letter-spacing: 0.096px;
            margin-bottom: 14px;
            color: rgba(31, 31, 31, 0.7);
        }

        .ant-input-affix-wrapper {
            border: 0;
            padding: 0;
        }

        .ant-input-data-count {
            position: relative;
            bottom: 0;
        }

        .ant-input-suffix {
            bottom: -30px;
            position: absolute;
            right: 0;
        }

        textarea, input.ant-input {
            border: 1px solid #1f1f1f!important;
            border-radius: 10px;
            outline: none;
            min-height: 50px !important;
            padding: 16px 32px 16px 16px;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 19.6px;
            letter-spacing: 0.084px;
        }
    }
}

.modal-tooltip {
    &.ant-tooltip {
        max-width: 273px;
    }

    .ant-tooltip-inner {
        padding: 16px 13px;
        background: #fff;
        border-radius: 10px;
        color: rgba(31, 31, 31, 0.5);
        font-size: 14px;
        font-weight: 500;
        line-height: 157%;

    }
}

.custom-instruction-modal {
    .ant-modal-footer {
        border-top: 1px solid rgba(31, 31, 31, 0.2);
        width: 100%;
        display: flex;
        align-items: center;

        .switch-button {
            display: flex;
            align-items: center;
            gap: 10px;
            margin-right: auto;

            @include maxw($breakpoint-sm) {
                width: 100%;
                margin: 0;
                justify-content: flex-end;
            }

            span {
                font-weight: 500;
                line-height: 129%;
                color: #4a4a4a;
            }
        }
    }

    .ant-modal-content, .ant-modal-body {
        .ant-form-item {
            margin-bottom: 0;
        }

        .ant-form-item.form-input-wrap {
            margin-bottom: 14px;

            &:last-child {
                margin-bottom: 0;
            }
        }



        .ant-input-suffix {
            bottom: 8px;
            right: 8px;
        }

        span.ant-typography.modal-form-tip {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 4px;
            margin: 0;
            text-align: right;
            font-size: 12px;
            font-weight: 600;
            line-height: 200%;
            color: rgba(31, 31, 31, 0.5);
            cursor: pointer;
        }
    }
}

.ant-modal.modal-confirm {
    .ant-modal-body {
        padding: 0 !important;
        .ant-modal-confirm-paragraph {
            max-width: 100%;
        }
    }
}
