.book__title {
    background: radial-gradient(192.53% 172.33% at 0% 0%, #6A74D1 38.54%, #9B60A5 72.4%, #9B60A5 99.48%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    max-width: max-content;
    font-size: 24px !important;
    font-weight: 700;
    line-height: 1.1;
    @media (min-width: 1440px) {
          font-size: 64px !important;
  }
}

  