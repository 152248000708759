@import "../../../../../../styles//mixins//variables.scss";

.book_card__available {
    :global(.ant-btn-default):disabled {
      width: 100%;
      height: 40px;
      padding: 4px 8px;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.6;
      border-radius: 5px;
      background: $orangeGradient;
      color: #fff;
      @media (min-width: 1024px) {
        font-size: 12px;
      }
      @media (min-width: 1440px) {
        font-size: 16px;
      }
      @media (min-width: 1728px) {
        height: 56px;
        font-size: 24px;
      }
    }
}
.book_card__not_available {
    :global(.ant-btn-default):disabled {
      width: 100%;
      height: 40px;
      font-size: 12px;
      padding: 4px 8px;
      border-radius: 5px;
      background-color: #949BAB;
      color: #fff;
      @media (min-width: 1024px) {
        font-size: 12px;
      }
      @media (min-width: 1440px) {
        font-size: 16px;
      }
      @media (min-width: 1728px) {
        height: 56px;
        font-size: 24px;
      }
    }
}
.book_cover__wrap {
    position: relative;
    width: 100%;
    height: 100%;
    min-width: 110px;
    min-height: 154px;
    aspect-ratio: 20 / 28;
    transition: all 1000ms;
    transform-style: preserve-3d;
    @media (min-width: 1024px) {
      min-width: 96px;
      min-height: 120px;
      width: 100%;
      aspect-ratio: 4 / 5;
    }
}
.book_cover {
    height: 100%;
    :global(.ant-radio-button.ant-radio-button-checked) {
      border-radius: 5px;
      border: 3px solid transparent;
      background: $orangeGradient;
      -webkit-mask:
      linear-gradient(#fff 0 0) padding-box, 
      linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
    }
    @media (min-width: 1024px) {
      padding: 0px;
      flex: 0 0 25%;
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 25%;
      max-width: 25%;
      height: 100%;
    }
  
    @media screen and (max-width: 1024px) {
      width: 200px;
    }
  }
  
.book__title,
.book__title_disabled {
    margin-top: 4px;
    margin-bottom: 4px !important;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.4;
    background: $orangeGradient;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    @media (min-width: 1024px) {
      font-size: 12px;
    }
    @media (min-width: 1440px) {
      font-size: 16px;
    }
    @media (min-width: 1728px) {
      margin-bottom: 4px !important;
      font-size: 24px;
      line-height: 1;
    }
}
.book__title_disabled {
  background: transparent;
  -webkit-text-fill-color: #949BAB;
}
.front_cover_0,
.front_cover_1,
.front_cover_2,
.front_cover_3 {
  width: inherit;
  height: 100%;
  transform-style: preserve-3d;
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  z-index: 10;
}
.front_cover_0 {
  background-image: url('../../images/kids-book-front.jpg');
}
.front_cover_1 {
  background-image: url('../../images/cook-book-front.jpg');
}
.front_cover_2 {
  background-image: url('../../images/e-book-front.jpg');
}
.front_cover_3 {
  background-image: url('../../images/graphic-novel-front.jpg');
}
.front_cover_0::before,
.front_cover_1::before,
.front_cover_2::before,
.front_cover_3::before {
  display: inline-block;
  content: '';
  position: absolute;
  width: 40px;
  height: 100%;
  background-size: 100% 100%;
  transform: rotateY(-90deg) translateZ(20px) translateX(-20px);
  @media (min-width: 1024px) and (max-width: 1400px) {
    width: 20px;
    transform: rotateY(-90deg) translateZ(10px) translateX(-10px);
  }
}
.front_cover_0::after,
.front_cover_1::after,
.front_cover_2::after,
.front_cover_3::after {
  display: inline-block;
  content: '';
  position: absolute;
  right: 0;
  width: 40px;
  height: 100%;
  background-size: 100% 100%;
  transform: rotateY(90deg) translateZ(20px) translateX(20px);
  @media (min-width: 1024px) and (max-width: 1400px) {
    width: 20px;
    transform: rotateY(90deg) translateZ(10px) translateX(10px);
  }
}
.book_cover__first_half_0,
.book_cover__second_half_0,
.book_cover__first_half_1,
.book_cover__second_half_1,
.book_cover__first_half_2,
.book_cover__second_half_2,
.book_cover__first_half_3,
.book_cover__second_half_3 {
  position: absolute;
  width: 50%;
  height: 100%;
  background-size: 100% 100%;
  z-index: 1;
}
.book_cover__second_half_0,
.book_cover__second_half_1,
.book_cover__second_half_2,
.book_cover__second_half_3 {
  left: 50%;
}
.book-cover__first_half_0 {
  background-image: url('../../images/kids-book-left.jpg');
}
.book_cover__second_half_0 {
  background-image: url('../../images/kids-book-right.jpg');
}
.book_cover__first_half_1 {
  background-image: url('../../images/cook-book-left.jpg');
}
.book_cover__second_half_1 {
  background-image: url('../../images/cook-book-right.jpg');
}
.book_cover__first_half_2 {
  background-image: url('../../images/e-book-left.jpg');
}
.book_cover__second_half_2 {
  background-image: url('../../images/e-book-right.jpg');
}
.book_cover__first_half_3 {
  background-image: url('../../images/graphic-novel-left.jpg');
}
.book_cover__second_half_3 {
  background-image: url('../../images/graphic-novel-right.jpg');
}
.front_cover_0::before {
  background-image: url('../../images/kids-book-sides.jpg');
}
.front_cover_0::after {
  background-image: url('../../images/kids-book-side-front.jpg');
}
.front_cover_1::before {
  background-image: url('../../images/cook-book-side1.jpg');
}
.front_cover_1::after {
  background-image: url('../../images/cook-book-side2.jpg');
}
.front_cover_2::before {
  background-image: url('../../images/e-book-side1.jpg');
}
.front_cover_2::after {
  background-image: url('../../images/e-book-side2.jpg');
}
.front_cover_3::before {
  background-image: url('../../images/graphic-novel-side1.jpg');
}
.front_cover_3::after {
  background-image: url('../../images/graphic-novel-side2.jpg');
}
.book_rotate__left {
  transform: rotateY(45deg);
}
.book_rotate__right {
  transform: rotateY(-45deg);
}