.book_form__label {
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
  @media (min-width: 1024px) {
    padding-left: 8px;
    padding-right: 8px;
    width: 616px;
  }
  :global(.ant-typography) {
    margin-bottom: 12px;
  }
  :global(.ant-form-item-label) {
    margin-top: 2px;
    margin-bottom: 2px;
    padding: 0;
    width: 100%;
    @media (min-width: 1024px) {
      margin-top: 12px;
    }
  }
  :global(.ant-form-item-label >label) {
    height: 22px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.6;
    @media (min-width: 1024px) {
      font-size: 24px;
      font-weight: 600;
      line-height: 1.0;
    }
  }
  :global(.ant-form-item-label >label.ant-form-item-required:not(.ant-form-item-required-mark-optional))::before {
    display: none;
  }
}
.book_form__sub_label {
  font-size: 12px;
  font-weight: 400;
  line-height: 1.4;
  @media (min-width: 1024px) {
    line-height: 2.0;
  }
}
.voice__radio_group {
  padding-top: 8px;
  padding-bottom: 8px;
}
.book__voice_form {
  margin-top: 32px;
  width: 100%;
  @media (min-width: 1024px) {
    margin-top: 46px;
    padding-left: 16px;
    width: 645px;
  }
  :global(.ant-form-item:first-child) {
    @media (min-width: 1024px) {
      margin-bottom: 32px;
    }
  }
  :global(.ant-form-item-label >label) {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.6;
    @media (min-width: 1024px) {
      font-size: 24px;
      font-weight: 600;
      line-height: 1.0;
    }
  }
}
.voice_form__label_wrap {
  @media (min-width: 1024px) {
    margin-left: auto;
    margin-right: auto;
    padding-left: 8px;
    padding-right: 8px;
    width: 616px;
  }
  :global(.ant-typography) {
    margin: 0;
  }
  .book_form__label_voice {
    margin-bottom: 4px;
    padding-left: 16px;
    padding-right: 16px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.6;
    @media (min-width: 1024px) {
      padding-left: 0;
      padding-right: 0;
      font-size: 24px;
      font-weight: 600;
      line-height: 1.0;
    }
  }
  .book_form__sub_label_voice {
    margin: 0;
    padding-left: 16px;
    padding-right: 16px;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.4;
    @media (min-width: 1024px) {
      margin-top: 8px;
      padding-left: 0;
      padding-right: 0;
      line-height: 2.0;
    }
  }
}
.voice_form__label_voice {
  margin-top: 10px;
  margin-bottom: 52px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
  @media (min-width: 1024px) {
    padding-left: 0px;
    padding-right: 0px;
    width: 616px;
  }
}