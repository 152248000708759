.card {
  padding: var(--padding);
  border-radius: 20px;

  @media screen and (max-width: 1300px){
    padding: 18px;
  }

  @media screen and (max-width: 768px){
    margin-left: -18px;
    width: calc(100% + 36px);
    border-radius: 0;
  }

  :global(.ant-card-body) {
    padding: 0;
  }
}
