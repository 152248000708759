.art-intro {
  left: 0;
  top: 0;
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 20;
}

.art-intro__wrapper{
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  background: #fff;
  @media screen and (max-width: 720px) {
    grid-template-columns: initial;
  }
}

.art-intro__wrapper__col{
  display: flex;
  flex-direction: column;
  width: 100%;

  &.first{
    p {
      display: none;
      @media screen and (max-width: 720px) {
        display: block;
      }
    }
  }

  &.second {
    margin-top: -100px;
    p {
      display: none;
      @media screen and (max-width: 720px) {
        display: block;
      }
    }
  }

  &.third {
    margin-top: -150px;
  }
}

.art-intro__wrapper__col .art-slide{
  width: 100%;
  background-size: cover;
  will-change: transform;

  p {
    will-change: transform;
    padding: 12px;
    font-weight: 600;
    min-height: 100px;
    display: flex;
    align-items: center;
    @media screen and (max-width: 720px){
      min-height: initial;
    }
  }

  img {
    height: 512px;
    width: 100%;
    object-fit: cover;
    display: block;
    @media screen and (min-width: 1920px) {
      height: 55vh;
    }
    @media screen and (max-width: 720px){
      height: 350px;
    }
  }
}

.art-intro__wrapper__modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  height: 380px;
  z-index: 5;
  background: rgba(255,255,255,.9);
  border-radius: 16px;
  box-shadow: 0 0 10px rgb(217, 217, 227);
  padding: 20px;

  @media screen and (max-width: 720px){
    width: 100%;
    top: initial;
    bottom: -1px;
    left: 0;
    transform: none;
    border-radius: 0;
    background: rgba(255,255,255,1);
    height: initial;
    min-height: 260px;
    padding: 0 20px 10px 20px;
  }
}

.art-intro__wrapper__modal {

  h1 {
    text-align: center;
    color: #0288d1;
    font-size: 80px;
    line-height: 120%;
    margin-top: 20px;
    @media screen and (max-width: 720px){
      margin-top: 0;
      font-size: 70px;
      margin-bottom: 0;
    }
  }

  a {
    text-align: center;
    font-size: 10px;
    margin-top: 10px;
    color: rgba(0, 0, 0, 0.88);
    display: block;
  }

  div.ant-typography {
    text-align: center;
    font-weight: 600;
    @media screen and (max-width: 720px){
      margin-bottom: 0;
    }
  }

  button {
    text-align: center;
    background: #0288d1;
    outline: 0;
    color: #fff;
    display: flex;
    justify-content: center;
    padding: 8px 70px;
    border-radius: 8px;
    cursor: pointer;
    margin: 40px auto 0 auto;
    font-size: 18px;
    border: 2px solid #0288d1;
    transition: .3s ease-in-out;
    @media screen and (max-width: 720px){
      margin: 20px auto 0 auto;
      padding: 8px 20px;
      width: 100%;
    }

    &:hover {
      background: #fff;
      color: #0288d1;
    }
  }
}
