@import "../../../../../../styles/mixins/breakpoints.scss";
@import "../../../../../../styles/mixins/variables.scss";

.art-page__single-card {
  height: 410px;
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 10px;
  background: $colorWhite;
  overflow: hidden;
  transition: .3s ease-in-out;
  will-change: transform;
  flex-wrap: nowrap;
  box-shadow: 0 3px 20px rgba(29, 38, 38, 0.05);

  @media screen and (max-width: 768px) {
    height: 140px;
    border-radius: 5px;
    flex-direction: row;
  }

  :global(.ant-image) {

    img {
      object-fit: cover;
    }
  }

  &:hover {
    transform: scale(1.03);
  }

  :global(.ant-col) {
    padding: 0;

    &:first-of-type {
      @media screen and (max-width: 768px) {
        flex: 0 0 150px;
      }
    }

    &:last-of-type {
      @media screen and (max-width: 768px) {
        flex: 0 0 calc(100% - 150px);
      }
    }
  }

  :global(.ant-card-head) {
    margin: 0;

    :global(.ant-card-head-wrapper) {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
      align-items: flex-start;

      :global(.ant-card-extra) {
        margin-inline-start: initial;
      }
    }
  }

  svg {
    width: 21px;
    height: 21px;
  }

  :global(span.anticon-star) {
    position: absolute;
    bottom: 19px;
    left: 18px;
    z-index: 6;

    @media screen and (max-width: 720px) {
      bottom: 15px;
      left: 15px;
    }
  }

  :global(.ant-card-head-title) {
    align-items: flex-start;
    white-space: initial;
    @media screen and (max-width: 1366px) {
      font-size: 14px;
    }

    > span.anticon {
      margin-top: 4px;
    }
  }
}

.art-page__single-card:hover .art-card-favorite-icon {
  display: inline-block;
}

.art-page__single-card__footer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  flex: 1 0 auto;
  padding: 16px!important;

  @media screen and (max-width: 768px) {
    align-items: flex-start;
    justify-content: flex-start;
  }

  h2 {
    color: rgba(31, 31, 31, 0.70);
    text-align: center;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    width: 100%;

    @media screen and (max-width: 768px) {
      text-align: left;
      font-size: 24px;
      line-height: 130%;
    }
  }

  :global(div.ant-typography) {
    color: #7B7B7B;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 130%;
    margin-bottom: 0!important;
    width: 100%;
    text-align: center;

    @media screen and (max-width: 768px) {
      text-align: left;
    }
  }
}

:global(.app-dark-mode) {
  .art-page__single-card {
    background: $darkModeBg;
    border: 1px solid $darkModeText;
  }

  .art-page__single-card__footer {
    border-top: 1px solid $darkModeText;
  }
}
