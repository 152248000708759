.book_type {
  margin-bottom: 48px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: 1024px) {
    margin-bottom: 32px;
    padding-left: 0;
    width: 100%;
    border-radius: 10px;
  }
  :global(.ant-row.ant-form-item-row) {
    width: 100%;
  }
}
.book_grid {
  padding-left: 16px;
  padding-right: 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 32px;
  @media (min-width: 1024px) {
    padding-left: 46px;
    padding-right: 46px;
    gap: 16px;
  }
  @media (min-width: 1728px) {
    padding-left: 76px;
    padding-right: 76px;
    gap: 32px;
  }
  :global(.ant-radio-button-wrapper) {
    padding-inline: 0px;
    border-color: transparent;
    &:not(:first-child)::before {
      width: 0px;
    }
  }
  :global(.ant-radio-button-wrapper.ant-radio-button-wrapper-checked) {
    padding: 8px;
    border-color: transparent;
    border-radius: 5px;
  }
}
