@import '../../../../../../../../../../styles/mixins/variables';
@import '../../../../../../../../../../styles/mixins/breakpoints';

:global(.app-dark-mode) {
    .form__period_info {
        color: $colorPrimary;
    }

    .billing__selector__price {
        background: $colorWhite;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .billing__selector__features__list li {
        color: $colorWhite;
    }
}

.billing__title {
    font-size: 32px !important;
    line-height: 115%;
    color: #18181b;
    text-align: center;
    @include maxw($breakpoint-lg) {
        font-size: 28px !important;
    }
    @include maxw($breakpoint-md) {
        font-size: 26px !important;
    }
    @include maxw($breakpoint-sm) {
        font-size: 24px !important;
    }
}

.billing__subtitle {
    font-weight: 400;
    font-size: 18px !important;
    line-height: 125%;
    color: #18181b;
    text-align: center;
    @include maxw($breakpoint-md) {
        font-size: 16px !important;
    }
    @include maxw($breakpoint-sm) {
        font-size: 12px !important;
    }
}

.form__period__radio {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    margin-bottom: 4px;
    border: 1px solid #e4e4e7;
    background: $colorWhite;
    border-radius: 10px;
    padding: 4px;
    width: 192px;
    height: 46px;

    :global(.ant-radio-button-wrapper) {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0;
        height: 100%;
        flex: 1 0 0;
        color: #a1a1aa;
        text-align: center;
        border-radius: 6px;
        padding: 8px 16px;

        &:before {
            display: none;
        }

        span {
            font-weight: 500;
            font-size: 14px;
            line-height: 115%;
        }
    }
}

.form__period_info {
    text-align: center;
    font-weight: 500;
    font-size: 12px;
    line-height: 125%;
    color: $colorPrimary;

    span {
        font-weight: 700;
    }
}

.billing__selector__config__radio {
    gap: 16px;
    flex-wrap: wrap;
    justify-content: center;
}

.billing__selector__item {
    margin: 0;
    max-width: 288px;
    width: 100%;
    @include maxw($breakpoint-md) {
        max-width: 280px;
    }
    @include maxw($breakpoint-sm) {
        max-width: 340px;
    }

    :global(label.ant-radio-wrapper) {
        margin-inline-end: 0;

        :global(.ant-radio.ant-wave-target) {
            display: none;
        }
    }

    :global(.ant-form-item) {
        margin-bottom: 0;
    }

    :global(span.ant-radio+*) {
        padding: 0;
        width: 100%;
    }

    :global(.ant-radio-wrapper),
    :global(.ant-radio-group) {
        width: 100%;
    }
}

.billing__selector__card {
    border: 2px solid #e4e4e7;
    width: 100%;

    :global(.ant-card-body) {
        padding: 24px 16px 16px;
        text-align: left;
    }

    h3 {
        margin-bottom: 4px;
        line-height: 115%;
        color: #18181b;
        text-transform: capitalize;
    }

    :global(.ant-btn) {
        margin-bottom: 40px;
        height: 42px;
        font-weight: 600;
        font-size: 16px;
        line-height: 115%;
    }
}

.billing__selector__features__title {
    font-weight: 600;
    font-size: 16px;
    line-height: 115%;
}

.billing__selector__features__list {
    list-style: none;

    li {
        margin-bottom: 16px;
        position: relative;
        padding-left: 40px;
        font-size: 14px;
        line-height: 171%;
        color: #18181b;

        &:before {
            content: "";
            background-color: transparent;
            position: absolute;
            left: 5px;
            top: 1px;
            width: 7px;
            height: 14px;
            border-bottom: 2px solid $colorPrimary;
            border-right: 2px solid $colorPrimary;
            transform: rotate(45deg);
        }
    }
}

.billing__selector__price {
    margin-bottom: 40px !important;
    font-size: 12px;
    background: $darkGradient;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    &__value {
        font-weight: 700;
        font-size: 32px;
        line-height: 115%;
    }
}

.billing__selector__most_popular {
    border: 2px solid $colorPrimary;
    position: relative;
    border-radius: 0 0 8px 8px;

    &:nth-child(n+3) {
        @include maxw($breakpoint-md) {
            margin-top: 28px;
        }
    }

    @include maxw($breakpoint-sm) {
        margin-top: 28px;
    }
}

.billing__selector__most_popular__badge {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    top: -26px;
    left: -2px;
    right: -2px;
    border-radius: 8px 8px 0 0;
    background: $colorPrimary;
    height: 28px;
    text-align: center;
    position: absolute;
    font-weight: 600;
    font-size: 14px;
    line-height: 115%;
    color: $colorWhite;
}

.billing__selector__info {
    padding: 0 8px;
}

.billing__config__radio {
    margin-bottom: 78px;
    display: flex;
    justify-content: center;

    @media screen and (max-width: 768px) {
        margin-bottom: 50px;
    }

    :global(.ant-form-item),
    :global(.ant-typography) {
        margin-bottom: 0;
    }
}
