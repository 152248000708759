@import "../../../styles/mixins/variables.scss";

.attached__image {
    width: 80px;
    height: 80px;
    border-radius: 5px;
    background: #fff;
    position: relative;
    margin: 5px 5px 5px 20px;
    border: 1px solid #1F1F1F;

    @media screen and (max-width: 720px) {
        width: 50px;
        height: 50px;
    }

    &__close {
        top: 0;
        right: 0;
        transform: translate(50%, -50%);
        position: absolute;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 1px solid #D9D9D9;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $colorWhite;
        cursor: pointer;

        svg {
            width: 11px;
            height: 11px;
        }
    }
}

.attached__image__wrapper {
    width: 100%;
    height: 100%;
    padding: 3px;

    :global(div.ant-image) {
        height: 100%;
        width: 100%;
        display: block;

        :global(.ant-image-mask-info) {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        img {
            width: 100%;
            display: block;
            height: 100%;
        }
    }
}

.attached {
  background: $colorWhite;
  width: 150px;
  border-radius: 5px;
  margin: 5px 5px 10px 20px;
  padding: 5px;
  position: relative;
  display: flex;
  gap: 4px;
  flex-direction: column;
  border: 1px solid #1F1F1F;

  @media screen and (max-width: 768px) {
    width: 100px;
    align-items: center;
  }

    &__wrapper {
        padding: 4px;
        border: 0;
        margin: 0;
        position: relative;
        height: 40px;
        display: flex;
        gap: 4px;

        @media screen and (max-width: 768px) {
            align-items: center;
            height: 36px;
        }
    }

    img {
        width: 100%;
        height: 31px;
        object-fit: cover;
        border-radius: 6px;
        flex: 0 0 31px;

        @media screen and (max-width: 768px) {
            height: 22px;
            flex: 0 0 22px;
        }
    }

  &__logo {
    flex: 0 0 31px;
    background: $orangeGradient;
    height: 31px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 768px) {
      height: 22px;
      flex: 0 0 22px;
    }

    svg {
      width: 15px;
      height: 17px;

      @media screen and (max-width: 768px) {
        width: 13px;
        height: 15px;
      }
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    gap: 4px;

    p {
      color: #515151;
      font-size: 10px;
      font-style: normal;
      font-weight: 700;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: 74px;
      margin-bottom: 0;

      @media screen and (max-width: 768px) {
        width: 65px;
        line-height: 100%;
      }
    }

    span {
      color: #A4A4A4;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 8px;
      text-transform: uppercase;
    }
  }

  &__close {
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid #D9D9D9;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $colorWhite;
    cursor: pointer;

    svg {
      width: 11px;
      height: 11px;
    }
  }
}

:global(.app-dark-mode) {
  .attached {
    background: $darkModeBg;
    border: 1px solid $darkModeText;

    .custom-item-renderer__text {
      p {
        color: $darkModeText;
      }
    }
  }
}

.attached-files-list {
  display: inline-flex;
  flex-wrap: nowrap;
  max-width: 775px!important;
  margin: auto!important;
  gap: 10px;
  overflow-x: auto;
  padding-bottom: 10px;
  margin-bottom: 10px;
  width: 100%;
}

.attached__image {
  width: 80px;
  height: 80px;
  flex: 0 0 auto;

  @media screen and (max-width: 720px) {
    width: 50px;
    height: 50px;
  }
}

.attached {
  height: auto;
  flex: 0 0 auto;

  &__wrapper {
    height: auto;
    padding: 4px;
    display: flex;
    align-items: center;
    gap: 4px;
  }

  &__logo {
    flex: 0 0 31px;
    width: 31px;
    height: 31px;

    @media screen and (max-width: 768px) {
      width: 22px;
      height: 22px;
    }
  }

  &__text {
    flex: 1;
    min-width: 0; // This allows text to shrink below its content size

    p {
      width: auto;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

// Existing styles remain the same...

@media screen and (max-width: 768px) {
  .attached {
    max-width: none; // Remove the max-width restriction

    &__wrapper {
      width: auto;
    }

    &__text {
      p {
        width: auto;
      }
    }
  }
}