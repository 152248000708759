@import "../../../../../../styles/mixins/variables.scss";


.tabs__title {
  composes: tabs__head__title from '../../index.module.scss';
}

.tabs__explore {
  composes: tabs__head__link from '../../index.module.scss';
}

.tabs__video {
  composes: tabs__head from '../../index.module.scss';
}

.tabs__video__slider {

  :global(.swiper-slide) {
    width: auto;
  }

  :global(.img-to-video__history__single .ant-card-cover) {
    padding-bottom: 64%;

    @media screen and (max-width: 1200px) {
      padding-bottom: initial;
    }
  }

  :global(.ant-card-meta-title) {
    margin-bottom: 0!important;

    h2 {
      color: rgba(31, 31, 31, 0.70)!important;
    }
  }

  :global(.ant-card) {
    width: 355px;
    border: 0;

    @media screen and (max-width: 768px){
      width: 280px;
    }
  }
}

.tabs__video__slider__wrap {
  margin-top: 38px;
  padding: 32px 22px 20px 22px;
  background: #FAFAFA;
  border-radius: 10px;
}

:global(.app-dark-mode) {
  .tabs__video__slider__wrap {
    background: $darkModeBg;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.75);
  }

  .tabs__video__slider {
    .ant-card-meta-description {
      color: $darkModeText!important;
    }

    .ant-card {
      outline-offset: -1px!important;
      outline: 1px solid $darkModeText!important;
    }

    :global(.ant-card-meta-title) {

      h2 {
        color: $darkModeText!important;
      }
    }
  }
}

