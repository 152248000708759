@import "../../styles/mixins/breakpoints.scss";
@import "../../styles/mixins/variables.scss";

.auth {
    display: flex;
    height: 100dvh;
    background: $colorWhite;

    @media screen and (max-width: 1024px) {
        justify-content: center;
    }
}

.auth__left {
    background: $colorWhite;
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    position: relative;
    padding: 15px 43px;

    @media screen and (min-width: 1024px) and (max-height: 850px) {
        padding: 10px 43px;
    }

    @media screen and (max-width: 1024px) {
        padding: 15px 16px;
    }

    @media screen and (max-width: 768px) {
        padding: 40px 16px;
    }

    :global(.ant-form-item) {
        margin-bottom: 0;
        padding: 10px 0 0;

        :global(.ant-form-item-control-input) {
            min-height: initial;

            :global(.ant-checkbox-wrapper) {
                margin-bottom: 30px;
                @include maxw($breakpoint-sm) {
                    margin-bottom: 15px;
                }

                span {
                    font-size: 12px;
                    line-height: 16px;
                }
            }

            :global(.ant-form-item-control-input-content) {
                font-size: 16px;
                font-weight: 500;
                color: $colorLightBlack;
            }
        }

        :global(.ant-form-item-label) {
            font-size: 16px;
            font-weight: 500;
            line-height: 140%;
            letter-spacing: 0.01em;

            label {
                font-size: 16px;
                color: $colorLightBlack;
            }

            :global(label.ant-form-item-required) {
                &:before {
                    display: none;
                }
            }
        }

        :global(.ant-input) {
            margin-bottom: 14px;
            height: 50px;
            background: $colorWhite;
            font-size: 14px;
            font-weight: 400;
            line-height: 19.6px;
            letter-spacing: 0.084px;
            padding: 8px 16px;
            border-radius: 5px;
            border-color: $colorLightBlack;

            &:global(.ant-input-password) {
                font-size: 22px;

                &::-webkit-input-placeholder {
                    color: $colorLightBlack;
                    opacity: 0.5;
                    font-size: 22px;
                }

                &::placeholder {
                    color: $colorLightBlack;
                    opacity: 0.5;
                    font-size: 22px;
                }
            }


            &::placeholder {
                color: $colorLightBlack;
            }
        }
    }

    h1 {
        margin-bottom: 27px;
        padding: 15px 0;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 82%;
        letter-spacing: 0.01em;
        background-clip: text;
        text-align: center;
        background-image: $darkGradient;
        color: transparent;
        position: relative;

        @include maxw($breakpoint-sm) {
            margin-bottom: 0;
            font-size: 36px;
        }

        @include maxw($breakpoint-min) {
            font-size: 30px;
        }
    }

    :global(.ant-form-item-explain) {
        transition: none !important;
        transform: none !important;
    }

    :global(.ant-form-item-explain-error) {
        position: absolute;
        bottom: -3px;
        transform: none !important;
        left: 0;
        height: initial !important;

        @media screen and (max-width: 768px) {
            position: initial;
        }

        &:not(:first-child) {
            display: none;

            @media screen and (max-width: 768px) {
                display: block;
            }
        }
    }

    :global(.ant-btn[type='submit']) {
        height: 50px;
        border-radius: 25px;
        font-weight: 700;
        font-size: 16px;
        line-height: normal;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        background: $blueGradient;
        transition: all ease-in-out 0.3s;
        box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);

        &:hover {
            background: $blueGradient;
            box-shadow: inset 0 0 15px rgba(31, 31, 31, 0.2);
        }

        @include maxw($breakpoint-sm) {
            height: 60px;
        }
    }

    :global(.ant-image) {
        padding: 0;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 30px;
        left: 0;
        height: 65px;

        @media screen and (min-width: 1024px) and (max-height: 850px) {
            height: 40px;
        }

        @media screen and (max-width: 1024px) {
            top: 10px;
        }

        @media screen and (max-width: 768px) {
            position: initial;
            height: 31px;
        }

        img {
            width: 181px;
            height: auto;
        }
    }
}

.auth__left__form {
    padding: 95px 40px 90px;
    width: 100%;
    max-width: 480px;
    @include maxw($breakpoint-sm) {
        padding: 60px 20px;
    }
    @include maxw($breakpoint-min) {
        padding-left: 0;
        padding-right: 0;
    }

    :global(.ant-divider) {
        margin: 0 0 4px;
        color: $colorLightBlack;

        @media screen and (max-width: 436px) {
            margin-left: -16px;
            margin-right: -16px;
            width: calc(100% + 32px);
        }

    }
}

.auth__right {
    flex: 1 0 calc(100% - 481px);
    background: $colorGrayBg;

    @media screen and (max-width: 1024px) {
        display: none;
    }

    :global(.ant-image) {
        display: flex;
        align-items: center;
        padding: 0 234px;
        height: 100dvh;

        @media screen and (max-width: 1600px) {
            padding: 50px;
        }

        img {
            max-height: 823px;
            height: 100%;
            object-fit: contain;
        }
    }
}

.auth__socials {
    margin-bottom: 4px;
    padding: 23px 0 15px;
    display: flex;
    justify-content: space-between;
    @include maxw($breakpoint-sm) {
        padding-top: 15px;
    }

    :global(button.ant-btn.ant-btn-default) {
        width: 100%;
        padding: 21px 31px;
        height: 62px;
        background: #fff;
        border-radius: 10px;
        border: 1px solid $colorLightBlack;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 2px;
        font-size: 16px;
        font-weight: 700;
        line-height: 110%;
        color: $colorLightBlack;
        letter-spacing: 0.01em;
        min-width: 162px;
        @include maxw($breakpoint-sm) {
            height: 58px;
        }

        &:hover {
            background: #F1F4F5;
        }
    }
}

.auth__links {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    padding: 20px 0;
    display: flex;
    justify-content: center;

    @media screen and (min-width: 1024px) and (max-height: 850px) {
        padding: 15px 0;
    }

    @media screen and (max-width: 1024px) {
        padding: 15px 0;
    }

    @media screen and (max-width: 768px) {
        padding: 44px 0 8px 0;
    }

    :global(.ant-form-item-control-input) {
        min-height: initial;
    }

    a {
        font-weight: 600;
        line-height: 22.4px;
        font-size: 16px;
        -webkit-background-clip: text;
        background-clip: text;
        background-image: $blueGradient;
        color: transparent;
    }
}
