@import "../../../../styles/mixins/breakpoints.scss";
@import "../../../../styles/mixins/variables.scss";

.art-page {
  padding: 38px 10px 10px 10px;
  position: relative;
  border-radius: 0;
  height: 100%;
  min-height: 100dvh;
  background: $colorGrayBg;

  @media screen and (max-width: 1024px){
    min-height: calc(100vh - 56px);
  }

  @media screen and (max-width: 768px){
    padding: 10px;
  }

  :global(.ant-card) {
    border-radius: 0;
  }

  :global(.ant-card-head) {
    padding: 0;
    margin: 0 24px;
    @include breakpoint(sm) {
      margin: 0;
    }
  }
  :global(.ant-card-head-wrapper) {
    :global(.ant-card-head-title) {
      padding-top: 0;
    }
  }
  :global(.ant-card-head), :global(.ant-card-body) {
    @include breakpoint(sm) {
      padding-left: 6px;
      padding-right: 6px;
    }

    :global(.ant-card-tool) {
      :global(.ant-card-head) {
        :global(.ant-card-head-title) {
          display: flex;
          align-items: center;
          gap: 5px;
        }
      }
    }
  }
}

.art-page__tabs {
  background: $colorGrayBg;

  :global(.ant-card-body) {
    padding: 41px 20px 20px 20px;

    @media screen and (max-width: 768px){
      padding: 21px 6px 11px 6px;
    }
  }

  :global(.ant-tabs-nav) {
    margin-top: 50px;
    position: relative;

    &:before, &:after{
      content: '';
      background: rgba(0,0,0,.1);
      width: calc(100% + 80px);
      left: -40px;
      height: 1px;
      position: absolute;
      border: 0;
    }

    &:before {
      top: 0;
    }

    &:after {
      bottom: 0;
    }

    @media screen and (max-width: 768px){
      margin-top: 21px;
    }

    :global(.ant-tabs-ink-bar) {
      display: none;
    }

    :global(.ant-tabs-nav-wrap) {

      :global(.ant-tabs-tab+.ant-tabs-tab) {
        margin: 0 0 0 24px;

        @media screen and (max-width: 768px){
          margin: 0 0 0 10px;
        }
      }

      :global(.ant-tabs-tab) {
        padding: 15px 0;

        @media screen and (max-width: 768px){
          padding: 4px 0;
        }

        :global(.ant-tabs-tab-btn) {
          padding: 8px 16px;
          color: $colorDark;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;

          @media screen and (max-width: 768px){
            font-size: 14px;
            padding: 4px 8px;
          }
        }
      }

      :global(.ant-tabs-tab.ant-tabs-tab-active) {

        :global(.ant-tabs-tab-btn) {
          color: $colorWhite;
          position: relative;

          &:before {
            content: '';
            position: absolute;
            top: -50%;
            left: -50%;
            transform: translate(50%,50%);
            z-index: -1;
            width: 100%;
            height: 100%;
            border-radius: 30px;
            background: radial-gradient(192.53% 172.33% at 0% 0%, #0288D1 38.54%, #51AFE0 72.4%, #EAF9FE 99.48%);
          }
        }
      }

      &:before, &:after {
        display: none;
      }
    }
  }
}

.art-page__head {
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 1024px) {
    justify-content: flex-end;
  }

  :global(span.ant-typography) {
    color: #7B7B7B;
    display: block;
    font-weight: 400;
    margin: 20px 0 0 0;
  }

  :global(& > .ant-space-item) {
    width: 100%;
    display: flex;
  }
}

.art-page__search {
  max-width: 436px;
  width: 100%;
  margin-left: auto;
  position: relative;
  border-radius: 10px;
  overflow: hidden;

  @media screen and (max-width: 1200px) {
    max-width: initial;
    border-radius: 5px;
  }

  :global(span.ant-input-affix-wrapper) {
    padding: 0;
    margin: 0;
  }

  :global(.ant-input-group-addon) {
      position: initial;

    button {
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 3;
    }

    button, span, div {
      width: initial;
      height: initial;
      background: transparent;
      outline: none!important;
      border: 0;
      box-shadow: none!important;

      svg {
        fill: $colorDark;
        width: 18px;
        height: 18px;
        margin-top: 1px;
      }
    }
  }

  :global(input.ant-input) {
    background: $colorWhite;
    padding: 6px 17px 6px 36px;

    &::placeholder {
      font-size: 14px;
      color: $colorDark;

      @media screen and (max-width: 720px){
        font-size: 12px;
      }
    }
  }

  :global(.ant-btn) {
    span {
      display: block;
    }
  }
}

button.art-page__head__right__btn {

    &:global(.ant-btn.ant-btn-default) {
        box-shadow: none;
        height: 36px;
        border: 0;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        border-radius: 5px;
        padding: 10px;
        gap: 5px;
        background: $greenGradient;

        &:hover {
            background: $greenGradient;
        }
    }

  span {
    color: #fff;
    display: block;
  }

  svg {
    width: 20px;
    height: 20px;
  }
}

:global(.app-dark-mode) {
  .art-page {
    background: $darkModeBg;
  }

  .art-page__tabs {
    background: $darkModeBg;

    :global(.ant-tabs-nav-wrap) {
      :global(.ant-tabs-tab) {
        :global(.ant-tabs-tab-btn) {
          color: $darkModeText;
        }
      }
    }
  }

  .art-page__search {
    border: 2px solid $darkModeText;

    :global(span.ant-btn-icon) {
      svg {
        fill: $darkModeText;
      }
    }

    input {
      background: $darkModeBg;
      color: $darkModeText;

      &::placeholder {
        color: $darkModeText;
      }
    }
  }
}
