.signup__links {
    margin-top: 25px;
    composes: auth__links from '../../index.module.scss'
}

.signup {
    :global(.ant-checkbox-inner) {
        border-radius: 2px;
    }
}
