@import "../../../../../../styles/mixins/variables.scss";

.template-modals {
    :global(span.ant-typography) {
        font-size: 15px;
        color: #4A4A4A;
        font-weight: 700;
        display: block;
        margin-bottom: 18px;
    }

    :global(.ant-form-item) {
      margin: 0 0 10px;
    }

    :global(input.ant-input) {
        height: 53px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        padding: 0 22px;
        border: 0;
        font-size: 14px;
        line-height: 24px;
        color: #1F1F1F;
        font-weight: 600;

        &::placeholder {
            color: rgba(31,31,31,.4);
        }
    }

    :global(textarea.ant-input) {
        border-radius: 10px;
        display: flex;
        align-items: center;
        padding: 14px;
        border: 0;
        font-size: 14px;
        line-height: 24px;
        color: #1F1F1F;
        font-weight: 600;

        &::placeholder {
            color: rgba(31,31,31,.4);
        }
    }

    :global(.ant-alert) {
        background: #CDCDCD;
        border-radius: 10px;
        border: 0;
        align-items: flex-start;
        margin-bottom: 15px;

        :global(.ant-alert-message) {
            font-size: 12px;
            font-weight: 600;
            line-height: 24px;
            color: rgba(31,31,31,.4);
        }

        :global(.anticon.anticon-exclamation-circle.ant-alert-icon) {
            display: block;
            font-size: 20px;
            color: rgba(31,31,31,.5);
            margin-top: 4px;
        }
    }

    :global(div.ant-upload) {
        background: 0;
        height: 140px;

        :global(.ant-upload .ant-upload-drag-container) {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            :global(.ant-upload-drag-icon) {
                svg {
                    font-size: 25px;
                    color: #A9ABAD;
                }
            }

            :global(.ant-upload-text) {
                font-size: 14px;
                font-weight: 600;
                line-height: 24px;
                color: rgba(31,31,31,.4);
            }

            :global(.ant-upload-hint) {
                font-size: 12px;
                font-weight: 600;
                line-height: 24px;
                color: #949BAB;
            }
        }
    }
}

.template-modals__footer {

  :global(button.ant-btn.ant-btn-default) {
    height: 53px;
    width: 160px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: $colorWhite;
    line-height: 1;
    font-weight: 500;
    background: $colorPrimary;

    &:hover {
      background: $colorPrimary;
    }

    &:first-of-type {
      border: 1px solid rgba(31,31,31,.5);
      color: rgba(31,31,31,.5);
      background: 0;

      &:hover {
        color: rgba(31,31,31,.5);
        background: 0;
      }
    }

    &:last-of-type {
      border: 0;

      &:hover {
        color: $colorWhite;
        background: $colorPrimary;
      }
    }
  }

  :global(.ant-form-item-control-input-content) {
    display: flex;
    justify-content: space-between;
  }
}

:global(.app-dark-mode) {
    .template-modals__footer {
        button {
            &:global(.ant-btn.ant-btn-default:first-of-type) {
                border: 1px solid $colorWhite;
                color: $colorWhite;
            }
        }
    }
}
