@import "../../../../../../../../styles/mixins/breakpoints.scss";

.badge {
  pointer-events: none;
  outline: none;
  border: none;
  color: #fff;

  &:global(.ant-btn) {
    margin-top: 15px;
    padding: 5px 28px;
    font-weight: 500;
    border-radius: 8px;
    font-size: 20px;
    line-height: 150%;
    height: 42px;
    @include maxw($breakpoint-sm) {
      padding: 1px 15px;
      height: auto;
      font-size: 18px;
    }
  }
}

.success {
  background: radial-gradient(192.53% 172.33% at 0% 0%, rgb(0, 138, 101) 38.54%, rgb(29, 193, 152) 72.4%, rgb(29, 193, 152) 99.48%);
}

.warning {
    background: radial-gradient(192.53% 172.33% at 0% 0%, rgb(255, 215, 0) 38.54%, rgb(255, 165, 0) 72.4%, rgb(255, 165, 0) 99.48%);
}
