.select_form {
    width: 343px;
    height: 40px;
    padding: 0 16px;
    border: 1px solid #E0E0E0;
    border-radius: 5px;
    background: #EAF9FE;
    color: #1F1F1F;

    font-size: 16px;
    font-weight: 600;
    line-height: 1.4;
    opacity: 0.5;
    :global(.ant-select-selector) {
        height: 100%;
        background-color: #EAF9FE;
        border: 1px solid transparent;
    }
    @media (min-width: 1024px) {
        width: 430px;
        margin-left: auto;
        margin-right: auto;
        height: 56px;
        font-size: 16px;
        font-weight: 600;
    }
}
:global(.ant-select:not(.ant-select-customize-input) .ant-select-selector) {
    background: #EAF9FE;
    border: none;
    height: 100%;
    display: flex;
    align-items: center;
}
