.button_submit {
    margin-right: auto;
    margin-left: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 343px;
    height: 40px;
    border-radius: 2px;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.5;
    @media (min-width: 1024px) {
      width: 430px;
      margin-top: 32px;
    }
  }