@import "../../../../../../styles/mixins/variables";
@import "../../../../../../styles/mixins/breakpoints";

.audio-page {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    background-color: $colorGrayBg;

    @media screen and (max-width: 950px) {
        height: initial;
    }

    &-result {
    width: 100%;
    background: $colorWhite;
    overflow: hidden;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > div {
      width: 100%;
    }

    &.active {
      background: $colorGrayBg;
    }

    img {
        width: 100%;
        object-fit: contain;
        display: block;
        }
    }
}

.audio-page__main {
    display: flex;

    @media screen and (max-width: 950px) {
        flex-direction: column;
    }
}

.audio-page__accordion {
    background: $colorWhite;
    border: 1px solid rgba(31,31,31,.5);
    margin-bottom: 15px;

    .ant-collapse-item {
        .ant-collapse-content {
            border-top: 1px solid rgba(31,31,31,.5);
        }

        .ant-collapse-header {
            height: 39px;
            padding: 0 14px;
            display: flex;
            align-items: center;

            .ant-collapse-expand-icon {
                > span {
                    font-size: 20px;
                    color: rgba(31,31,31,.5);
                }
            }

            .ant-collapse-header-text {
                font-size: 15px;
                font-weight: 600;
            }
        }
    }
}

.audio-page-settings {
    height: calc(100vh - 90px);
    overflow-y: auto;
    width: 66%;
    padding: 20px 70px 20px 30px;
    border-right: 1px solid #CDCDCD;

    @media screen and (max-width: 1480px){
        width: 50%;
        padding: 30px;
    }

    @media screen and (max-width: 950px) {
        width: 100%;
        height: initial;
        padding: 15px;
    }
}

.audio-page__side {
    width: 34%;
}

.audio-page__heading {
    padding: 20px 30px;
    border-bottom: 1px solid #CDCDCD;

    @media screen and (max-width: 768px) {
        padding: 15px;
    }

    span {
        font-size: 14px;
        line-height: 120%;
        display: block;
        color: $colorDark;
        opacity: .5;
        font-weight: 300;
    }

    h2 {
        font-size: 24px;
        line-height: 120%;
        font-weight: 400;
        margin-bottom: 4px;
    }
}

.textarea-section {
    h3 {
        font-size: 15px;
        font-weight: 600;
        margin-bottom: 15px;
    }
}

.section {
  .ant-form-item {
    margin-bottom: 0px;
  }

  &-container {
    position: relative;

    .container-count {
        position: absolute;
        color: rgba(31,31,31,.5);
        bottom: 16px;
        right: 16px;
    }

    .ant-input {
        padding: 16px;
        border: 1px solid rgba(31,31,31,.5);
        color: rgba(31,31,31,.4);
        font-weight: 600;

        &::placeholder {
            color: rgba(31,31,31,.4);
        }
    }
  }
}

.container {
  position: relative;
  margin-bottom: 16px;
  border-radius: 5px;

  &-text {
    color: rgba(31,31,31,.5);
    padding: 8px 16px;
    font-size: 16px;
    line-height: 120%;
    font-weight: 600;
    border-radius: 5px;
    background: $colorWhite;
    resize: none;
    outline: none;
    box-sizing: border-box;

    &:active, &:focus {
      box-shadow: none!important;
    }
  }

  &-count {
    position: absolute;
    bottom: 8px;
    right: 16px;
    font-size: 12px;
    font-weight: 400;
    opacity: .5;
  }
}

.description-second {
  margin-bottom: 8px;
}

.gender {
  margin: 0;

  &-radio-group {
        display: flex;
        gap: 16px;

        @media screen and (max-width: 500px) {
            justify-content: flex-start;
        }
  }

  &-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    height: 30px;
    border: none !important;
    border-radius: 5px;
    font-size: 12px !important;
    font-weight: 400;
    background-color: $colorGrayBg;

    &:active {
      color: $colorWhite;
      background: radial-gradient(192.53% 172.33% at 0% 0%, #6A74D1 38.54%, #9B60A5 72.4%, #9B60A5 99.48%);
    }

    &::before {
      display: none !important;
    }
  }
}

.gender-section {

    h2 {
        font-size: 15px;
        line-height: 120%;
        margin-bottom: 10px;
        font-weight: 600;
    }

    .ant-form-item-control-input-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        @media screen and (max-width: 500px) {
            flex-direction: column;
            gap: 10px;
        }
    }

    @media screen and (min-width: 1024px) {
        border-radius: 10px;
    }
}

.gender-btn.ant-radio-button-wrapper {
    background: #CDCDCD;
    color: rgba(31,31,31,.5);
    font-weight: 500;
    font-size: 16px;
    line-height: 1;
}

.gender-btn.ant-radio-button-wrapper-checked {
  color: $colorWhite;
  background: radial-gradient(192.53% 172.33% at 0% 0%, #6A74D1 38.54%, #9B60A5 72.4%, #9B60A5 99.48%) !important;
}

.add-voice-btn {
  position: relative;
  padding-left: 16px;
  padding-right: 16px;
  height: 30px;
  border-radius: 5px;
  background: $greenGradient;
  color: #FFF;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;

  &:disabled {
    background: #D3D3D3;
    color: #fff;

    .clone_voice__wrap__popover {
      z-index: 11;
      position: absolute;
      top: 30px;
      right: 0;
      opacity: 0;
      pointer-events: none;
      transition: .2s ease-in-out;
      width: 240px;
      cursor: auto;
    
      @media screen and (min-width: 1024px){
        top: 40px;
      }
    
      & > div:nth-child(1) {
        height: 10px;
        @media screen and (min-width: 1024px){
          top: 20px;
        }
      }
      & > div:nth-child(2) {
        height: 108px;
        padding: 9px 9px 12px 12px;
        border-radius: 10px;
        background: #fff;
        border: 1px solid rgba(0,0,0,.4);
      }
      & > div {
        h3 {
          margin: 0;
          padding: 0;
          text-align: left;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 22.4px;
          background: $purpleGradient;
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          margin-bottom: 0;
        }
    
        p {
          margin: 0;
          padding: 0;
          text-align: left;
          color: #000;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16.8px;
        }
    
        span {
          margin: 0;
          padding: 0;
          color: #949BAB;
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          line-height: 16.8px;
          opacity: 0.5;
    
          a {
            margin: 0;
            padding: 0;
            text-decoration: underline;
            color: #949BAB;
          }
        }
      }
    }
    &:hover {
      .clone_voice__wrap__popover {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
  &:active:hover:not([disabled]) {
    color:#0577b5 !important;
  }
}

.voice {

  &-radio-group {
    display: flex;
    justify-content: center;
    min-width: 320px;
    width: 100%;
  }

  &-slider {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    overflow: hidden;
    border-radius: 14px;

    .slick-list {
      margin: 0 -4px;

      @media screen and (min-width: 768px) {
        margin: 0 -8px;
      }
    }

    .slick-track {
        display: flex;
        margin-left: 0;
    }

    .slick-slide {
      padding: 0 4px;

      @media screen and (min-width: 768px) {
        padding: 0 8px;
      }
    }

    .slick-dots {
      position: initial;
    }

    .slick-dots li button:before {
      color: $colorPrimary !important;
    }

    .ant-radio-button-wrapper:first-child {
      border-inline-start: none;
    }
  }

  &-card {
    margin: 2px;
    padding: 0 0 0 1px;
    min-width: 180px;
    height: 66px;
    width: auto !important;
    color: $colorDark;
    background: rgba(31,31,31,.5);
    overflow: hidden;
    border-inline-start: none;
    border-radius: 12px !important;
    border: 1px solid transparent;

      @media screen and (max-width: 768px) {
          height: 42px;
          min-width: 160px;
      }

    &:hover {
      color: transparent;
    }
    &.custom {
      height: 76px;
      @media screen and (min-width: 1024px) {
        height: 96px;
      }
    }
  }

  &-section {
    padding: 16px 0;

    .ant-form-item-explain {
      margin-left: 16px;
    }
  }

    h3 {
        font-size: 15px;
        font-weight: 600;
    }
}

.voice-card.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: $colorDark !important;
    background: radial-gradient(192.53% 172.33% at 0% 0%, #FF916D 38.54%, #ED5E93 72.4%, #ED5E93 99.48%) !important;
    border: 3px solid transparent;
    padding: 0;
}

.content {
  width: 100%;
  box-sizing: border-box;
  height: 100%;

  &:hover, &:active {
    color: $colorDark !important;
  }

  &-info {
    position: relative;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    height: 100%;
    font-weight: 500;
    background: $colorWhite;

    &-inner {
        padding: 10px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        @media screen and (max-width: 768px) {
            padding: 0 10px;
            align-items: center;
        }
    }

    p {
        color: rgba(31, 31, 31, 0.70);
        font-size: 16px;
        line-height: 1;
        font-weight: 600;
        margin-top: 4px;

        @media screen and (max-width: 768px) {
            margin-top: 2px;
        }
    }

    &-icon {
      font-size: 24px;
      color: $colorDark;
      cursor: pointer;

        svg {
            width: 24px;
            height: 24px;
        }
    }
  }
}

.clone-wrap {
  width: 170px;
  height: 76px;
  display: flex;
  flex-direction: column;
  gap: 1px;
  justify-content: flex-start;
  align-items: flex-start;

  .content-info-inner {
    padding: 0;
  }
  p {
    max-width: 130px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @media screen and (min-width: 1024px) {
      max-width: 170px;
    }
  }
  p:nth-child(1) {
    font-size: 15px;
    font-weight: 600;
    line-height: 1.5;
    background: $darkGradient;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  p:nth-child(2) {
    color: #D3D3D3;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.4;
  }
  .anticon-close {
    position: absolute;
    top: 7px;
    right: 9px;
    svg {
      width: 11px;
      height: 11px;
      color: #D3D3D3;
      :hover {
        color:#000
      }
    }
  }
}

.button-section {
  padding: 0;
}

.audio-page-results {
    height: 36px;
    border-radius: 0;
    border: 1px solid rgba(31,31,31,.5);
    width: 100%;
    background: rgba(237, 242, 245, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.button-submit {
    height: 54px;
    font-size: 16px;
    font-weight: 700;
    font-style: normal;
    border-radius: 2px;
    width: 100%;

    @media screen and (max-width: 768px) {
        height: 40px;
    }
}

.rhap {
  &_container {
    margin: 16px;
    width: auto;
    border: none;
    border-radius: 10px;
    box-shadow: none;
    background-color: $colorWhite;

    @media screen and (min-width: 1280px) {
      margin: 8px;
      border-radius: 0;
      box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
    }

    @media screen and (min-width: 1440px) {
      padding: 5px 10px;
    }
  }

  &_header {
    text-align: center;
    font-size: 14px;
    font-weight: 600;

    @media screen and (min-width: 1440px) {
      text-align: start;
      font-size: 12px;
    }
  }

  &_horizontal-reverse {
    margin-right: 0;
    flex-direction: column-reverse;
    gap: 20px;

    @media screen and (min-width: 1440px) {
      flex-direction: row-reverse;
    }
  }

  &_progress {
    &-section {
      margin-right: 75px;
    }

    &-filled {
      background-color: rgb(58, 166, 224) !important;
    }

    &-bar {
      @media screen and (min-width: 1440px) {
        height: 3px;
      }
    }

    &-indicator {
      @media screen and (min-width: 1440px) {
        top: -5px;
        height: 13px;
        width: 12px
      }
    }
  }

  &_additional-controls {
    position: absolute;
    right: 40px;
    margin-right: 0;
    bottom: 10px;

    .anticon {
      @media screen and (min-width: 1440px) {
        vertical-align: 10px !important;
      }
    }

    svg {
      width: 24px;
      height: 24px;

      @media screen and (min-width: 1440px) {
        width: 18px;
        height: 18px;
      }
    }
  }

  &_time {
    @media screen and (min-width: 1440px) {
      font-size: 12px;
    }
  }

  &_play-pause-button {
    @media screen and (min-width: 1440px) {
      display: flex;
      align-items: center;
      font-size: 30px;
    }
  }

  &_volume-button {
    @media screen and (min-width: 1440px) {
      display: flex;
      align-items: center;
      font-size: 20px;
    }
  }

  &_volume-indicator {
    top: -4px;

    @media screen and (min-width: 1440px) {
      top: -3px;
      width: 10px;
      height: 10px;
    }
  }

  &_volume-bar {
    background-color: rgb(199, 229, 245);
  }

  &_main-controls-button, &_volume-button {
    color: $colorPrimary;
  }

  &_volume-indicator, &_progress-indicator {
    background-color: $colorPrimary;
  }

  &_progress-bar, &_download-progress {
    background-color: rgb(200, 230, 246);
  }
}
