@import "../../../../styles/mixins/breakpoints.scss";
@import "../../../../styles/mixins/variables.scss";

.art-history {
    min-height: 100vh;
    background: $colorGrayBg;
    padding: 4px 18px;
    margin-top: 0px;
    @media (min-width: 1024px) {
      padding: 32px 70px 60px 70px;
    }
    .ant-typography {
        margin-bottom: 8px;
        font-size: 36px;
    @media (min-width: 1024px) {
        margin-bottom: 16px;
        font-size: 40px;
        font-weight: 600;
      }
    }
    .ant-tabs-nav {
        margin-bottom: 16px;
    @media (min-width: 1024px) {
        margin-bottom: 13px;
      }
    }
    .ant-tabs .ant-tabs-tab {
        font-size: 16px;
        font-weight: 600;
    }
}
.ant-tabs-top >.ant-tabs-nav::before {
    border-bottom: none;
}
.art-history__spin .ant-spin-nested-loading .ant-spin-container {
    min-height: 100vh;
    text-align: center;
} 
.art-history__images {
    width: 100%;
    display: grid;
    gap: 3px;
    grid-template-areas: 'one  two'
                        'three four'
                        'five six'
                        'seven eight'
                        'nine ten'
                        'eleven twelve'
                        'thirteen fourteen';
    @media (min-width: 1024px) {
        gap: 4px;
        grid-template-areas: 'one  two three four four'
                        'five six seven four four'
                        'eight eight nine ten eleven'
                        'eight eight twelve thirteen fourteen';
    }
}
.art-history__favorite {
    display: grid;
    gap: 3px;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 3px;
    grid-row-gap: 3px;
    @media (min-width: 1024px) {
        grid-template-columns: repeat(4, 1fr);
        grid-column-gap: 4px;
        grid-row-gap: 4px;
    }
}
.art-history__img-wrap {
    position: relative;
    &:nth-child(1) {
        grid-area: one;
    }
    &:nth-child(2) {
        grid-area: two;
    }
    &:nth-child(3) {
        grid-area: three;
    }
    &:nth-child(4) {
        grid-area: four;
    }
    &:nth-child(5) {
        grid-area: five;
    }
    &:nth-child(6) {
        grid-area: six;
    }
    &:nth-child(7) {
        grid-area: seven;
    }
    &:nth-child(8) {
        grid-area: eight;
    }
    &:nth-child(9) {
        grid-area: nine;
    }
    &:nth-child(10) {
        grid-area: ten;
    }
    &:nth-child(11) {
        grid-area: eleven;
    }
    &:nth-child(12) {
        grid-area: twelve;
    }
    &:nth-child(13) {
        grid-area: thirteen;
    }
    &:nth-child(14) {
        grid-area: fourteen;
    }
}
.art-history__img-wrap img,
.art-history__img-fav {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.art-history__fav-wrap {
    position: relative;
    width: 100%;
    height: 100%;
}
.img-fav-wrap {
    position: relative;
}
.art-history__icon {
    position: absolute;
    left: 10px;
    bottom: 10px;
    transition: .3s ease-in-out;
    @include breakpoint(sm) {
        display: block;
      }
      &:hover {
        transform: scale(1.1);
        svg {
            filter: drop-shadow(2px 2px 1px #000);
        }
      }
}
.art-history__icon svg {
    width: 18px;
    height: 18px;
}
.art-history__dropdown {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 7;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color:rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    gap: 7px;
}
  .img-dropdown-btn {
    border: none;
    opacity: 0.8;
  }
  .art-history__dropdown-icon {
    transition: .3s ease-in-out;
    & svg {
      fill: #fff;
    }
    &:hover {
      cursor: pointer;
      transform: scale(1.03);
    }
  }


