@import "../../../../../../../../../../styles/mixins/variables";
@import "../../../../../../../../../../styles/mixins/breakpoints";

.app-dark-mode {
    .billing__payment__history {
        * {
            color: $colorWhite !important;
        }

        .ant-table {

            table {

                * {
                    border-radius: 0 !important;
                    background: transparent !important;
                }
            }

            .ant-table-cell {
                border-bottom: 1px solid $lineColorGray;
            }
        }
    }

    .billing__payment__history__title {
        background: $colorBlue;
    }
}

.billing__payment__history {
    margin: 46px auto 32px;

    @include maxw($breakpoint-md) {
        margin: 29px 0 0 -16px;
    }

    & > div.ant-row {
        @media screen and (max-width: 768px) {
            flex-direction: column;
        }
    }

    .ant-col {
        width: 100%;
        border: 0 !important;
        outline: none !important;
        box-shadow: none !important;
        background: transparent;
        gap: 8px;
        padding-left: 0;
        padding-right: 0;

        &:hover {
            span {
                &:last-of-type {
                    color: $colorPrimary;
                }
            }
        }


        span {
            color: $colorDark;

            &:last-of-type {
                margin-left: auto;
            }
        }
    }

    .ant-table {
        border-radius: 0;
        background: initial;
        @include maxw($breakpoint-md) {
            padding-top: 0;
        }

        .ant-table-content,
        .ant-table-tbody {
            transform: translateY(-4px);
        }

        table {
            border-radius: 0;
            border-spacing: 0 4px;
            @include maxw($breakpoint-md) {
                border-spacing: 0;
            }

            tr {
                background: #fafafa;

                th {
                    border-top: none;
                    width: 33%;
                    background: initial;
                    border-bottom: 0;
                    font-weight: 600;
                    font-size: 24px;
                    text-align: center;
                    padding: 14px 16px 4px;
                    color: $colorLightBlack;

                    @include maxw($breakpoint-lg) {
                        font-size: 20px;
                    }

                    @include maxw($breakpoint-md) {
                        padding: 12px 16px 4px;
                        font-size: 18px;
                        font-weight: 400;
                    }

                    @include maxw($breakpoint-sm) {
                        font-size: 12px;
                    }

                    &:first-of-type {
                        text-align: left;
                    }

                    &:before {
                        display: none;
                    }
                }

                td {
                    width: 33%;
                    border-bottom: 0;
                    padding: 20px 16px 28px;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 93%;
                    text-align: center;
                    color: #464646;
                    @include maxw($breakpoint-md) {
                        font-size: 16px;
                        padding: 16px;
                    }

                    @include maxw($breakpoint-md) {
                        font-size: 12px;
                    }

                    &:first-child {
                        border-radius: 5px 0 0 5px;
                        @include maxw($breakpoint-md) {
                            border-radius: 0;
                        }
                    }

                    &:last-child {
                        border-radius: 0 5px 5px 0;
                        @include maxw($breakpoint-md) {
                            border-radius: 0;
                        }
                    }

                    &:first-of-type {
                        text-align: left;
                    }
                }

                td.ant-table-cell-row-hover {
                    background: transparent !important;
                }
            }

            .ant-table-thead {
                tr {
                    th:first-child {
                        border-radius: 0 0 0 5px;
                        @include maxw($breakpoint-md) {
                            border-radius: 0 !important;
                            border-start-start-radius: 0 !important;
                        }
                    }

                    th:last-child {
                        border-radius: 0 0 5px 0;
                        @include maxw($breakpoint-md) {
                            border-radius: 0 !important;
                            border-start-end-radius: 0 !important;
                        }
                    }
                }
            }
        }
    }

    .ant-pagination {
        justify-content: center;
        gap: 16px;

        .ant-pagination-prev,
        .ant-pagination-next,
        .ant-pagination-item {
            margin: 0;
        }

        .ant-pagination-item {
            border-radius: 50%;
            border: none;

            a {
                line-height: 32px;
                color: #9EA3AE;
            }

            &.ant-pagination-item-active {
                background: $colorPrimary;

                a {
                    color: $colorWhite;
                }
            }
        }
    }
}

.billing__payment__history__title {
    padding: 12px 37px;
    border-radius: 10px 10px 0 0;
    background: #141e30;

    @include maxw($breakpoint-md) {
        padding: 12px 16px;
        border-radius: 0;
    }

    h4 {
        margin: 0;
        font-weight: 600;
        font-size: 24px;
        color: $colorWhite;
        @include maxw($breakpoint-md) {
            font-size: 20px;
        }
        @include maxw($breakpoint-sm) {
            font-size: 16px;
        }
    }
}
