@import '../../../../../../../../styles/mixins/variables.scss';

.billing__selector {
    height: 100%;
    padding: 23px 40px 30px;

    @media (max-width: 720px) {
        padding: 12px 16px;
    }
}

.billing__selector__container {
    margin: 0 auto;
    max-width: 1200px;
}
