@import "../../../../../../styles//mixins//variables.scss";

.voice__radio_group {
    display: flex;
    min-width: 320px;
    .voice__slider {
      display: flex;
      align-items: center;
      width: 100%;
      padding-left: 0px;
    }
    :global(.slick-track) {
      width: 100%;
      display: flex;
      :global(.slick-slide.slick-active) {
        width: 100%;
        margin-left: 0;
        margin-right: auto;
      }
    }

    :global(.ant-radio-button-wrapper.ant-radio-button-wrapper-checked) {
      margin-top: 2px !important;
      margin-bottom: 2px;
      color: $colorDark !important;
      background: radial-gradient(192.53% 172.33% at 0% 0%, #FF916D 38.54%, #ED5E93 72.4%, #ED5E93 99.48%) !important;
      border: 2px solid transparent;
      
      @media screen and (min-width: 375px) {
        margin: 2px;
        min-width: 150px;
        min-height: 176px;
      }

      @media screen and (min-width: 1024px) {
        margin: 0 8px auto 5px !important;
        min-width: 186px;
        width: 100%;
        min-height: 238px;
        border-width: 3px !important;
      }
    }
}
.voice_card {
    position: relative;
    margin-top: 4px;
    margin-bottom: 4px;
    padding: 0;
    min-width: 150px;
    width: auto !important;
    min-height: 176px;
    color: $colorDark;
    background: $colorLightBlue !important;
    overflow: hidden;
    border-inline-start: none;
    border: none;
    border-radius: 5px !important;

    @media screen and (min-width: 1024px) {
      margin-top: 3px;
      margin-left: 8px;
      margin-right: 8px;
      min-width: 180px;
      min-height: 232px;
      border-radius: 10px !important;
    }
    &:hover {
      color: transparent;
    }
  }
.voice_card__content {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;

  &:hover, &:active {
    color: $colorDark !important;
  }
}
.voice_card__photo {
    width: 150px;
    height: 136px;

    @media screen and (min-width: 1024px) {
      width: 180px;
      height: 176px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
}
.voice_card__info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 8px;
    height: 40px;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    background: $colorLightBlue !important;

    @media screen and (min-width: 1024px) {
      padding: 16px;
      height: 56px;
      font-size: 16px;
      font-weight: 600;
    }
    :global(p) {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.6;
    }
    .anticon svg {
      @media screen and (min-width: 1024px) {
        width: 24px;
        height: 24px;
      }
    }
}
.voice_card__icon {
    font-size: 18px;
      color: $colorDark;
      cursor: pointer;

      @media screen and (min-width: 1024px) {
        font-size: 24px;
      }
}
