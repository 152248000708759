@import "../../../../../../styles/mixins/variables.scss";

.single-art {
    background: $colorGrayBg;
    padding: 30px 10px 30px 30px;
    height: 100%;
    @media screen and (max-width: 992px) {
        padding: 30px;
    }
    @media screen and (max-width: 720px) {
        padding: 16px;
    }

    :global(.ant-col) {
        height: 100%;
    }
}

.single-art__left {
    padding-right: 30px;
    @media screen and (max-width: 992px) {
        padding-right: 0;
    }

    :global(.single-art__prompts) {

        &:global(.show) {
           :global(.ant-col) {
                display: flex!important;
            }
        }

        :global(.ant-col) {
            align-self: stretch;
            display: flex;
            height: auto;
            min-height: 250px;

            @media screen and (max-width: 720px) {
                display: none;
            }

            &:first-of-type, &:nth-of-type(2) {
                @media screen and (max-width: 720px) {
                    display: flex;
                }
            }
        }
    }
}

.single-art__categories {
    display: flex;
    gap: 10px;
    margin-bottom: 30px;


    @media screen and (max-width: 720px) {
        margin-bottom: 10px;
    }

    & > div {
        padding: 4px 11px 4px 8px;
        border-radius: 20px;
        color: $colorWhite;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        position: relative;
        display: flex;
        align-items: center;
        gap: 8px;
    }

    :global(.category) {
        padding: 4px 18px 4px 25px;
        background: radial-gradient(circle at top left, #5429FF 1%, #28C4FF 80%);;
        border-radius: 20px;
        color: $colorWhite;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        position: relative;
        display: flex;
        align-items: center;
        gap: 8px;

        &:before {
            content: '';
            position: absolute;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: #fff;
            left: 8px;
            top: 50%;
            transform: translateY(-50%);
        }

        @media screen and (max-width: 720px) {
            padding: 4px 8px 4px 25px;
        }
    }
}

.single-art__title {
    background: #FFFFFF;
    padding: 18px 20px 18px 30px;
    display: flex;
    align-items: center;
    gap: 18px;
    margin-bottom: 30px;
    margin-left: -30px;
    margin-right: -30px;

    h2 {
        margin: 0;
        @media screen and (max-width: 720px) {
            font-size: 24px;
            line-height: 120%;
        }
    }

    @media screen and (max-width: 720px) {
        margin-left: -16px;
        width: calc(100% + 32px);
        margin-bottom: 24px;
        padding: 8px 12px 8px 17px;
        font-size: 24px;
        line-height: 120%;
        gap: 5px;
        align-items: flex-start;
    }

    button {
        border-radius: 3px;
        outline: none;
        background: none;
        border: 1px solid rgba(31,31,31,.5);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 4px;
    }

    svg {
        width: 25px;
        height: 25px;
        color: rgba(31,31,31,.5);

        @media screen and (max-width: 720px) {
            width: 17.5px;
            height: 17.5px;
        }
    }
}

.single-art__back {
    &:global(.ant-btn-default.ant-btn) {
        color: $colorDark;
        margin-bottom: 32px;
        display: flex;
        align-items: center;
        gap: 9px;
        border: 0;
        box-shadow: none;
        background: 0;
        padding-left: 0;

        @media screen and (max-width: 720px) {
            margin-bottom: 10px;
        }

        span.ant-btn-icon {
            margin-inline-end: 0 !important;

            svg {
                width: 16px;
                height: 16px;
            }
        }

        &:hover {
            background: 0;
            color: $colorPrimary;
        }
    }
}

:global(.single-art__view) {
    justify-content: space-between;
    display: flex;
    margin-bottom: 10px;

    h4 {
        margin-bottom: 0;
    }

    a {
        color: $colorPrimary;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
    }
}

.single-art__right {
    border-left: 2px solid rgba(31, 31, 31, .1);
    margin-bottom: 29px;
    padding: 61px 94px 0 30px;

    @media screen and (max-width: 1400px) {
        padding: 20px;
    }
    @media screen and (max-width: 992px) {
        padding: 20px 0;
        border-left: 0;
    }
}

:global(.single-art__tips) {
    :global(.ant-col) {
        width: 100%;
        max-width: 426px;

        &:first-of-type {
            margin-top: 13px;
        }

        @media screen and (max-width: 992px) {
            max-width: 100%;
        }

        @media screen and (max-width: 720px) {
            display: none;
        }

        &:first-of-type, &:nth-of-type(2) {
            @media screen and (max-width: 720px) {
                display: block;
            }
        }
    }

    &:global(.show) {
        :global(.ant-col) {
            display: block!important;
        }
    }
}

:global(.app-dark-mode) {
    .single-art {
        background: $darkModeBg;
    }

    .single-art__back {
        &:global(.ant-btn-default.ant-btn) {
            color: $darkModeText;
        }
    }

    .single-art__title {
        background: rgba(255, 255, 255, 0.2);
        button {
            &:global(.ant-btn.ant-btn-default) {
                border: 1px solid $darkModeText;

                &:hover {
                    background: 0;
                }

                span {
                    svg {
                        color: $darkModeText;
                    }
                }
            }
        }
    }

    .single-art__right {
        border-left: 2px solid $darkModeText;
    }
}
