@import '../../styles/mixins/variables.scss';
@import '../../styles/mixins/breakpoints';

.wrapper {
    position: relative;
    width: 100%;
    min-height: 100vh;
    background-color: $colorGrayBg;
    overflow: auto;
}

.container {
    height: 100vh;
    background-color: #464646;
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.11);
    overflow: auto;

    @include minw($breakpoint-sm) {
        background-color: $colorDark;
    }

    &::before {
        content: '';
        position: absolute;
        top: -90px;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: url('../../assets/images/introPage/intro_page_bg_mob.png');
        background-size: cover;
        z-index: 0;

        @media screen and (min-width: 720px) {
            background-image: url('../../assets/images/introPage/intro_page_bg_desk.png');
        }
    }

    &__header {
        padding: 90px 20px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;

        @media screen and (min-width: 768px) {
            padding: 180px 0 0;
        }

        &--title {
            margin-bottom: 7px !important;
            text-align: center;
            font-size: 32px !important;
            background: radial-gradient(192.53% 172.33% at 0% 0%, #6A74D1 38.54%, #9B60A5 72.4%, #9B60A5 99.48%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

            @include minw($breakpoint-sm) {
                font-size: 32px !important;
            }

            @include minw($breakpoint-md) {
                margin: 0 auto 16px !important;
                width: 624px;
                font-size: 64px !important;
            }
        }

        &--description {
            text-align: center;
            color: $colorWhite;
            font-size: 14px;
            font-weight: 400;
            opacity: 0.5;

            @include maxw($breakpoint-sm) {
                font-size: 12px;
            }

            @include minw($breakpoint-md) {
                margin: 0 auto;
                width: 596px;
            }
        }
    }

    &__content {
        margin-top: 30px;
        padding: 0 15px 125px;
        @include minw($breakpoint-sm) {
            padding-bottom: 0;
        }
        @include minw($breakpoint-xl) {
            margin: 42px auto 0;
            max-width: 800px;
        }

        .form_item {
            margin-bottom: 0;
        }

        .cards_group {
            display: grid;
            grid-template-columns: 1fr;
            gap: 20px;
            width: 100%;

            @include minw($breakpoint-md) {
                grid-template-columns: repeat(2, 1fr);
            }

            &__radio_button {
                height: auto;
                padding: 22px 25px;
                background-color: $colorWhite;
                border-radius: 10px !important;
                border: none;

                &::before {
                    display: none !important;
                }

                & > span {
                    border-radius: 10px;
                    background: $purpleGradient;
                }
            }
        }

        &--button {
            padding: 20px 16px;
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: #464646;
            overflow: hidden;
            @include minw($breakpoint-sm) {
                position: static;
                margin-top: 125px;
                padding: 40px 20px;
                background-color: transparent;
            }

            &::before {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background-image: url('../../assets/images/introPage/intro_page_bg_mob.png');
                background-size: cover;
                background-position: 0 86.3%;
                z-index: 0;

                @include minw($breakpoint-sm) {
                    display: none;
                }
            }

            :global(.ant-form-item) {
                margin-bottom: 0;
            }
        }
    }
}

.button {
    &__submit {
        margin: 0 auto;
        display: flex;
        align-items: center;
        padding: 15px;
        max-width: 430px;
        width: 100% !important;
        height: auto !important;
        text-align: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 700;
        border-radius: 10px;
        background: $blueGradient;
    }
}
