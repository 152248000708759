@import "../../../../../../../../styles/mixins/variables.scss";
@import "../../../../../../../../styles/mixins/breakpoints.scss";

.profile__avatar {
  background: $colorWhite;

  &__uploader {

    &:global(.ant-upload-wrapper) {
      width: auto;
    }
    :global(.ant-upload-list-item-container) {
      width: 113px!important;
      height: 113px!important;
      @include maxw($breakpoint-sm) {
        width: 90px!important;
        height: 90px!important;
      }
    }
    :global(.ant-upload-list-item) {
      padding: 6px!important;
      background: $colorWhite;
      border: 0!important;
      box-shadow: 4px 4px 7px rgba(56, 77, 108, 0.25);
      @include maxw($breakpoint-sm) {
        padding: 4px;
      }

      &:before {
        width: calc(100% - 14px) !important;
        height: calc(100% - 14px) !important;
      }
    }

    :global(.ant-upload-list-item-image) {
      object-fit: cover!important;
    }

    :global(.ant-upload-list-item-actions){
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}