@import "../../../../../../../../styles/mixins/variables";

.audio-page__accordion {
    .ant-collapse-content {
        .ant-collapse-content-box {
            padding: 24px;
        }
    }
}

.audio-page__accordion__item {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    margin-bottom: 40px;

    .ant-form-item-control-input {
        min-height: initial;
    }

    .ant-form-item {
        width: 100%;
    }

    &.boost {
        display: flex;
        justify-content: space-between;

        button {
            border: 2px solid rgba(31,31,31,.5);
            color: rgba(31,31,31,.5);
            width: 76px;
            height: 32px;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;

            span {
                font-size: 12px;
                line-height: 1;
            }
        }

        .ant-checkbox-wrapper {
            font-size: 12px;
            display: flex;
            align-items: center;
            line-height: 1;

            .ant-checkbox-inner {
                width: 20px;
                height: 20px;
                border: 1px solid rgba(31,31,31,.5);
            }

            & > span {
                display: flex;
                align-items: center;
                gap: 5px;
            }
        }
    }

    &:last-of-type {
        margin-bottom: 10px;
    }

    .ant-slider {
        width: 100%;
        margin: 0;

        .ant-slider-dot {
            display: none;
        }

        .ant-slider-mark {

            .ant-slider-mark-text {
                font-size: 11px;
                line-height: 24px;
                color: rgba(31,31,31,.5);
                margin-top: 5px;
                font-weight: 400;

                & > div {
                    display: flex;
                    align-items: center;
                    gap: 4px;
                }

                &:first-of-type {
                    transform: translateX(0%) !important;
                }

                &:last-of-type {
                    left: 100% !important;
                    transform: translateX(-100%) !important;
                }
            }
        }
    }

    & > span.ant-typography {
        display: block;
        width: 150px;
        font-size: 12px;
        flex: 0 0 150px;

        @media screen and (max-width: 1440px) {
            width: 120px;
            flex: 0 0 120px;
        }
    }

    .ant-slider-rail {
        height: 11px;
        border-radius: 35px;
        background-color: #CDCDCD!important;
    }

    .ant-slider-handle {
        &:after {
            background: $darkGradient;
            box-shadow: none!important;
            width: 16px!important;
            height: 16px!important;
            inset-inline-start: 0!important;
            inset-block-start: 0!important;
        }
    }
}
