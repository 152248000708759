@import "./mixins/variables";
@import "./mixins/breakpoints";

.app-dark-mode {

  .prompt-link {
    svg {
      path {
        fill: $darkModeText;
      }
    }
  }

  .audio-player {
    .rhap_container {
      background: transparent;
      box-shadow: 0 4px 30px 0 rgba(255, 255, 255, 0.25);

      .rhap_header, .rhap_time {
        color: $darkModeText;
      }
    }
  }

  .billing {
     background: $darkModeBg;
      .billing__selector__head {
          &:after {
              background: #949BAB;
          }
      }
  }

  .billing > .ant-col:last-of-type {
    box-shadow: 0 4px 30px 0 rgba(255, 255, 255, 0.25);

    @media screen and (max-width: 768px) {
      box-shadow: none;
    }

    .credit-card {
      background: $darkModeBg;
      border: 3px solid $darkModeText;
      color: $darkModeText;
    }

    .billing__payment {
      background: $darkModeBg;
    }

    .billing__payment__settings .ant-col {
      h4, span {
        color: $darkModeText;
      }

      button {
        background: $darkModeBg;
      }
    }

    .billing__payment__price {
      background: $darkModeBg;

      h2 {
        color: $darkModeBg;
      }
    }

    .billing__payment__history {
      border: 2px solid $darkModeText;
      border-radius: 5px;

      @media screen and (max-width: 768px) {
        border: 0;
        border-radius: 0;
      }
    }

    .billing__payment__price .ant-row .ant-col {
      color: $darkModeText;
    }
  }

  .billing__selector__settings {

    h2 {
      color: $darkModeBg;
    }

    .ant-table-wrapper .ant-table table {
      background: $darkModeBg;
      border-radius: 0 0 10px 10px;
      border: 1px solid $darkModeText;

      th {
        background: transparent;
      }
    }
  }

  .billing__selector {
    background: transparent;

    .billing__selector__config__radio .ant-form-item-row .ant-form-item-label label {
      color: $darkModeText;
    }

    .billing__selector__config__radio label.ant-radio-button-wrapper:not(.ant-radio-button-wrapper-checked) {
      background: $darkModeBg;
      border: 1px solid $darkModeText;

      span {
        color: $darkModeText;
      }
    }

    .billing__selector__config__inner.ant-form-item .ant-form-item label.ant-radio-button-wrapper:not(.ant-radio-button-wrapper-checked) {
      background: $darkModeBg;
      border: 1px solid $darkModeText;

      span {
        color: $darkModeText;
      }
    }
  }

  main.ant-layout-content, .tools-page__tabs, .img-to-video__history {
    background: $darkModeBg !important;
  }

  .single-tool__back.ant-btn {
    span {
      color: $darkModeText !important;
    }
  }

  .single-tool__prompt {
    background: $darkModeBg;
    outline: 1px solid rgba(255, 255, 255, 0.85);
  }

  .single-tool__tip {
    background: $darkModeBg;
    outline: 1px solid rgba(255, 255, 255, 0.85);
  }

  .single-tool__right {
    border-left: 2px solid $darkModeText;
    @include maxw($breakpoint-lg) {
      border-left: 0;
    }
  }

  .single-tool__title {
    background: $darkModeBg;
    padding-left: 0;

    svg path {
      fill: $darkModeText !important;
    }
  }

  .tools-page {
    .ant-empty-description {
      p {
        color: $darkModeText !important;
      }
    }
  }

  .tools-page__search {
    border: 2px solid rgba(255, 255, 255, 0.85);
    overflow: hidden;

    svg {
      fill: $darkModeText !important;
    }

    input {
      background: $darkModeBg !important;
      color: $darkModeText;

      &::placeholder {
        color: $darkModeText !important;
      }
    }
  }

  .tools-page__tabs .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-tab .ant-tabs-tab-btn {
    color: $darkModeText;
  }

  .tools-page__single-card {
    background: $darkModeBg;
    outline: 1px solid rgba(255, 255, 255, 0.85);

    .ant-col:first-of-type {
      opacity: .9;
    }

    h4, p, div {
      color: $darkModeText !important;
    }
  }

  .text-to-speech {
    form.ant-form {
      background-color: rgb(20, 20, 20);
      outline: 1px solid rgba(255, 255, 255, 0.85);
    }
  }

  .chat-input-container {
    background: #141414;
  }

  .chat-input {
    background: $darkModeBg;
    outline: 1px solid rgba(255, 255, 255, 0.85);

    textarea {
      color: $darkModeText;

      &::placeholder {
        color: $darkModeText;
      }
    }
  }

  .chat-input {
    position: relative;
    transition: all .2s;
    display: flex;
    flex-direction: column;
    background: $colorWhite;
    border-radius: 55px;
    border: 3px solid #cccccc;

    .chat-upload {
        position: absolute;
        top: 15px;
        left: 15px;
        z-index: 10;
        display: flex;
        align-items: center;
        margin: 0;
    }

    .ant-upload-list {
        position: absolute;
        top: 10px;
        left: 50px; /* Adjust based on the width of the upload button */
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
        max-width: calc(100% - 60px); /* Adjust based on the left position */
    }

    .chat-input-textarea {
        width: 100%;
        resize: none;
        border: none;
        outline: none;
        padding: 10px 90px 10px 50px;
        margin-bottom: 0;
        background: white!important;
        color: black;

        &.ant-input {
            border-radius: 0 0 52px 52px;
        }
    }

    .chat-action-icons {
        position: absolute;
        right: 3px;
        bottom: 3px;
    }
}


/* Responsive adjustments */
@media screen and (max-width: 768px) {
    .chat-input {

        &.files-attached {
            padding-top: 80px;
        }

        .chat-upload {
            top: 15px;
            left: 10px;
        }

        .ant-upload-list {
            left: 40px;
            max-width: calc(100% - 50px);
        }

        .chat-input-textarea {
            padding: 8px 70px 8px 25px;
        }

        .chat-action-icons {
            right: 5px;
            bottom: 5px;
        }
    }
}

  .user-survey-wrap .anticon svg path {
    fill: $darkModeText;
  }

  .user-survey-examples, .user-survey-limits {
    li {
      background-color: rgb(20, 20, 20);
      border: 1px solid rgba(255, 255, 255, 0.85);

      span {
        color: rgba(255, 255, 255, 0.85);
      }
    }
  }

  .user-survey-limits {
    li {
      &:hover {
        background: transparent;
      }
    }
  }

  .scroll-button {
    border: 1px solid rgba(255, 255, 255, 0.85);

    &:after {
      color: rgba(255, 255, 255, 0.85);
    }

    &:hover {
      border-color: rgba(255, 255, 255, 0.997);
    }

    &:hover:after {
      color: rgba(255, 255, 255, 0.997);
    }
  }
}


/// chat

.app-dark-mode {

  .ant-drawer .ant-drawer-body {
    background: $darkModeBg;
  }

  .ant-list-item.chat-message-item {
    background-color: #141414; // Black background for dark mode
  }

  .ant-list-item.chat-message-item {
    &:nth-child(even) {
      .chat-message-response {
        background-color: #141414; // Dark gray background for even items
        padding: 12px 0px;
        border-radius: 18px 18px 18px 0;
        display: inline-block;
        border: 0;
      }
    }

    &:nth-child(odd) {
      .chat-message-response.question {
        background-color: #fff3; // White background for odd items
        color: white;
        padding: 12px 16px;
        border-radius: 18px 18px 0 18px;
        width: 100%;
        float: right;
        box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.1);
      }
    }
  }

  .chat-btns-wrapper {
    svg {
      path {
        fill: $darkModeText;
      }
    }
  }

  .chat-message-response {
    h1, h2, h3, h4, h5, h6 {
      color: $darkModeText;
    }

    &__generate-doc {
      svg path {
        fill: $darkModeText;
      }
    }
  }

  .chat-item-spinning {
    background: rgba(255, 255, 255, 0.03);
  }

  .chat-upload-clip svg path {
    fill: $darkModeText;
  }

  .chat-upload {
    .chat-upload__popover {
      background: $darkModeBg;
      border: 1px solid $darkModeText;
    }

    .ant-upload-list.ant-upload-list-picture {
      .custom-item-renderer {
        background: $darkModeBg;
        border: 1px solid $darkModeText;

        .custom-item-renderer__text {
          p {
            color: $darkModeText;
          }
        }
      }
    }
  }

  .chat-btn-engine__wrap .chat-btn-engine__wrap__popover {
    & > div {
      background: $darkModeBg;
      border: 1px solid $darkModeText;

      h3, p, span {
        color: $darkModeText;
      }
    }
  }

  .chat-add-btns button.ant-btn.ant-btn-default {
    outline: 2px solid $darkModeText;
    border: 0;
  }

  .chat-btn-engine__wrap .ant-radio-button-wrapper {
    outline: 1px solid $darkModeText;

    > span {
      color: $darkModeText;
    }

    svg {
      fill: $darkModeText;

      path {
        fill: $darkModeText;
      }
    }
  }

  .prompts-search-input input.ant-input {
    color: $darkModeBg;
  }

  .chat-history-sidebar {
    background: $darkModeBg;
  }

  .menu-drawer-wrap.quick-access.ant-drawer-content .ant-drawer-header-title .ant-drawer-close svg {
    color: $darkModeText;
  }

  .chat-convo-header {
    background: $darkModeBg;
  }

  .menu-side-wrap {
    background: transparent;

    &.desktop {
      & > .ant-flex {
        span.ant-typography {
          color: $darkModeText;
          opacity: .8;
        }

        .ant-menu-item {
          &:hover {
            background: $blueGradient;

            .ant-menu-title-content {
              span {
                color: $colorWhite;
              }
            }
          }

          .ant-menu-title-content {

            svg {
              color: $darkModeText;
            }

            span {
              color: $darkModeText;
            }
          }
        }
      }
    }
  }

  .menu-side__bottom__ref {
    svg {
      fill: $darkModeText;
    }
  }

  .menu-side {
    box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 1);
  }

  .menu-side-wrap {
    li.ant-menu-submenu {
      .ant-menu-submenu-title {
        span {
          color: $darkModeText;
        }
      }

      .ant-menu-submenu-arrow {
        color: $darkModeText;
      }

      ul.ant-menu {
        background: 0;

        .ant-menu-item {
          span.ant-menu-title-content {
            color: $darkModeText;
          }
        }
      }
    }
  }

  .prompts-library .ant-drawer-header {
    background: $darkModeBg;
  }

  .prompts-library span.anticon.anticon-close svg {
    color: $darkModeText;
  }

  .prompts-library .ant-tabs.prompts-library__tabs > .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list {
    background: $darkModeBg;
    border-top: 1px solid $darkModeText;
    border-bottom: 1px solid $darkModeText;

    & > div:nth-of-type(3) {
      border-right: 0;
    }

    .ant-tabs-tab {
      color: $darkModeText;
      border-right: 1px solid $darkModeText;
    }
  }

  .prompts-search-input, .prompts-library .ant-drawer-body {
    background: $darkModeBg;
  }

  .mob-menu__border:after {
    background: $darkModeText;
  }

  .prompts-accordion .ant-collapse-item {
    background: $darkModeBg;
    outline: 1px solid $darkModeText;
    outline-offset: -1px;

    .ant-collapse-header .ant-collapse-expand-icon svg path {
      fill: $darkModeText;
    }
  }

  .prompts-library .ant-tabs-tabpane .tools-page .ant-card-head {
    border: 0;
  }


  .menu-side__bottom ul.ant-menu.ant-menu-sub.ant-menu-vertical {
    background: $darkModeBg;
    outline: 1px solid $darkModeText;

    li.ant-menu-item .ant-row .ant-col {
      color: $darkModeText;

      svg {
        path {
          stroke: $darkModeText;
        }
      }

      .ant-switch-inner {
        svg {
          path {
            stroke: none;
          }
        }
      }
    }
  }

  ul.menu-side__settings {
    background: transparent;

    li.ant-menu-submenu .ant-menu-submenu-title {
      h4, span:not(.ant-avatar-string):not(.ant-avatar), svg {
        color: $darkModeText;

        path {
          stroke: $darkModeText;
        }
      }
    }
  }

  h2.chat-history-title {
    background: $darkModeBg;
  }

  .chat-wrap > div.ant-col:first-of-type {
    background: $darkModeBg;
  }

  .chat-wrap > div.ant-col:last-of-type {
      border-left: 1px solid rgba(255, 255, 255, 0.85);
  }

  .chat-messages ul.ant-list-items {
    background: $darkModeBg;
  }

  .chat-history-item {
    background-color: rgb(20, 20, 20) !important;

    @media screen and (max-width: 768px) {
      border: 1px solid rgba(255, 255, 255, 0.85);
    }
  }

  .chat-message-streaming {
    color: rgba(255, 255, 255, 0.85);
  }

  .chat .ant-list-item:nth-child(2n) {
    background: rgba(255, 255, 255, 0.03);
  }

  .chat-history-item--selected,
  .chat-history-item:hover {
    border: 1px solid rgb(255, 255, 255);
    background-color: rgb(0, 0, 0);

    @media screen and (max-width: 768px) {
      background-color: rgba(101, 100, 100, 0.3) !important;
    }
  }

  .chat-history-item.chat-history-item--selected {
    background: rgba(255, 255, 255, 0.2) !important;
  }

  .chat-history-date {
    color: $colorPrimary;
  }

  .chat-button-stop {
    border: 1px solid rgb(255, 255, 255);
  }

  // modal easy chat input
    .chat-share {
        background: $darkModeBg;
        border-bottom: 1px solid $darkModeText;

        @media screen and (max-width: 768px) {
            border-top: 1px solid $darkModeText;
            border-bottom: 0;
        }

        span.ant-typography {
            color: $darkModeText;
        }

        svg {
            fill: $darkModeText;

            path {
                fill: $darkModeText;
            }
        }
    }

  .chat-input-container {
    .ant-modal {
      label {
        color: #fff;
      }

      .ant-btn-default {
        color: #fff
      }

      .ant-input {
        color: #000;
      }
    }
  }
}

/// image to video

.app-dark-mode {
  .img-to-video__presenter__menu .ant-tabs-nav .ant-tabs-tab h4 svg path {
    fill: $darkModeText;
  }

  .img-to-video__presenter__menu__items {
    @media screen and (max-width: 1024px) {
      background: $darkModeBg;
    }
    @media screen and (max-width: 768px) {
      width: 100%;
      margin: 0;
    }
  }

  .img-to-video__loader .ant-modal-content .ant-spin-container.ant-spin-blur {
    background: transparent;
    opacity: 0;
  }

  .img-to-video__search {
    border: 2px solid rgba(255, 255, 255, 0.85);
    overflow: hidden;

    svg {
      fill: $darkModeText !important;
    }

    input {
      background: $darkModeBg !important;
      color: $darkModeText;

      &::placeholder {
        color: $darkModeText !important;
      }
    }
  }

  .img-to-video__history .ant-list-item .ant-card-meta-description {
    color: $darkModeText !important;
  }

  .img-to-video__share .ant-modal-content {

    .ant-modal-close-x {
      color: $darkModeText;
    }

    .ant-modal-header {
      background: $darkModeBg;
    }
  }

  .img-to-video__share__list .ant-col span.anticon {
    background: $darkModeBg;
    outline: 2px solid $darkModeText;

    svg {
      color: $darkModeText;

      path {
        fill: $darkModeText;
      }
    }
  }

  .img-to-video__presenter__menu {
    background: $darkModeBg;
    border: 2px solid rgba(255, 255, 255, 0.85);
    border-radius: 5px;
  }

  .img-to-video__settings .ant-tabs .ant-tabs-nav-list {
    @media screen and (max-width: 768px) {
      background: $darkModeBg;
    }
  }

  .img-to-video__settings {

    .ant-form-item-control-input-content > div#text {
      background: $darkModeBg;
      border: 2px solid rgba(255, 255, 255, 0.85);
    }

    .ant-select-arrow {
      svg {
        path {
          fill: $darkModeText;
        }
      }
    }
  }

  .img-to-video__settings input, .img-to-video__settings .ant-select .ant-select-selector {
    border: 2px solid rgba(255, 255, 255, 0.85);
  }

  .img-to-video__settings input, .img-to-video__settings textarea, .img-to-video__settings .ant-select .ant-select-selector {
    background: $darkModeBg;
    color: $darkModeText;

    &::placeholder {
      color: $darkModeText;
    }

    span {
      color: $darkModeText;
    }
  }
}

// art

.app-dark-mode {
  h1.ant-typography.art-page__templates__title {
    color: $colorWhite;
  }

  .art-page {
    background: $darkModeBg;
  }

  .art-page__search__popover {
    background: $darkModeBg;

    .ant-input {
      color: $colorWhite;
      background: $darkModeBg;

      &::placeholder {
        color: $colorWhite;
      }
    }

    .ant-form-item-label label {
      color: $colorWhite;
    }
  }

  .art-page__search {

    textarea {
      color: $colorWhite;
    }

    input {
      border: 1px solid rgba(255, 255, 255, 0.85);
      @media (min-width: 1024px) {
        border-right: none;
      }
    }

      .art-page__search__btn__sizes {
        button {
            span {
                color: rgba(255, 255, 255, 0.85);
            }
        }
      }

    .ant-input:focus {
      @media (min-width: 1024px) {
        border-right: none;
      }
    }
  }

  .art-page .art-btn-engine__wrap svg path {
    fill: $colorWhite;
    transition: .2s;
  }

  .art-page .art-btn-engine__wrap .ant-radio-button-wrapper:not(.isActive) {
    border: 1px solid $colorWhite;

      span {
          color: $colorWhite;
      }

    &:hover {
        span svg path {
            fill: $colorWhite!important;
        }
    }
  }

  .art-page .art-btn-engine__wrap .isActive.ant-radio-button-wrapper {
    border: 0;
  }

  .art-generated-btns button {
    border: none;
    background-color: $colorDark;

    & span {
      color: $colorWhite !important;
    }
  }

  .art-history {
    background: $darkModeBg;
  }

  .art-page__notification {
    background-color: $colorDark;
    color: $colorWhite;

    svg {
      path {
        fill: $darkModeText;
      }
    }
  }
}

//just speak

.app-dark-mode {

  .audio-page__list__empty {
        span.ant-typography {
            color: $darkModeText;
        }
  }

  .ant-list-item.audio-page__list__item {
    background: $darkModeBg;

    .audio-page__item__main__inner {
      span {
        color: $darkModeText;
        background: 0;
        border: 1px solid $darkModeText;
      }
    }

    .audio-page__item__footer {
      button {
        background: 0;
        border: 1px solid $darkModeText;
        color: $darkModeText;
      }
    }

    span.ant-typography {
      color: $darkModeText;
    }
  }

  .audio-page {
    background-color: $darkModeBg;

    .audio-page-results {
      background: 0;
      border-top: 1px solid $darkModeText;
    }

    .audio-page__list__empty {
      span.ant-typography {
        color: $darkModeText;
      }
    }

    .audio-page__accordion__item .ant-slider .ant-slider-mark .ant-slider-mark-text > div {
      color: $darkModeText;
    }

    .audio-page__accordion__item.boost {
      .ant-checkbox-wrapper .ant-checkbox-inner {
        border: 1px solid $darkModeText;
      }

      button {
        border: 1px solid $darkModeText;
        color: $darkModeText;
      }

    }

    .audio-page__accordion .ant-collapse-item .ant-collapse-header {
      background-color: $darkModeBg;
      border-radius: 8px;

      .ant-collapse-expand-icon > span {
        color: $darkModeText;
      }

      .ant-collapse-header-text {
        color: $darkModeText;
      }
    }

    &-settings {
      background-color: $darkModeBg;
      border: 1px solid $darkModeText;

      @media screen and (max-width: 768px) {
        border: none;
      }
    }

    &-result {
      background: $darkModeBg;

      &.active {
        border-top: 1px solid $darkModeText;
        background: $darkModeBg;
      }
    }

    .gender {
      &-btn {
        background-color: $darkModeBg;
        color: $darkModeText;
        border: 1px solid $darkModeText !important;
      }
    }

    .gender-btn.ant-radio-button-wrapper-checked {
      border: none !important;
    }

    .section {
      background-color: $darkModeBg;
    }

    .card {
      margin-top: 2px;
      background-color: $darkModeBg !important;
      color: $darkModeText;

      @media screen and (min-width: 1024px) {
        margin-top: 3px;
      }
    }

    .content {
      border: 1px solid $darkModeText;
      border-radius: 14px;
      overflow: hidden;

      &:hover, &:active {
        color: $darkModeText !important;
      }

      &-info {
        background-color: $darkModeBg !important;

        p {
          color: $darkModeText;
        }

        &-icon {
          color: $darkModeText;
        }
      }
    }

    .voice-card.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      .content-info {
        border: none;
        border-radius: 0;
      }
    }

    .container {
      background-color: $darkModeBg;
      border: 2px solid rgba(255, 255, 255, 0.85);

      &-text {
        background-color: $darkModeBg;
        color: $darkModeText;

        &::placeholder {
          color: $darkModeText;
        }
      }
    }

    .rhap_container {
      background-color: $darkModeBg;
      border: 1px solid $darkModeText !important;

      .rhap_header, .rhap_time {
        color: $darkModeText;
      }
    }

    .voice-card.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      color: $darkModeText !important;
    }

    .clone-wrap {
      p {
        -webkit-text-fill-color: #fff;
      }

      span:hover {
        svg {
          fill: #0288d1;
        }
      }
    }

    .add-voice-btn:disabled {
      background-color: rgba(255, 255, 255, 0.08);
      border: 1px solid rgba(255, 255, 255, 0.85);
      color: rgba(255, 255, 255, 0.85);

      :hover {
        color: #000;
      }

      .clone_voice__wrap__popover {
        & > div:nth-child(2) {
          background: #141414;
          border: 1px solid rgba(255, 255, 255, 0.85);
        }

        p,
        span {
          opacity: 1;
          color: rgba(255, 255, 255, 0.85);
        }
      }
    }
  }
}

//modal

.app-dark-mode {

    .ant-modal-content {
        div.ant-modal-header {
            background: 0;
            border-bottom: 1px solid $colorWhite;

            .ant-modal-title {
                color: $colorWhite;
            }
        }

        .ant-modal-body {
            .ant-form-item {
                textarea.ant-input, input.ant-input {
                    background: 0!important;
                    border: 1px solid $colorWhite;
                }
            }

            span.ant-typography {
                color: $colorWhite;
            }
        }
    }

  .new-doc-modal {
    .ant-modal-header {
      background: 0!important;
        border-bottom: 1px solid $colorWhite;

        .ant-modal-title {
            color: $colorWhite;
        }
    }

    .ant-modal-body {
        .ant-form-item {
            input.ant-input {
                background: 0!important;
                border: 1px solid $colorWhite;
            }
        }

      & > p {
        color: $colorWhite!important;
      }
    }
  }

  .ant-modal.ant-modal-confirm.modal-confirm {
    .ant-modal-confirm-title {
      background: $colorDarkBlue;
      color: $colorWhite;
    }

    .ant-modal-content {
      .ant-modal-confirm-content {
        h4 {
          color: $colorWhite;
          opacity: .5;
        }
      }
    }
  }

  .ant-modal.modal-component {

    .ant-modal-content {
      .ant-modal-header {
        background-color: $colorDarkBlue;

        .ant-modal-title {
          color: $darkModeText;
        }
      }

      span.ant-typography, h5.ant-typography {
        color: $darkModeText;
      }

      .ant-form-item {
        background: transparent;

          .ant-form-item-control-input-content {
              input {
                  background: transparent;
              }
          }
      }
    }
  }

  .modal-warning {
    .ant-modal {
      &-content {
        background-color: $darkModeBg;
        border: 1px solid $darkModeText;
      }

      &-confirm {
        &-title {
          color: $darkModeText !important;
          background-color: $colorBlack !important;
          border-bottom: 1px solid $darkModeText;
        }

        &-btns {
          bottom: 15px;

          @media screen and (min-width: 768px) {
            bottom: 45px;
          }

          @media screen and (min-width: 1440px) {
            bottom: 74px;
          }
        }
      }

      &-close {
        color: $darkModeText !important;
      }
    }

    .ant-typography {
      color: $darkModeText;
    }

    .ant-col.opacity {
      color: $darkModeText;
    }
  }


  .ant-modal.modal-component,
  .ant-modal.modal-confirm,
  .ant-modal-root {
        .ant-modal-content {
            .ant-modal-header {
                background: rgba(255, 255, 255, 0.04);

                .ant-modal-title {
                    color: #D3D3D3;
                }
            }

            h5.ant-typography {
                color: $colorWhite;
            }
    }

      input,
    textarea {
      background: rgba(255, 255, 255, 0.04);
      border: 1px solid #fff;
    }

    input.ant-input,
    textarea.ant-input {
      color: #D3D3D3;
        background: #1f1f1f;
      border: 1px solid $darkModeText;
    }

    input::placeholder,
    textarea::placeholder {
      color: #4A4A4A;
    }

    .ant-upload-drag-container {
      .ant-upload-text {
        color: #D3D3D3 !important;
      }

      svg {
        path {
          fill: #D3D3D3;
        }

        &:hover {
          path {
            fill: #fff;
          }
        }
      }
    }

    .ant-btn-icon svg > path {
      fill: #fff;
    }

    .ant-checkbox-wrapper {
      span:nth-child(2) {
        color: #D3D3D3;
      }
    }

    .ant-modal-footer {
      .ant-btn-default {
        border: 1px solid #D3D3D3;
        color: #D3D3D3;

        &:hover {
          color: rgba(255, 255, 255, 0.4);
        }
      }
    }
  }

  .ant-modal .ant-form {
    > div:nth-child(3) {

      > p {
        color: #fff;
      }

      > span > svg > path {
        fill: #fff;
      }
    }
  }
}

// book

.app-dark-mode {
  .book {
    background: $darkModeBg;
  }

  .book-page {
    background-color: rgba(255, 255, 255, 0.04);
  }

  .ant-steps .ant-steps-item-wait .ant-steps-icon {
    color: $colorBlack;
  }
}

// home
.app-dark-mode {
  .ant-typography {
    color: $colorWhite;
  }

  .ant-card-meta-description {
    color: rgba(255, 255, 255, 0.85) !important;
  }
}

// document

.app-dark-mode {

  input.toolbar-title {
    color: $colorWhite;
      background: $darkModeBg;
      border: 1px solid $colorWhite!important;
      padding: 5px 10px;
  }

  .toolbar-save {
    color: $colorWhite;
  }

  .toolbar-options {
    background: $darkModeBg;

    button.toolbar-option {
      color: $colorWhite;
    }
  }

  .toolbar-buttons {
    background: $darkModeBg;
  }

  .editor-inner {
    background: $darkModeBg;
  }

  .editor-content {
    color: $darkModeBg;
  }
}

// custom-form

.app-dark-mode {
  .custom-instruction-modal {
    .ant-modal-content, .ant-modal-body {
      span.ant-typography, span.ant-typography.modal-form-tip {
        color: $colorWhite;
      }
    }
  }

  .modal-form-popover {
    .ant-popover-title {
      background: rgba(255, 255, 255, 0.04);

      .anticon {
        svg {
          path {
            fill: $colorWhite;
          }
        }
      }
    }

    ul {
      li {
        color: $colorWhite;
      }
    }
  }
}
