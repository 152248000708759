@import '../../styles/mixins/variables.scss';
@import '../../styles/mixins/breakpoints';

.card {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    &__title {
        color: $colorLightBlack;
        text-align: center;
        line-height: 1;
        font-size: 24px;
        font-weight: 600;
    }

    .selected_text {
        color: $colorWhite;
    }
}
