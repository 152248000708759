@import "../../../../styles/mixins/breakpoints.scss";
@import "../../../../styles/mixins/variables.scss";

.chat {
    position: relative;
    height: calc(100% - 45px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &-message {
        width: 100%;
        align-items: flex-start;

        &.user-message {
            justify-content: flex-end;
            padding-right: 32px;

            @media screen and (max-width: 768px) {
                padding-right: 16px;
            }

            .chat-message-wrapper {
                max-width: 70%;
                width: 100%;
            }

            .chat-message-content {
                .chat-message-response.question {
                    background-color: #f0f0f0;
                    border-radius: 18px 18px 0 18px;
                    padding: 12px 16px;
                    position: relative;
                    display: inline-block;
                    max-width: 100%;
                    margin-left: auto;
                    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
                    color: #333;
                    font-size: 16px;
                    line-height: 1.4;
                    white-space: pre-wrap;
                    word-wrap: break-word;

                    &:after {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        right: -8px;
                        width: 16px;
                        height: 16px;
                        background-color: #f0f0f0;
                        border-bottom-left-radius: 16px;
                    }
                }
            }
        }
    }

    &-input-container {
        margin-top: 10px;
        width: 100%;
        position: relative;

        @media screen and (max-width: 720px) {
            border-top: 1px solid #c1c1c1;
            margin: 0;
            width: 100%;
            bottom: 0;
        }
    }

    &-button-stop {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -150%);
        border: 1px solid rgb(31, 31, 31);
        border-radius: 5px;
    }

    &-input {
        transition: all .2s;
        display: flex;
        background: $colorWhite;
        padding: 10px 90px 10px 8px;
        position: relative;
        border-radius: 55px;
        align-items: flex-end;
        border: 3px solid #cccccc;

        // New rules for dynamic border radius
        &:has(.chat-input-textarea.ant-input[rows="1"]) {
            border-radius: 30px;
        }

        &:has(.chat-input-textarea.ant-input[rows="2"]) {
            border-radius: 30px;
        }

        &:has(.chat-input-textarea.ant-input[rows="3"]) {
            border-radius: 30px;
        }

        &:has(.chat-input-textarea.ant-input[rows]:not([rows="1"]):not([rows="2"]):not([rows="3"])) {
            border-radius: 30px;
        }

        &.files-attached {
            .chat-input-textarea {

                @media screen and (max-width: 720px) {
                }
            }
        }

        @media (min-width: 1024px) {
            max-width: 781px;
            margin-left: auto;
            margin-right: auto;
        }

        @media screen and (max-width: 720px) {
            width: calc(100% - 32px);
            border: 0.5px solid rgba(31, 31, 31, 0.20);
            margin: 10px 16px 12px 16px;
            padding: 5px 16px 10px;
        }

        &-prompt {
            margin-top: 10px;
            font-size: 12px;
            font-weight: 400;
            text-align: center;
            color: rgb(86, 88, 105);

            @media screen and (max-width: 720px) {
                margin: 12px auto;
            }

            a {
                position: relative;
                font-weight: 700;
                text-decoration: underline;
                background-image: $purpleGradient;
                -webkit-background-clip: text;
                background-clip: text;
                color: transparent;

                &:after {
                    content: '';
                    position: absolute;
                    bottom: 1px;
                    left: 0;
                    width: 100%;
                    height: 1px;
                    background: $purpleGradient;
                }
            }
        }

        &-textarea {
            resize: none;
            overflow: hidden;

            &:focus {
                &::placeholder {
                    color: transparent !important;
                }
            }

            &.ant-input {
                resize: none;
                min-height: initial;
                height: initial;
                padding: 10px 20px 8px 0;
                max-height: 350px;
                overflow-y: auto;
                border: 0;
                margin-bottom: 0;
                outline: 0;
                font-size: 16px;
                color: $colorDark;
                border-radius: 0;
                margin-bottom: 6px;
                transition: height 0.2s ease-in-out;
                line-height: 1.5;

                @media screen and (max-width: 720px) {
                    font-size: 14px;
                    font-weight: 500;
                    margin-bottom: 1px;
                }

                &::placeholder {
                    color: #D3D3D3;
                }

                &:hover,
                &:active,
                &:focus {
                    border: none;
                    outline: none;
                    box-shadow: none;
                }
            }
        }

        &-button.ant-btn-primary.ant-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            background: $blueGradient;
            box-shadow: none;
            margin: 0 0 0 8px;
            border-radius: 55px;
            padding: 0;

            @media screen and (max-width: 720px) {
                width: 45px;
                height: 45px;
            }

            &:hover {
                background: $blueGradient;
            }

            svg {
                width: 36px;
                height: 36px;
                font-size: 16px;
                object-fit: contain;

                @media screen and (max-width: 720px) {
                    width: 16px;
                    height: 16px;
                }

                path {
                    fill: rgb(255, 255, 255);
                }
            }

            &.ant-btn {
                &:hover {
                    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
                }
            }

            &.record-btn {
                border-radius: 55px;
                margin: 0;
                background: $blueGradient;
            }
        }
    }
}

.chat-share {
    height: 45px;
    background: $colorWhite;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: rgba(31, 31, 31, .5);
    font-size: 16px;
    font-weight: 600;
    border-bottom: 1px solid rgba(31, 31, 31, .08);

    @media screen and (max-width: 768px) {
        background: rgba(31, 31, 31, .05);
        border-bottom: 0;
    }

    // New styles to bring buttons closer together
    > .ant-flex {
        flex-grow: 1;
    }

    span.ant-typography {
        color: rgba(31, 31, 31, .5);
    }

    svg {
        fill: rgba(31, 31, 31, .5);

        path {
            fill: rgba(31, 31, 31, .5);
        }
    }

    button {
        width: 35px !important;
        height: 35px;
        border: 1px solid #CDCDCD;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 8px; // Add space between buttons

        svg {
            font-size: 18px;
            color: rgba(31, 31, 31, .5);
        }
    }

     // Create a button group for the last two buttons
     button:nth-last-child(-n+2) {
        margin-left: 0;
        border-radius: 7px;
    }

    button:nth-last-child(2) {
        border-radius: 7px;
    }

    button:last-child {
        background: black;
        border: none;
        margin-left: 15px;

        svg {
            color: white; // Change icon color to white for better contrast
            fill: white;
        }

        path {
            fill: white;
        }

        &:hover {
            background: gray!important;
        }
    }
}

.scroll-to-bottom__wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.scroll {
    &-to-bottom {
        overflow-y: auto;
        height: 100%;

        &>div {
            display: flex;
            flex-direction: column;
        }
    }

    &-button {
        right: 60px !important;
        bottom: 40px !important;
        height: 36px !important;
        width: 36px !important;
        border: 1px solid rgba(0, 0, 0, 0.2) !important;
        border-radius: 50% !important;
        background: none !important;

        @media screen and (max-width: 720px) {
            right: 10px !important;
            bottom: 10px !important;
            height: 24px !important;
            width: 24px !important;
            background: rgb(247, 247, 248) !important;
        }

        &:after {
            content: '\25BC';
            display: flex;
            justify-content: center;
            align-items: center;
            color: rgba(0, 0, 0, 0.2);
            font-size: 15px;

            @media screen and (max-width: 720px) {
                font-size: 13px;
                color: rgb(86, 88, 105);
            }
        }

        &:hover {
            border: 1px solid rgba(0, 0, 0, 0.314);
        }

        &:hover:after {
            color: rgba(0, 0, 0, 0.314);
        }
    }
}

.modal-record {
    div.ant-modal-title {
        font-size: 30px;
        text-align: center;
    }

    img {
        width: 100px;
        height: 100px;
        object-fit: contain;
        display: block;
        margin: 0 auto 15px auto;
    }

    p {
        text-align: center;
        font-size: 16px;
        margin: 0;
        font-weight: 500;

        &:last-of-type {
            color: rgb(255, 0, 0);
            margin-bottom: 70px;
        }
    }

    .ant-modal-footer {
        button {
            margin-left: auto;
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
    }
}

.chat-action-icons {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    transform: none;
    flex-direction: row-reverse;
    gap: 0 !important;
    right: 5px;
    bottom: 5px;


    @media screen and (max-width: 768px) {
        right: 5px;
        bottom: 5px;
    }

    @media screen and (max-width: 720px) {
        right: 5px;
        bottom: 5px;
    }

    button.ant-btn {
        width: 60px;
        height: 60px;

        @media screen and (max-width: 720px) {
            width: 24px;
            height: 24px;
        }

        &:hover {
            svg {
                path {
                    fill: rgb(255, 255, 255);
                }
            }
        }

        svg {
            path {
                transition: .3s ease-in-out;
            }
        }
    }
}

.chat-wrap {
    height: 100vh;
    position: relative;
  
    @media screen and (max-width: 1024px) {
      height: calc(100dvh - 56px);
    }
  
    > .ant-col {
      height: 100%;
    }
  }

  .chat-inner-wrap {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden; // Add this to prevent scrolling issues
}

.ant-list-item.chat-message-item {
    padding: 12px 16px;
    background-color: #ffffff; /* Default white background */

    /* Styles for user messages */
    &:nth-child(odd) {

        .chat-message-content {
            display: flex;
            justify-content: flex-end; /* Align content to the right */
        }

        .chat-message-response.question {
            background-color: #cccccc; /* Slightly darker gray for user message bubble */
            padding: 12px 16px;
            border-radius: 18px 18px 0 18px;
            width: 100%; /* Limit the width of the message */
            float: right;
            box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.1);
        }

        .chat-images, .chat-files {
            justify-content: flex-end; /* Align images and files to the right */
        }
    }

    /* Styles for AI messages */
    &:nth-child(even) {
        .chat-message-content {
            display: flex;
            justify-content: flex-start; /* Align content to the left */
        }

        .chat-message-response {
            background-color: #ffffff; /* White background for AI message bubble */
            padding: 12px 0px;
            border-radius: 18px 18px 18px 0;
            display: inline-block;
            border: 0;
        }

        .chat-images, .chat-files {
            justify-content: flex-start; /* Align images and files to the left */
        }
    }
}



/* Styles for AI responses */
.chat-message:not(.user-message) {
    .chat-message-response {
        background-color: #ffffff; /* White background for AI message bubble */
        padding: 12px 16px;
        border-radius: 18px 18px 18px 0;
        display: inline-block;
    }
}

/* Ensure the chat container has no padding to allow full-width list items */
.chat-messages-wrap {
    padding: 0;
}

.chat-messages-wrap {
    flex: 1;
    overflow-y: auto;
}

.chat-item-wrapper {
    max-width: 750px;
    margin: 0 auto;
}

.chat-message {
    &.user-message {
        justify-content: flex-end;
        padding-right: 32px;

        @media screen and (max-width: 768px) {
            padding-right: 15px;
        }

        .chat-message-wrapper {
            max-width: 70%;
            width: 100%;
        }

        .chat-message-response.question {
            background-color: #f0f0f0;
            border-radius: 18px 18px 0 18px;
            padding: 12px 16px;
            position: relative;
            display: inline-block;
            max-width: 100%;
            margin-left: auto;
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
            color: #333;
            font-size: 16px;
            line-height: 1.4;
            white-space: pre-wrap;
            word-wrap: break-word;

            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                right: -8px;
                width: 16px;
                height: 16px;
                background-color: #f0f0f0;
                border-bottom-left-radius: 16px;
            }
        }
    }
}


.chat-images {
    margin-top: 15px;
    grid-template-columns: 235px 235px;
    position: relative;
    top: 20px;
    grid-column-gap: 7px;
    grid-row-gap: 7px;

    @media screen and (max-width: 1024px) {
        display: flex;
        flex-wrap: wrap;
    }

    @media screen and (max-width: 768px) {
        margin-left: 16px;
    }

    &.single {
        width: 470px;

        @media screen and (max-width: 1024px) {
            width: 250px;
        }

        div.ant-image {
            height: 470px;

            @media screen and (max-width: 1024px) {
                height: 250px;
                width: 100%;
            }
        }

        @media screen and (max-width: 1024px) {
            grid-template-columns: 1fr;
            grid-template-rows: 1fr;
        }
    }

    div.ant-image {
        border-radius: 10px;
        overflow: hidden;
        height: 300px;
        margin-left: 10px;
        margin-bottom: 20px;

        @media screen and (max-width: 1024px) {
            height: 120px;
            width: 120px;
        }

        .ant-image-mask-info {
            display: flex;
        }

        img.ant-image-img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: block;
        }
    }

}

.chat-files {
    bottom: 0;
    left: 0;
    transform: translateY(115%);
    display: flex;
    gap: 6px;
    margin-left: 32px;

    @media screen and (max-width: 768px) {
        margin-left: 16px;
    }

    &>div {
        border: 1px solid rgba(0, 0, 0, 0.314);

        @media screen and (max-width: 768px) {
            height: 35px;
            width: 90px;
        }

        p {
            line-height: 15px;

            @media screen and (max-width: 768px) {
                width: 56px;
            }
        }

        span {
            @media screen and (max-width: 768px) {
                line-height: 6px;
                font-size: 13px;
            }
        }
    }
}

.chat-message-logo {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    flex: 0 0 56px;

    @media screen and (max-width: 768px) {
        width: 30px;
        height: 30px;
        flex: 0 0 30px;
    }

    >span.ant-avatar {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
    }

    >img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
    }
}

.chat-message-response {
    font-weight: 400;
    line-height: 28px;
    font-size: 16px;
    overflow: hidden;

    &.question {
        white-space: pre-line;
    }

    @media screen and (max-width: 1400px) {
        overflow-x: auto;
    }

    @media screen and (max-width: 768px) {
        font-size: 16px;
        font-weight: 400;
        line-height: 1.75;
    }

    hr {
        display: none;
    }

    table {
        width: 100%;
        border-spacing: 0;
        border-radius: .375rem;
        border: 1px solid #d1d5db;
        margin-bottom: 15px;
        margin-top: 5px;

        thead {

            tr {
                th {
                    padding: .25rem .75rem;
                    background: rgba(148, 148, 157, .2);
                    border-right: 1px solid #d1d5db;

                    &:last-of-type {
                        border-right: 0;
                    }
                }
            }
        }

        tbody {
            tr {
                td {
                    padding: .25rem .75rem;
                    border-top: 1px solid #d1d5db;
                    border-right: 1px solid #d1d5db;

                    &:last-of-type {
                        border-right: 0;
                    }
                }
            }
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 0 0 15px;
        line-height: 120%;
    }

    ul,
    ol {
        margin: 0 0 10px 30px;

        li {
            margin: 0 0 10px;
        }
    }

    ul {
        list-style-type: disc;

        ul {
            margin-top: 10px;
            font-size: 14px;

            @media screen and (max-width: 768px) {
                font-size: 11px;
            }
        }
    }

    pre {
        padding: .5em;
        margin-bottom: 10px;
        margin-top: 10px;
        overflow-x: auto;
    }

    p {
        margin: 0 0 15px;
    }

    @media screen and (max-width: 768px) {
        margin-left: 0px;
    }
}

.chat-message-content {
    &.user-message {
        display: flex;
        justify-content: flex-end;
        padding-right: 32px;
        width: 100%;

        @media screen and (max-width: 768px) {
            padding-right: 16px;
        }

        .chat-message-wrapper {
            max-width: 70%;
        }

        .chat-message-bubble {
            background-color: #f0f0f0; // Light gray background
            border-radius: 18px 18px 0 18px; // Rounded corners
            padding: 12px 16px;
            position: relative;
            display: inline-block;
            max-width: 100%;
            margin-left: auto; // Align to the right
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); // Optional: adds a subtle shadow

            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                right: -8px;
                width: 16px;
                height: 16px;
                background-color: #f0f0f0;
                border-bottom-left-radius: 16px;
            }
        }

        .chat-message-response.question {
            color: #333;
            font-size: 16px;
            line-height: 1.4;
            margin: 0;
            white-space: pre-wrap;
            word-wrap: break-word;
            box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.3);
        }
    }
}

.chat-convo-header {
    width: 100%;
    padding: 10px;

    @media screen and (max-width: 768px) {
        padding: 15px;
        background: $colorGrayBg;
    }
}

.chat-convo-history {
    width: 100%;
    padding: 0 10px;
    overflow-y: auto;
}

.chat-message-loader {
    display: flex;
    align-items: center;
    height: 100%;
    margin-left: 15px;
}

.user-survey-wrap {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    top: 10%;
    position: relative;
}

.user-survey-inner {
    text-align: center;
}

.user-survey-icon {
    font-size: 48px;
    margin-bottom: 20px;
    justify-content: center;
}

.user-survey-title {
    margin-bottom: 10px !important;
}

.user-survey-text {
    margin-bottom: 20px !important;
}

.user-survey-examples {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    padding: 0;
    list-style-type: none;
}

.user-survey-examples li {
    aspect-ratio: 1 / 1;
    display: flex;
    border: 1px solid #c1c1c1;
    align-items: center;
    justify-content: center;
    border-radius: 17px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    padding: 10px;
}

.user-survey-examples li:hover {
    background-color: #e0e0e0;
}

.user-survey-examples li span {
    text-align: left;
    word-break: break-word;
}

@media (max-width: 767px) {
    .user-survey-examples {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 479px) {
    .user-survey-examples {
        grid-template-columns: repeat(2, 1fr);
    }
}

.dot-pulse {
    position: relative;
    left: -9999px;
    width: 8px;
    height: 8px;
    border-radius: 5px;
    background-color: #0288d1;
    color: #0288d1;
    box-shadow: 9999px 0 0 -5px;
    animation: dot-pulse 1.5s infinite linear;
    animation-delay: 0.25s;
}

.dot-pulse::before,
.dot-pulse::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    width: 8px;
    height: 8px;
    border-radius: 5px;
    background-color: #0288d1;
    color: #0288d1;
}

.dot-pulse::before {
    box-shadow: 9984px 0 0 -5px;
    animation: dot-pulse-before 1.5s infinite linear;
    animation-delay: 0s;
}

.dot-pulse::after {
    box-shadow: 10014px 0 0 -5px;
    animation: dot-pulse-after 1.5s infinite linear;
    animation-delay: 0.5s;
}

.chat-items-wrapper:last-child {
    .chat-btns-wrapper {
        display: flex;
    }
}

.chat-btns-wrapper {
    display: none;  /* Hide by default */
    align-items: center;
    gap: 12px;
    transition: all ease-in-out .3s;

    @media screen and (max-width: 768px) {
        margin-left: 16px;
    }

    button.ant-btn {
        width: auto;
        height: auto;
        margin: 0;
        padding: 0;
    }

    .chat-btn-generate-doc,
    .chat-btn-regenerate {
        svg {
            cursor: pointer;
            display: block;
            width: 18px;
            height: 18px;
        }
    }
}

.chat-btn-read {
    cursor: pointer;

    svg {
        width: 18px;
        height: 18px;
        color: #1F1F1F;
    }
}

.chat-btn-copy {
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        width: 18px;
        height: 18px;
    }
}

.chat-btn-engine__wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 25px;
    position: relative;
    width: fit-content;
    margin: 50px auto 50px auto;

    @media screen and (max-width: 768px) {
        gap: 8px;
    }

    .ant-radio-button.ant-radio-button-checked {
        display: none;
    }

    .chat-btn-engine__wrap__popover {
        padding-top: 20px;
        z-index: 11;
        position: absolute;
        top: 40px;
        left: 0;
        opacity: 0;
        pointer-events: none;
        transition: .2s ease-in-out;
        width: 100%;
        cursor: auto;

        @media screen and (max-width: 768px) {
            padding-top: 10px;
        }

        &>div {
            border-radius: 10px;
            padding: 10px;
            background: #fff;

            @media screen and (max-width: 768px) {
                border: 1px solid rgba(0, 0, 0, .4);
            }

            h3 {
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 22.4px;
                background: var(--diamond, linear-gradient(to bottom right, #6A74D1 19%, #9B60A5 36%, #9B60A5 50%) bottom right / 50% 50% no-repeat, linear-gradient(to bottom left, #6A74D1 19%, #9B60A5 36%, #9B60A5 50%) bottom left / 50% 50% no-repeat, linear-gradient(to top left, #6A74D1 19%, #9B60A5 36%, #9B60A5 50%) top left / 50% 50% no-repeat, linear-gradient(to top right, #6A74D1 19%, #9B60A5 36%, #9B60A5 50%) top right / 50% 50% no-repeat);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                margin-bottom: 2px;
            }

            p {
                color: #000;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 16.8px;
                margin-bottom: 12px;
            }

            span {
                color: #949BAB;
                font-size: 10px;
                font-style: normal;
                font-weight: 400;
                line-height: 16.8px;

                a {
                    text-decoration: underline;
                    color: #949BAB;
                }
            }
        }
    }

    @media screen and (max-width: 720px) {
        margin: 16px auto 16px auto;
    }

    .ant-radio-button-wrapper {
        display: flex;
        padding: 8px 16px;
        justify-content: center;
        align-items: center;
        border: none;
        border-radius: 5px;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.43;
        box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.05);
        height: 40px;
        position: initial;
        color: #000;

        @media screen and (max-width: 768px) {
            height: 45px;
        }

        &:hover {
            .chat-btn-engine__wrap__popover {
                opacity: 1;
                pointer-events: auto;
            }
        }

        &>span {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 4px;
        }


        &:not(:first-child)::before {
            width: 0;
        }
    }

    &:hover {
        span {
            color: #000;
        }
    }

    & .ant-radio-button-wrapper-checked {
        background: radial-gradient(192.53% 172.33% at 0% 0%, #0288D1 38.54%, #51AFE0 72.4%, #EAF9FE 99.48%);
        color: #fff;

        span {
            color: #fff;
        }

        & svg {
            fill: #fff;

            path {
                fill: #fff;
            }
        }
    }
}

.chat-upload__popover {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(-110%);
    width: 350px;
    background: $colorWhite;
    height: auto;
    padding: 15px;
    border-radius: 10px;
    z-index: 8;
    border: 1px solid #ccc;

    @media screen and (max-width: 768px) {
        width: 325px;
        top: -15px;
        left: -15px;
    }
}

.chat-upload {
    margin-right: 15px;
    margin-left: 15px;
    display: flex;
    align-items: flex-end;
    height: fit-content;
    position: relative;
    bottom: 5px;

    @media screen and (max-width: 768px) {
        position: relative;
        margin-right: 10px;
        margin-left: 0;
        bottom: 5px;
    }

    .ant-upload-select {
        &:hover {
            .chat-upload__popover {
                display: block;
            }
        }
    }

    .ant-upload-list.ant-upload-list-picture {
        position: absolute;
        top: 10px;
        left: 8px;
        display: flex;
        gap: 14px;
        align-items: flex-end;
        z-index: 9;

        @media screen and (max-width: 720px) {
            left: 14px;
        }

        &:before,
        &:after {
            display: none;
        }

        .ant-upload-list-item-container {
            transition: none !important;

            &.ant-upload-animate-leave {
                display: none !important;
            }
        }
    }

    .ant-upload-select {
        button {
            width: 31px;
            height: 34px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: transparent;
            box-shadow: none;
            border-radius: 5px;
            border: 0;

            &:hover {
                background: transparent !important;
            }

            @media screen and (max-width: 720px) {
                width: 24px;
                height: 24px;
                position: relative;

                svg {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}

.chat-wrap__drag {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    pointer-events: none;
  
    &.active {
      pointer-events: auto;
    }
  
    .ant-upload-drag {
      height: 100%;
    }
  }

.chat-wrap__drag__title {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 11;
    display: none;
    pointer-events: none;
    color: #fff;

    .anticon-cloud-upload {
        font-size: 60px;
        display: flex;
        justify-content: center;
    }

    h1 {
        font-weight: 600;
        font-size: 20px;
        line-height: 22px;
    }

    span {
        font-weight: 400;
        font-size: 15px;
        line-height: 22px;
    }

    h1,
    span {
        margin: 0;
        color: #fff;
    }
}

.chat-wrap__drag {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    pointer-events: none;

    .ant-upload-drag {
        &:before {
            content: '';
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            position: absolute;
            background: $darkGradient;
            opacity: .85;
            z-index: 8;
            display: none;
            pointer-events: none;
        }
    }

    &.active {
        pointer-events: auto;

        .ant-upload-drag {
            &.ant-upload-drag-hover {
                .chat-wrap__drag__title {
                    display: block;
                }

                &:before {
                    display: block;
                }
            }
        }
    }

    .ant-upload.ant-upload-drag {
        border: 0;
        cursor: initial;

        &>.ant-upload.ant-upload-btn {
            padding: 0;
        }
    }
}

.chat-messages__read_aloud {
    display: none !important;
}

// chat easy input
.chat-input-container {
    position: sticky;
    bottom: 0;
    background-color: white; // Match your background color
    padding: 0px 25px 15px 25px;
  
    @media screen and (max-width: 720px) {
      padding: 0px 0px;
    }
  }

.modal-confirm {
    .ant-modal-confirm-paragraph {
        max-width: none !important;
    }
}

@keyframes dot-pulse-before {
    0% {
        box-shadow: 9984px 0 0 -5px;
    }

    30% {
        box-shadow: 9984px 0 0 2px;
    }

    60%,
    100% {
        box-shadow: 9984px 0 0 -5px;
    }
}

@keyframes dot-pulse {
    0% {
        box-shadow: 9999px 0 0 -5px;
    }

    30% {
        box-shadow: 9999px 0 0 2px;
    }

    60%,
    100% {
        box-shadow: 9999px 0 0 -5px;
    }
}

@keyframes dot-pulse-after {
    0% {
        box-shadow: 10014px 0 0 -5px;
    }

    30% {
        box-shadow: 10014px 0 0 2px;
    }

    60%,
    100% {
        box-shadow: 10014px 0 0 -5px;
    }
}

.chat-btn-engine__wrap {
    display: flex;
    align-items: center;
}

.chat-btn-engine__wrap .ant-radio-button-wrapper,
.chat-btn-engine__wrap .ant-dropdown-trigger {
    height: 40px;
    padding: 0 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.chat-btn-engine__wrap .anticon,
.chat-btn-engine__wrap .custom-icon {
    display: flex;
    align-items: center;
    margin-right: 8px;
}

.chat-btn-engine__wrap .ant-dropdown-trigger {
    min-width: 120px;
}

.chat-btn-engine__wrap .ant-radio-button-wrapper>*,
.chat-btn-engine__wrap .ant-dropdown-trigger>* {
    display: flex;
    align-items: center;
}

.chat-btn-engine__wrap .anticon-down {
    margin-left: 4px;
}

/* New styles for highlighting the selected dropdown option */
.chat-btn-engine__wrap .ant-dropdown-trigger.selected {
    background: radial-gradient(192.53% 172.33% at 0% 0%, #0288D1 38.54%, #51AFE0 72.4%, #EAF9FE 99.48%);
    /* Light blue background */
    border-color: #1890ff;
    /* Blue border */
    color: white !important;
    /* Blue text */
}

.chat-btn-engine__wrap .ant-dropdown-trigger.selected:hover,
.chat-btn-engine__wrap .ant-dropdown-trigger.selected:focus {
    background-color: #bae7ff;
    /* Slightly darker blue on hover/focus */
    border-color: #40a9ff;
    /* Slightly darker blue border */
    color: #40a9ff;
    /* Slightly darker blue text */
}

.chat-btn-engine__wrap .ant-dropdown-trigger.selected .ant-btn-icon svg,
.chat-btn-engine__wrap .ant-dropdown-trigger.selected .custom-icon svg {
    fill: white;
    /* White SVG fill */
}

.mobile-chat-engine-dropdown {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    height: 50px;
    font-size: 20px;
    width: 90%;
    margin: 20px auto;
    font-weight: 600;
    box-shadow: 3px 7px 15px 0 rgba(0, 0, 0, 0.1);

    .anticon {
        margin-right: 8px;
    }
}

.ant-dropdown-menu-item {
    display: flex!important;
    font-size: 18px!important;
    align-items: center!important;
    padding: 12px 16px!important;

    span {
        line-height: 24px!important; // Match this to the icon height
    }

    .anticon,
    .custom-icon {
        display: flex!important;
        align-items: center!important;
        justify-content: center!important;
        width: 24px; // Adjust as needed
        height: 24px; // Adjust as needed
        margin-right: 8px; // Space between icon and text
    }

    .anticon,
    .custom-icon,
    svg {
        flex-shrink: 0!important; // Prevents icon from shrinking
    }
}

